import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  hover: {
    "&:hover": {
      width: "800px",
      transition: 'all 0.3s'
    },
  },
}));

export const AlertWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ff8f39;
  border-radius: 25px;
  box-shadow: 0 10px 10px grey;
  bottom: 0;
  left: 0;
  transition: all 0.3s;
  overflow: hidden;
  cursor: pointer;
`;
