import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, TextField } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BatchSettingsWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  font-size: 1.25rem;
`;

export const UploadIcon = styled(CloudUploadIcon)(({ theme, hover }) => ({
  '&': {
    fontSize: hover ? '2.5rem !important' : '5rem !important',
    margin: '0 !important',
    padding: '0 !important',
    color: theme.palette.primary.textPrimary,
    transition: '0.3s ease-in-out !important',
  },
}));

export const UploadButton = styled.div`
  width: 100%;
  padding: 1.5rem;
  max-width: 1000px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    cursor: pointer;
  }
`;

export const UploadText = styled.p`
  color: ${({ theme, hover }) => (hover ? theme.palette.primary.textPrimary : theme.palette.primary.main)};
  font-size: ${({ hover }) => (hover ? '2.5rem' : '0rem')};
  line-height: ${({ hover }) => (hover ? '2.5rem' : '0rem')};
  margin: 0;
  padding: 0;

  transition: 0.3s ease-in-out;
`;

export const BatchCountTextField = styled(TextField)({
  '&': {
    fontSize: '1rem',
    width: '8ch',
  },
});

export const BatchButton = styled(Button)({
  '&': {},
});
