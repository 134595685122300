import { getApi } from "api/axios";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

export const useDeliveryAPI = () => {
  const shopId = useSelector(
    (state) => state.nonPersistedReducers.shopSliceReducer.shopId
  );
  const [delivery, setDelivery] = useState(null);

  const fetchDelivery = useCallback(
    async ({ id }) => {
      try {
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PAYMENT_URL })
        ).get(`/admin/shops/${shopId}/deliveries/${id}`);
        setDelivery(res.data);
      } catch (e) {
        console.log(e);
      }
    },
    [shopId]
  );

  const handleReprint = useCallback(
    async ({ id }) => {
      try {
        await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PAYMENT_URL })
        ).post(`/admin/shops/${shopId}/deliveries/${id}/reprint`);
      } catch (e) {
        console.log(e);
      }
    },
    [shopId]
  );
  const handleResend = useCallback(
    async ({ id, body }) => {
      try {
        await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
        ).put(`/admin/shops/${shopId}/orders`, body);
        await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PAYMENT_URL })
        ).post(`/admin/shops/${shopId}/deliveries/${id}/reprint`);
      } catch (e) {
        console.log(e);
      }
    },
    [shopId]
  );

  return {
    fetchDelivery,
    delivery,
    handleReprint,
    handleResend,
  };
};
