import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    transition: "all 1s",
    padding: "0 !important",
    margin: "0 !important",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    margin: "0.5rem",
    width: "calc(100% - 1rem)",
    padding: "0 !important",
  },
  content: {
    flexGrow: 1,
  },
  clearBtn: {
    color: "#3D372C",
    opacity: "0",
    position: "absolute",
    bottom: "0.4rem",
    right: "0.4rem",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.backgroundSecondary,
    },
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  table: {
    padding: theme.spacing(2),
  },
  rowItem: {
    "&:hover, &:focus": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.light,
    },
  },

  resetBtn: {
    width: "100%",
  },
  heading: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
  },

  headerTop: {
    backgroundColor: theme.palette.primary.backgroundSecondary,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0.25rem 0",
    color: "white",
    width: "100%",
  },
  header: {
    // borderRadius: "0 0 30px 30px",
    backgroundColor: theme.palette.primary.backgroundSecondary,
    color: "white",
    tableLayout: "fixed",
    width: "100%",
    boxShadow: "0 4px 15px 0 rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
  },
  searchBar: {
    fontSize: "0.75rem",
    display: "flex",
  },

  filterExp: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  searchField: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      width: "90%",
    },
  },
  option: {
    color: "black",
  },
  sortField: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  searchDatePicker: {
    width: "100%",
    border: "transparent",
    color: "whitesmoke",
    borderBottom: "1px solid rgb(100, 100, 100)",
    outline: "none",
    "&:hover, &:focus": {
      cursor: "pointer",
      borderBottom: "2px solid whitesmoke",
    },
    background: "transparent",
    height: "25px",
  },

  searchSelect: {
    width: "100%",
    border: "transparent",
    color: "whitesmoke",
    borderBottom: "1px solid rgb(100, 100, 100)",
    outline: "none",
    "&:hover, &:focus": {
      cursor: "pointer",
      borderBottom: "2px solid whitesmoke",
    },
    background: "transparent",
    height: "25px",
  },
  filterInput: {
    color: "whitesmoke",
    "&:hover, &:focus": {
      cursor: "pointer",
      borderBottom: "2px solid whitesmoke",
    },
  },

  listitemtable: {
    tableLayout: "fixed",
    width: "100%",
    transition: "background 300ms",
    borderRadius: "0 0 4px 4px",
  },
  listItem: {
    height: "50px",
    borderRadius: "0 0 4px 4px ",
    "&:hover": {
      cursor: "pointer",
      background: "rgb(220, 220, 220)",
    },
  },
  tableCell: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
  },
  hideLg: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  hideMd: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  hideXs: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  showXs: {
    display: "inherit",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  breadcrumb: {
    margin: "1rem",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    color: theme.palette.primary.backgroundSecondary,
    outline: "none !important",
  },

  pendig: {
    color: "gray",
    fontWeight: "bold",
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
  paid: {
    color: "rgb(25,109,235)",
    fontWeight: "bold",
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
  verified: {
    color: "rgb(0, 204, 204)",
    fontWeight: "bold",
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
  invoiced: {
    color: "rgb(0, 255, 0)",
    fontWeight: "bold",
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
  shipping: {
    color: "rgb(204, 204, 0 )",
    fontWeight: "bold",
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
  done: {
    color: "rgb(0, 153, 0)",
    fontWeight: "bold",
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
  rejected: {
    color: "rgb(204, 0, 0 )",
    fontWeight: "bold",
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
  allBtn: {
    backgroundColor: theme.palette.primary.light,
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    transition: "opacity 300ms",
    "&:hover": {
      cursor: "pointer",
      opacity: "100%",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const AllButton = styled.button`
  border-radius: 4px;
  align-self: center;
  line-height: 50px;
  width: 250px;
  margin-top: 30px;
  border: transparent;
  color: whitesmoke;
  opacity: 80%;
`;
export const FilterInput = styled.input`
  width: 100%;
  border: transparent;
  border-bottom: 1px solid rgb(100, 100, 100);
  outline: none;
  background: transparent;
  height: 25px;
`;

export const OrderListWrapper = styled.div`
  tablelayout: fixed;
  width: 100%;
  transition: background 300ms;
  background-color: whitesmoke;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 15px 0 rgb(0 0 0 / 10%);
`;
