import { useTheme, Typography, CircularProgress } from "@material-ui/core";

import { Overlay, Wrapper, useStyles } from "./styles";
import { useEffect, useState } from "react";

const ErrorPage = (props) => {
  const { errorCode, errorText } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => setNotFound(true), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <Wrapper>
      {notFound ? (
        <>
          <Typography component="h1" variant="h1" className={classes.mainText}>
            {errorCode}
          </Typography>
          <Typography
            component="h2"
            variant="h2"
            className={classes.secondaryText}
          >
            {errorText}
          </Typography>
        </>
      ) : (
        <Overlay>
          <CircularProgress size={100} />
        </Overlay>
      )}
    </Wrapper>
  );
};

export default ErrorPage;
