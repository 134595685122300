import { getApi } from "api/axios";
import { errorToast, successToast } from "components/common/Toast/Toast";
import { useUserApi } from "pages/Register/hooks/useUserApi";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

export const useShopAPI = () => {
  const shopId = useSelector(
    (state) => state.nonPersistedReducers.shopSliceReducer.shopId
  );

  const isOk = (statusCode) => statusCode >= 200 && statusCode < 300;

  const [ownedShops, setOwnedShops] = useState([]);
  const [maintainedShops, setMaintainedShops] = useState([]);
  const [allShops, setAllShops] = useState([]);
  const [shopsLoading, setShopsLoading] = useState(false);

  const { getCurrentUser } = useUserApi();

  const [allScopes, setAllScopes] = useState([]);
  const [allScopesLoading, setAllScopesLoading] = useState(false);
  const [allScopesError, setAllScopesError] = useState(null);

  const getAllScopes = useCallback(async () => {
    setAllScopesLoading(true);
    try {
      setAllScopes([]);
      const { data, status } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/admin/users/scopes`);

      if (isOk(status)) {
        setAllScopesLoading(false);
        setAllScopes(data);
      }
    } catch (e) {
      setAllScopesError(e);
      console.log(e);
    }
  }, [shopId]);

  const getShops = useCallback(async () => {
    setShopsLoading(true);
    setOwnedShops([]);
    setAllShops([]);
    setMaintainedShops([]);
    const { status, user } = await getCurrentUser();
    if (isOk(status)) {
      setOwnedShops([...user?.ownedShops]);
      setMaintainedShops([...user?.maintainedShops]);
      setAllShops([...user?.ownedShops, ...user?.maintainedShops]);
    }
    setShopsLoading(false);
  }, []);

  const [shop, setShop] = useState(null);
  const [shopLoading, setShopLoading] = useState(false);
  const [shopError, setShopError] = useState(null);

  const getShop = useCallback(async () => {
    setShopLoading(true);
    try {
      setShop(null);
      const { data, status } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/admin/shops/${shopId}`);
      if (isOk(status)) {
        setShopLoading(false);
        setShop(data);
      }
    } catch (e) {
      setShopError(e);
      console.log(e);
    }
  }, [shopId]);

  const createShop = async ({ body }) => {
    setShopLoading(true);
    try {
      setShop(null);
      const { data, status } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).post(`/admin/shops`, body);
      if (isOk(status)) {
        setShopLoading(false);
        successToast("Bolt létrehozása sikeres!");
        setShop(data);
        window.location.reload();
      }
    } catch (e) {
      setShopError(e);
      errorToast("Sikertelen bolt létrehozás!");
      console.log(e);
    }
  };

  const putShop = useCallback(
    async ({ body }) => {
      setShopLoading(true);
      try {
        setShop(null);
        const { data, status } = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
        ).put(`/admin/shops`, body);
        if (isOk(status)) {
          setShopLoading(false);
          setShop(data);
          successToast("Sikeres művelet!");
        }
      } catch (e) {
        setShopError(e);
        errorToast("Sikertelen művelet!");
        console.log(e);
      }
    },
    [shopId]
  );

  return {
    getShops,
    getShop,
    createShop,
    putShop,
    allShops,
    ownedShops,
    maintainedShops,
    shopsLoading,
    shop,
    shopLoading,
    shopError,
    getAllScopes,
    allScopes,
    allScopesError,
    allScopesLoading,
  };
};
