import { getApi } from "api/axios";
import { useEffect, useState } from "react";
import { useCallback } from "react";

export const useReportingAPI = () => {
  const [allReportsloading, setAllReportsLoading] = useState();
  const [allAggregateloading, setAllAggregatedloading] = useState();
  const [allPrintsloading, setAllPrintsLoading] = useState();
  const [allReports, setAllReports] = useState();
  const [allAggregated, setAllAggregated] = useState();
  const [allPrints, setAllPrints] = useState();
  const [allReportsLength, setAllReportsLength] = useState();
  const [allAggregatedLength, setAllAggregatedLength] = useState();
  const [allPrintsLength, setAllPrintsLength] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [vendorTypes, setVendorTypes] = useState();

  const [vendorPrices, setVendorPrices] = useState();
  const [vendorPricesLoading, setVendorPricesLoading] = useState(); 

  const getSetUniqueVendorTypes = (vendorPrices) => {
    const duplicatedTypes = [];
    let uniqueTypes = [];
    vendorPrices.forEach((p) => duplicatedTypes.push(p.deliveryType));
    uniqueTypes = duplicatedTypes.filter(
      (value, index, self) => self.indexOf(value) === index
    );
    setVendorTypes(uniqueTypes);
  };

  const fetchAllReports = useCallback(async ({ queryString }) => {
    setAllReportsLoading(true);
    try {
      setAllReports([]);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).get(`admin/reports${queryString}`);
      if (res.status === 200) {
        setAllReportsLoading(false);
        const reports = res.data.map((report) => ({
          ...report,
          selected: false,
        }));
        setAllReports(reports);
        setAllReportsLength(res.data.length);
      }
    } catch (e) {
      setAllReportsLoading(false);
      setErrorMessage(e);
      console.log(e);
    }
  }, []);
  const fetchAllAggregated = useCallback(async ({ queryString }) => {
    setAllAggregatedloading(true);
    try {
      setAllAggregated([]);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).get(`admin/reports/aggregated${queryString}`);
      if (res.status === 200) {
        setAllAggregatedloading(false);
        const reports = res.data.map((report) => ({
          ...report,
          selected: false,
        }));
        setAllAggregated(reports);
        setAllAggregatedLength(res.data.length);
      }
    } catch (e) {
      setAllAggregatedloading(false);
      setErrorMessage(e);
      console.log(e);
    }
  }, []);
  const fetchAllPrinted = useCallback(async ({ queryString }) => {
    setAllPrintsLoading(true);
    try {
      setAllPrints([]);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).get(`admin/reports/aggregated/printing-price${queryString}`);
      if (res.status === 200) {
        setAllPrintsLoading(false);
        const reports = res.data.map((report) => ({
          ...report,
          selected: false,
        }));
        setAllPrints(reports);
        setAllPrintsLength(res.data.length);
      }
    } catch (e) {
      setAllPrintsLoading(false);
      setErrorMessage(e);
      console.log(e);
    }
  }, []);
  const fetchVendorPricesLatest = useCallback(async () => {
    setVendorPricesLoading(true);
    try {
      setAllReports([]);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).get(`admin/delivery-vendor-options/latest`);
      if (res.status === 200) {
        setVendorPricesLoading(false);
        const prices = res.data;
        setVendorPrices(prices);
      }
    } catch (e) {
      setVendorPricesLoading(false);
      setErrorMessage(e);
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (vendorPrices) {
      getSetUniqueVendorTypes(vendorPrices);
    }
  }, [vendorPrices]);

  return {
    fetchAllReports,
    fetchAllAggregated,
    fetchAllPrinted,
    fetchVendorPricesLatest,
    vendorPrices,
    vendorTypes,
    vendorPricesLoading,
    allReportsloading,
    allAggregateloading,
    allPrintsloading,
    allReports,
    allAggregated,
    allPrints,
    allReportsLength,
    allAggregatedLength,
    allPrintsLength,
    errorMessage,
  };
};
