import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  desktopTitle: {
    fontSize: "2rem",
    padding: "0 1rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  addBtn: {
    width: "50%",
  },
}));
