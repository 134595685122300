import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const GridContainer = styled.div`
  height: 460px;
  width: 100%;
`;

export const useStyles = makeStyles((theme) => ({
  grid: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
  },
  toolbar: {
    margin: "1rem",
  },
}));
