import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  deletebutton: {
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    backgroundColor: "#c8c8c8",
    opacity: "0.5",
    borderRadius: "1rem",
    color: "white",
    fontSize: "20px",
    position: "absolute",
    bottom: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    "&:hover": {
      color: "red",
      transition: "all 300ms",
    },
  },

  customDialogTitle: {
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
  confirmBtn: {
    width: "100%",
    height: "auto",
    padding: "1rem",
    margin: "0 2rem",
  },
  cancelBtn: {
    width: "100%",
    height: "auto",
    padding: "1rem",
    margin: "0 2rem",
  },
}));
