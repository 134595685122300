import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  stepper: {
    background: "transparent",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  stepperItems: {
    marginTop: "0.5rem",
    "& .MuiTypography-root": {
      fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    },

    [theme.breakpoints.up("md")]: {
      marginTop: "0",
    },
  },
  error: {
    marginTop: "0.5rem",
    "& .MuiTypography-root, & .MuiStepIcon-root": {
      fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
      color: theme.palette.primary.error,
      fontWeight: "bold",
    },

    [theme.breakpoints.up("md")]: {
      marginTop: "0",
    },
  },
}));
