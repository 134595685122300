/** @format */

import { getApi } from "api/axios";
import { errorToast, successToast } from "components/common/Toast/Toast";
import { useState } from "react";

export const useUserApi = () => {
    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(null);

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const { status, data } = await (
                await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
            ).get(`/admin/users`);
            if (status === 200) {
                setLoading(false);
                setUsers(data);
            } else {
                setLoading(false);
                setUsers([]);
            }
            return { status: status, user: data };
        } catch (e) {
            console.log(e);
        }
    };
    const fetchUser = async (id) => {
        try {
            setLoading(true);
            const { status, data } = await (
                await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
            ).get(`/admin/users/${id}`);
            if (status === 200) {
                setLoading(false);
                setUser(data);
            } else {
                setLoading(false);
                setUser([]);
            }
            return { status: status, user: data };
        } catch (e) {
            console.log(e);
        }
    };

    const putUser = async (body) => {
        try {
            const { status, data } = await (
                await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
            ).put(`/users`, body);
            successToast(
                data?.active ? "Sikeres aktiválás!" : "Sikeres deaktiválás!"
            );
        } catch (e) {
            errorToast("Sikertelen művelet!");
            console.log(e);
        }
    };

    return { loading, users, fetchUsers, fetchUser, user, putUser };
};
