import { Grow, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { AlertWrapper } from "./styles";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import sizes from "constants/sizes";

const Alert = ({ maxWidth }) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  const shopId = useSelector(
    (state) => state.nonPersistedReducers?.shopSliceReducer?.shopId
  );
  const alert = useSelector(
    (state) => state.nonPersistedReducers?.alertSliceReducer?.alert
  );

  const routeChange = (path) => {
    navigate(path);
  };

  const desktop = useMediaQuery(`(min-width:${sizes.SM.toString()}px)`);

  return (
    <Grow timeout={500} direction="left" in={true}>
      <AlertWrapper
        className={classes.hover}
        style={{ maxWidth: desktop && maxWidth ? maxWidth : 300 }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => routeChange(`/shops/${shopId}/settings/invoicing`)}
      >
        <WarningAmberIcon
          style={{ color: "white", marginLeft: !desktop && hover ? "0.7rem" : "unset" }}
        />
        {hover && (
          <Typography
            style={{
              color: "white",
              margin: 0,
            }}
          >
            {alert === "no_vendor"
              ? "Még nem állítottál be számlázási kiszolgálót!"
              : alert === "set_modifiers"
              ? "Van olyan terméked, amit még egyik számlázási kiszolgálóhoz sem állítottál be!"
              : ""}
          </Typography>
        )}
      </AlertWrapper>
    </Grow>
  );
};

export default Alert;
