import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  margin: 0.15rem 0;
  padding: 0.25rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.22);
`;
export const Name = styled(Typography)(({ theme }) => ({
  '&': {
    fontSize: '0.95rem !important',
  },
}));
