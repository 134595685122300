import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import PrintIcon from "@mui/icons-material/Print";
import { useTheme } from "@material-ui/core/styles";
import Checkbox from "@mui/material/Checkbox";
import {
  Button,
  Tooltip,
  Typography,
  Container,
  CssBaseline,
  Breadcrumbs,
  Zoom,
} from "@material-ui/core";

import LoadIndicator from "components/common/LoadIndicator/LoadIndicator";
import QueryBuilder from "components/common/QueryBuilder/QueryBuilder";
import { AllButton, DeliveryListWrapper, useStyles } from "./styles";
import { usePrinterApi } from "./hooks/usePrinterApi";
import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";

const PrinterAdminSite = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();
  const { search } = useLocation();

  const [showAll, setShowAll] = useState(false);

  const [allSelected, setAllSelected] = useState(false);

  const [open, setOpen] = useState();

  const {
    fetchPrintableGroups,
    printGroupFinal,
    groups,
    groupsLength,
    setGroups,
    loading,
  } = usePrinterApi();

  const selectGroup = (groups, currentgroup) => {
    const newGroups = groups.map((group) => {
      if (group.id === currentgroup.id) {
        return { ...group, selected: !group.selected };
      }
      return group;
    });
    setGroups(newGroups);
  };

  const getShowAllQueryString = () => {
    let queryParams = new URLSearchParams(search);
    queryParams.set("page", 1);
    queryParams.set("size", groupsLength);
    return queryParams.toString();
  };
  const handleShowAll = () => {
    setShowAll(!showAll);

    navigate(`/printer${getShowAllQueryString()}`);
  };
  const handleSelectAll = (groups, e) => {
    setAllSelected(e.target.checked);
    const newGroups = groups.map((group) => ({
      ...group,
      selected: e.target.checked,
    }));
    setGroups(newGroups);
  };

  const handlePrint = async () => {
    let promises = [];
    groups?.forEach(async (group) => {
      if (group.selected) {
        let newBody = { ...group };
        delete newBody.selected;
        newBody.isPrintingDone = true;
        let promise = printGroupFinal({
          group: { ...newBody },
        });
        promises.push(promise);
      }
    });
    await Promise.all(promises);
    await fetchPrintableGroups({
      queryString: search,
    });
  };

  const refresh = () => {
    fetchPrintableGroups({ queryString: search });
  };

  const linkRef = useRef(null);

  useEffect(() => {
    refresh();
    let queryParams = new URLSearchParams(search);

    if (queryParams.get("size") !== groupsLength) {
      setShowAll(false);
    }
  }, [search]);

  const resetQueryString = () => {
    navigate("/printer");
  };

  return (
    <Container maxWidth={false} className={classes.root}>
      <CssBaseline />
      <a ref={linkRef} />
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
        <Typography variant="body1">Nyomtatásra beérkezett galériák</Typography>
      </Breadcrumbs>
      <Zoom in={true} timeout={1000}>
        <Container maxWidth={false} className={classes.container}>
          <QueryBuilder
            itemsLength={groupsLength}
            showAll={showAll}
            siteType={"printer"}
            reset={resetQueryString}
            refresh={refresh}
          >
            <table className={classes.headerTop}>
              <tbody>
                <tr width="100%" className={classes.searchBar}>
                  <td
                    style={{
                      color: "whitesmoke",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title={"Kijelöltek nyomtatásának véglegesítése"}>
                      <Button
                        variant="outlined"
                        style={{
                          backgroundColor:
                            theme.palette.primary.backgroundContent,
                          color: theme.palette.primary.main,
                          margin: 0,
                          border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
                          transition: "opacity 300ms",
                          opacity: !groups?.find((group) => group.selected)
                            ? 0.3
                            : 1,
                        }}
                        disabled={!groups?.find((group) => group.selected)}
                        onClick={() => setOpen("printConfirm")}
                      >
                        <PrintIcon />
                        Nyomtatás véglegesítése
                      </Button>
                    </Tooltip>
                    <ConfirmDialog
                      open={open === "printConfirm"}
                      title="Biztosan Véglegesíted a nyomtatást? Ezáltal a fotósoknak is visszajelzést küldünk, hogy a kijelölt iskolák nyomtatása megtörtént!"
                      functionButtonText="Nyomtatás véglegesítése"
                      functionButtonColor={theme.palette.primary.success}
                      onOk={async () => {
                        setOpen(null);
                        await handlePrint();
                        setAllSelected(false);
                      }}
                      onClose={() => setOpen(null)}
                      closeButtonText="Mégsem"
                      multipleButtons
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={classes.header}>
              <thead>
                <tr width="100%">
                  <td className={classes.heading} width="10%">
                    <Checkbox
                      style={{ color: "whitesmoke" }}
                      checked={allSelected}
                      onChange={(e) => handleSelectAll(groups, e)}
                    ></Checkbox>
                  </td>
                  <td className={classes.hideXs} style={{ width: "10%" }}>
                    <Typography className={classes.heading}>#</Typography>
                  </td>
                  <td className={classes.heading}>
                    <Typography className={classes.heading}>
                      Azonosító
                    </Typography>
                  </td>
                  <td className={classes.hideXs}>
                    <Typography className={classes.heading}>
                      Bolt azonosító
                    </Typography>
                  </td>
                  <td className={classes.heading}>
                    <Typography className={classes.heading}>
                      Iskola neve
                    </Typography>
                  </td>
                  <td className={classes.heading}>
                    <Typography className={classes.heading}>
                      Létrehozás dátuma
                    </Typography>
                  </td>
                  <td className={classes.tableCell}>
                    <Typography className={classes.heading}>Státusz</Typography>
                  </td>
                </tr>
              </thead>
            </table>
            <DeliveryListWrapper>
              <table className={classes.listitemtable}>
                <tbody>
                  {loading ? (
                    <tr>
                      <td>
                        <LoadIndicator
                          color={theme.palette.primary.light}
                          height={800}
                        />
                      </td>
                    </tr>
                  ) : groups?.length > 0 ? (
                    groups?.map((group, idx) => (
                      //Frontend filtering for showing only printable paper deliveries, FOR TEST ONLY!!!!
                      //delivery.deliveryType === "POST" &&
                      <tr
                        className={classes.listItem}
                        style={{ background: idx % 2 === 1 && "lightgray" }}
                        width="100%"
                        key={group.id}
                        onClick={(e) => {
                          selectGroup(groups, group);
                          e.stopPropagation();
                        }}
                      >
                        <td
                          className={classes.tableCell}
                          style={{ width: "10%" }}
                        >
                          <Checkbox
                            style={{
                              color: theme.palette.primary.backgroundSecondary,
                            }}
                            checked={group.selected || allSelected}
                            onChange={() => {
                              selectGroup(groups, group);
                            }}
                          ></Checkbox>
                        </td>
                        <td className={classes.hideXs} style={{ width: "10%" }}>
                          <Typography className={classes.tableCell}>
                            {idx + 1}
                          </Typography>
                        </td>
                        <td className={classes.tableCell}>
                          <Typography className={classes.tableCell}>
                            {group.id}
                          </Typography>
                        </td>
                        <td className={classes.hideXs}>
                          <Typography className={classes.tableCell}>
                            {group.shopId}
                          </Typography>
                        </td>
                        <td className={classes.tableCell}>
                          <Typography className={classes.tableCell}>
                            {group?.name}
                          </Typography>
                        </td>
                        <td className={classes.tableCell}>
                          {group?.createdAt && (
                            <Typography className={classes.tableCell}>
                              {new Date(group?.createdAt).toLocaleDateString(
                                "hu-HU",
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  seconds: "2-digit",
                                }
                              )}
                            </Typography>
                          )}
                        </td>
                        <td className={classes.tableCell}>
                          <Typography
                            style={{
                              fontFamily: theme.typography.main,
                              color: group?.isActive
                                ? "rgb(25,109,235)"
                                : theme.palette.primary.error,
                            }}
                          >
                            {group?.isActive ? "Aktív" : "Inaktív"}
                          </Typography>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr
                      style={{
                        margin: "0.5rem",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <td>
                        <Typography
                          variant="h5"
                          style={{ color: theme?.palette?.primary?.light }}
                        >
                          Nincs nyomtatható galéria.
                        </Typography>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </DeliveryListWrapper>
          </QueryBuilder>

          <AllButton
            className={classes.allBtn}
            onClick={() => {
              handleShowAll();
            }}
          >
            {showAll ? "Kevesebb galéria" : "Összes galéria"}
          </AllButton>
        </Container>
      </Zoom>
    </Container>
  );
};

export default PrinterAdminSite;
