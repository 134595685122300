import { Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useStyles } from "./styles";
import Tooltip from "@mui/material/Tooltip";

import { useState } from "react";
import { useEffect } from "react";

const OrderHistoryWidget = ({ order, handler }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    "Rendelés fogadva",
    activeStep > 1
      ? "Fizetve"
      : order?.status === "REJECTED_PAYMENT"
      ? "Sikertelen fizetés"
      : "Fizetésre vár",
    activeStep > 2
      ? "Számlázva"
      : order?.status === "REJECTED_INVOICE"
      ? "Sikertelen számlázás"
      : "Számlázásra vár",
    activeStep < 3
      ? "Kiszállításra vár"
      : order?.status === "SHIPPING" && activeStep === 3
      ? "Kiszállítás alatt"
      : order?.status === "REJECTED_DELIVERY" && activeStep === 3
      ? "Sikertelen kézbesítés"
      : "Kiszállítva",
    activeStep < 4
      ? "Teljesítés folyamatban"
      : activeStep === 4
      ? "Utolsó lépések"
      : "Teljesítve",

    // "Visszautasítva",
  ];

  const handleStepCount = () => {
    if (order?.status === "PENDING" || order?.status === "REJECTED_PAYMENT") {
      setActiveStep(1);
    }
    if (order?.status === "PAID" || order?.status === "REJECTED_INVOICE") {
      setActiveStep(2);
    }
    if (
      order?.status === "INVOICED" ||
      order?.status === "SHIPPING" ||
      order?.status === "REJECTED_DELIVERY"
    ) {
      setActiveStep(3);
    }
    if (order?.status === "SHIPPED") {
      setActiveStep(4);
    }
    if (order?.status === "DONE") {
      setActiveStep(5);
    }
    // if (order?.status === "REJECTED") {
    //   setActiveStep(6);
    // }
  };


  useEffect(() => {
    handleStepCount();
  }, [order]);

  return (
    <Stepper activeStep={activeStep} className={classes.stepper}>
      {steps.map((label, idx) => (
        <Step key={label}>
          {order?.orderHistories === undefined ? (
            <StepLabel className={classes.stepperItems}>{label}</StepLabel>
          ) : (
            <Tooltip
              title={
                order?.orderHistories[idx] === undefined ? (
                  <Typography>{label}</Typography>
                ) : (
                  <>
                    <Typography>
                      {order?.orderHistories[idx]?.message.split(":")}
                    </Typography>
                    <Typography>
                      Date of changes:
                      <br></br>
                      {order?.orderHistories[idx]?.updateAt &&
                        handler(order?.orderHistories[idx]?.updateAt)}
                    </Typography>
                  </>
                )
              }
              arrow
            >
              <StepLabel
                className={
                  (order?.status === "REJECTED_PAYMENT" && idx === 1) ||
                  (order?.status === "REJECTED_INVOICE" && idx === 2) ||
                  (order?.status === "REJECTED_DELIVERY" && idx === 3)
                    ? classes.error
                    : classes.stepperItems
                }
              >
                {label}
              </StepLabel>
            </Tooltip>
          )}
        </Step>
      ))}
    </Stepper>
  );
};

export default OrderHistoryWidget;
