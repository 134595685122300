import { Button, Grid, Tooltip, useTheme } from "@material-ui/core";

import { useStyles, CalculatorWrapper } from "./styles";

const Calculator = ({
  setCalcFormula,
  calcFormula,
  formulaParams,
  inverse,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleCalculator = ({ add, remove, clear, param }) => {
    if (add) {
      setCalcFormula(calcFormula + param);
    }
    if (remove) {
      let newFormula = calcFormula?.slice(0, -1);
      setCalcFormula(newFormula);
    }
    if (clear) {
      setCalcFormula("");
    }
  };

  const getTooltipText = (formulaKey) => {
    if (formulaKey === "print") {
      return "Nyomtatási költség";
    }
    if (formulaKey === "dtcp") {
      return "DT jutalék (százalék)";
    }
    if (formulaKey === "simplePay") {
      return "SimplePay jutalék";
    }
    if (formulaKey === "dtcf") {
      return "DT jutalék (fix)";
    }
    if (formulaKey === "userInput" && inverse) {
      return "Teljes összeg";
    }
    if (formulaKey === "userInput" && !inverse) {
      return "Fotós profit";
    }
  };
  return (
    <CalculatorWrapper>
      <Grid container>
        <Grid item container xs={9}>
          <Grid item container xs={12} style={{ height: "0rem" }}>
            <Grid item xs={4} className={classes.calcOption}>
              <Button
                variant="contained"
                className={classes.optionBtn}
                onClick={() => {
                  handleCalculator({
                    add: true,
                    param: "(",
                  });
                }}
              >
                (
              </Button>
            </Grid>
            <Grid item xs={4} className={classes.calcOption}>
              <Button
                variant="contained"
                className={classes.optionBtn}
                onClick={() => {
                  handleCalculator({
                    add: true,
                    param: ")",
                  });
                }}
              >
                )
              </Button>
            </Grid>
            <Grid item xs={4} className={classes.calcOption}>
              <Button
                variant="contained"
                className={classes.optionBtn}
                onClick={() => {
                  handleCalculator({
                    remove: true,
                  });
                }}
              >
                CE
              </Button>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            {formulaParams?.map((param, idx) => (
              <Grid item xs={4} className={classes.calcParam} key={idx}>
                <Tooltip title={getTooltipText(param.formulaKey)}>
                  <Button
                    variant="outlined"
                    style={{
                      width: "1rem",
                      marginBottom: "0.5rem",
                    }}
                    onClick={() => {
                      handleCalculator({
                        add: true,
                        param: param.formulaKey,
                      });
                    }}
                  >
                    {param.formulaKey === "userInput"
                      ? "uInput"
                      : param.formulaKey === "simplePay"
                      ? "sc"
                      : param.formulaKey}
                  </Button>
                </Tooltip>
              </Grid>
            ))}
            <Grid item xs={4} className={classes.calcParam}>
              <Button
                variant="outlined"
                style={{
                  width: "1rem",
                  marginBottom: "0.5rem",
                }}
                onClick={() => {
                  handleCalculator({
                    add: true,
                    param: "1",
                  });
                }}
              >
                1
              </Button>
            </Grid>
            <Grid item xs={4} className={classes.calcParam}>
              <Button
                variant="outlined"
                style={{
                  width: "1rem",
                  marginBottom: "0.5rem",
                }}
                onClick={() => {
                  handleCalculator({
                    add: true,
                    param: "max",
                  });
                }}
              >
                max
              </Button>
            </Grid>
            <Grid item xs={4} className={classes.calcParam}>
              <Button
                variant="outlined"
                style={{
                  width: "1rem",
                  marginBottom: "0.5rem",
                }}
                onClick={() => {
                  handleCalculator({
                    add: true,
                    param: "min",
                  });
                }}
              >
                min
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={3}>
          <Grid item container xs={12}>
            <Grid item xs={12} className={classes.calcOption}>
              <Button
                variant="contained"
                className={classes.optionBtn}
                onClick={() => {
                  handleCalculator({
                    clear: true,
                  });
                }}
              >
                AC
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.calcOption}>
              <Button
                variant="contained"
                className={classes.optionBtn}
                onClick={() => {
                  handleCalculator({
                    add: true,
                    param: "/",
                  });
                }}
              >
                /
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.calcOption}>
              <Button
                variant="contained"
                className={classes.optionBtn}
                onClick={() => {
                  handleCalculator({
                    add: true,
                    param: "*",
                  });
                }}
              >
                x
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.calcOption}>
              <Button
                variant="contained"
                className={classes.specialOBtn}
                onClick={() => {
                  handleCalculator({
                    add: true,
                    param: "-",
                  });
                }}
              >
                -
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.calcOption}>
              <Button
                variant="contained"
                className={classes.specialOBtn}
                onClick={() => {
                  handleCalculator({
                    add: true,
                    param: "+",
                  });
                }}
              >
                +
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.calcOption}>
              <Button
                variant="contained"
                className={classes.specialOBtn}
                onClick={() => {
                  handleCalculator({
                    add: true,
                    param: ";",
                  });
                }}
              >
                ;
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CalculatorWrapper>
  );
};

export default Calculator;
