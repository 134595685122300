import { useTheme } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useStyles } from "./styles";
import { useGroupNameEditorLogic } from "./hooks/useGroupNameEditorLogic";

const GroupNameEditor = ({ option, setOption }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { fetchGroups, groups, groupLoading } = useGroupNameEditorLogic();

  const [selectedSchool, setSelectedSchool] = useState(null);
  const [schoolOptions, setSchoolOptions] = useState([]);

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (groups) {
      setSchoolOptions(groups);
    }
  }, [groups]);
  useEffect(() => {
    if (option.value !== "" && groups) {
      setSelectedSchool(groups.find((g) => option.value.includes(g.name)));
    }
  }, [groups]);

  useEffect(() => {
    if (selectedSchool) {
      const newValue = `${option.name}=${selectedSchool.name}`;
      if (decodeURI(option.value) !== decodeURI(newValue)) {
        setOption({ ...option, value: encodeURI(newValue) });
      }
    }
  }, [selectedSchool]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "0.5rem",
        fontFamily: theme.typography.main,
      }}
    >
      {groupLoading ? (
        <p>Loading...</p>
      ) : (
        <Autocomplete
          disabled={!groups || groups?.length === 0}
          disablePortal
          disableClearable
          id="school-autocomplete"
          options={schoolOptions}
          getOptionLabel={(option) => option?.name || ""}
          sx={{ width: 300, fontFamily: theme.typography.main }}
          value={selectedSchool}
          className={classes.autocomplete}
          onChange={(event, selectedSchool) => {
            setSelectedSchool(selectedSchool);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { fontFamily: `${theme.typography.main}` },
              }}
              label="Iskola"
            />
          )}
        />
      )}
    </div>
  );
};

export default GroupNameEditor;
