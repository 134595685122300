import { getApi } from 'api/axios';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { convertBlobToBase64 } from 'utils/ImageUploadRequest';

export const useProductAPI = () => {
  const [product, setProduct] = useState();

  const shopId = useSelector((state) => state.nonPersistedReducers.shopSliceReducer.shopId);

  const fetchProduct = useCallback(
    async ({ id }) => {
      try {
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
        ).get(`/admin/shops/${shopId}/products/${id}`);
        setProduct(res.data);
      } catch (e) {
        console.log(e);
      }
    },
    [shopId]
  );

  const [productImage, setProductImage] = useState();

  const fetchProductImage = useCallback(
    async ({ id }) => {
      try {
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
        ).get(`/admin/shops/${shopId}/products/${id}/thumbnail`, {
          responseType: 'blob'
        });
        let image = await convertBlobToBase64(await res.data);
        setProductImage(image);
      } catch (e) {
        console.log(e);
      }
    },
    [shopId]
  );

  const [productEnabling, setProductEnabling] = useState(false);

  const putProduct = useCallback(
    async ({ body }) => {
      setProductEnabling(true);
      try {
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
        ).put(`/admin/shops/${shopId}/products`, body);
        setProduct(res.data);
        setProductEnabling(false);
      } catch (e) {
        setProductEnabling(false);
        console.log(e);
      }
    },
    [shopId]
  );

  const [productDeleting, setProductDeleting] = useState(false);
  const deleteProduct = useCallback(
    async ({ id }) => {
      setProductDeleting(true);
      try {
        await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
        ).delete(`/admin/shops/${shopId}/products/${id}`);
        setProductDeleting(false);
      } catch (e) {
        setProductDeleting(false);
        console.log(e);
      }
    },
    [shopId]
  );

  return {
    fetchProduct,
    fetchProductImage,
    productImage,
    putProduct,
    product,
    deleteProduct,
    productDeleting,
    productEnabling
  };
};
