import { getApi } from "api/axios";
import { errorToast, successToast } from "components/common/Toast/Toast";
import { useState } from "react";
import { useSelector } from "react-redux";

export const usePrintableGroupLogic = () => {
  const shopId = useSelector(
    (state) => state.nonPersistedReducers.shopSliceReducer.shopId
  );

  const [groups, setGroups] = useState();
  const [groupsLength, setGroupsLength] = useState(0);
  const [loading, setLoading] = useState();

  const fetchGroups = async ({ queryString }) => {
    setLoading(true);
    try {
      setGroups(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(
        `/admin/shops/${shopId}/groups/filter${queryString}${
          queryString ? "&" : "?"
        }topLevel=true`
      );
      if (res.status === 200) {
        const groups = res.data._embedded.groupDTOList.map((group) => ({
          ...group,
          selected: false,
        }));
        setLoading(false);
        setGroupsLength(res.data.page.totalElements);
        setGroups(groups);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  const getParentGroup = async ({ groupId }) => {
    let parentGroupNames = "";
    const res1 = await (
      await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
    ).get(`/admin/shops/${shopId}/groups/${groupId}`);
    if (res1?.data?.belongsTo?.id) {
      const res2 = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/admin/shops/${shopId}/groups/${res1.data.belongsTo.id}`);
      parentGroupNames = `${res2.data.name} - ${res1.data.name}`;
    }

    return parentGroupNames;
  };

  const printGroup = async ({ group }) => {
    setLoading(true);
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).put(`/admin/shops/${shopId}/groups`, group);
      if (res.status >= 200 && res.status < 300) {
        successToast(
          `Sikeresen továbbította nyomtatásra a ${group.name} nevű galériához tartozó rendeléseket!`
        );
      }
    } catch (e) {
      errorToast(
        `Sikertelen művelet! A ${group.name} nevű galériához tartozó rendeléseket nem sikerült nyomtatásra küldeni! Kérjük próbálja újra később!`
      );
      console.log(e);
    }
  };

  return {
    fetchGroups,
    getParentGroup,
    printGroup,
    groups,
    loading,
    setGroups,
    groupsLength,
    setGroupsLength,
  };
};
