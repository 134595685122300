import { useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { useNavigate, useLocation } from "react-router-dom";
import ApiIcon from "@mui/icons-material/Api";
import SettingsIcon from "@material-ui/icons/Settings";
import PersonIcon from "@material-ui/icons/Person";
import Drawer from "@material-ui/core/Drawer";

import HeaderOption from "../HeaderOption/HeaderOption";
import HeaderData from "config/HeaderData";
import { useStyles } from "./styles";
import AuthService from "services/AuthService";
import { useSelector } from "react-redux";

const DrawerMenu = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();

  const routeChange = (path) => {
    navigate(path);
    props.setDrawer(false);
  };
  const shopId = useSelector(
    (state) => state.nonPersistedReducers?.shopSliceReducer?.shopId
  );

  const id = useLocation().pathname.split("/")[2];

  const handleLogout = () => {
    //dispatch(logout());
    AuthService.doLogout();
  };

  return (
    <Drawer
      classes={{ paper: classes.paper }}
      anchor="left"
      open={props.drawer}
      onClose={() => props.toggleDrawer()}
    >
      <List className={classes.listWrapper}>
        {props?.user?.groups?.find((role) => role === "users") && (
          <div>
            <ListItem
              button
              onClick={(e) => {
                props.toggleAdminOptions();
                e.stopPropagation();
              }}
              disabled={shopId === -1}
            >
              <ListItemIcon className={classes.titleIcon}>
                <ApiIcon className={classes.titleIcon} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Adminisztráció"
              />
            </ListItem>
            <Collapse in={props?.adminOptionsOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {HeaderData.options.admin.map((item, idx) => (
                  <HeaderOption
                    disabled={item.disabled}
                    click={() => {
                      routeChange(`/shops/${id}/administration/${item.to}`);
                    }}
                    name={item.name}
                    icon={item.icon}
                    key={`header_small_admin_option_admin_${idx}`}
                  />
                ))}
              </List>
            </Collapse>
            <Divider />
            <ListItem
              button
              onClick={(e) => {
                props.toggleSiteOptions();
                e.stopPropagation();
              }}
              disabled={shopId === -1}
            >
              <ListItemIcon className={classes.titleIcon}>
                <SettingsIcon className={classes.titleIcon} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Beállítások"
              />
            </ListItem>
            <Collapse in={props.siteOptionsOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {HeaderData.options.site.map((item, idx) => (
                  <HeaderOption
                    disabled={item.disabled}
                    click={() => {
                      routeChange(`/shops/${id}/settings/${item.to}`);
                    }}
                    name={item.name}
                    icon={item.icon}
                    key={`header_small_admin_option_profile_${idx}`}
                  />
                ))}
              </List>
            </Collapse>
            <Divider />

            <ListItem
              button
              onClick={(e) => {
                props.toggleProfileOptions();
                e.stopPropagation();
              }}
            >
              <ListItemIcon className={classes.titleIcon}>
                <PersonIcon className={classes.titleIcon} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Profil"
              />
            </ListItem>
            <Collapse
              in={props?.profileOptionsOpen}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {HeaderData.options.profile.map((item, idx) => (
                  <HeaderOption
                    disabled={item.disabled}
                    click={() => {
                      routeChange(`/settings/${item.to}`);
                    }}
                    name={item.name}
                    icon={item.icon}
                    key={`header_small_admin_option_admin_${idx}`}
                  />
                ))}
              </List>
            </Collapse>
          </div>
        )}
        <div>
          {props?.user?.groups?.find((role) => role === "dt_admin") && (
            <List component="div" disablePadding>
              {HeaderData.options.superAdmin.map((item, idx) => (
                <HeaderOption
                  disabled={item.disabled}
                  click={() => {
                    routeChange(`superadmin/${item.to}`);
                  }}
                  name={item.name}
                  icon={item.icon}
                  key={`header_small_admin_option_admin_${idx}`}
                />
              ))}
            </List>
          )}
          {/*
            

          <Divider />

         
                      */}
        </div>

        <div>
          <HeaderOption
            click={handleLogout}
            name={HeaderData.logoutOption.name}
            icon={HeaderData.logoutOption.icon}
            key={HeaderData.logoutOption.name}
          />
        </div>
      </List>
    </Drawer>
  );
};

export default DrawerMenu;
