import styled from "styled-components";
import { makeStyles } from "@material-ui/styles";

export const PortraitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: left;
`;

export const PortraitImage = styled.img`
  width: 250px;
  height: 250px;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.22), 0 6px 20px 0 rgba(0, 0, 0, 0.22);
  border: 6px solid white;
  margin: 2rem;

  @media (max-width: 600px) {
    width: 200px;
    height: 200px;
  }
`;

export const ProfileFields = styled.div`
  margin: 0 2rem 2rem 2rem;
  float: right;

  @media (min-width: 600px) {
    margin: 3rem 2rem 2rem 2rem;
    float: right;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const ProfileContainer = styled.div`
  @media (min-width: 600px) {
    flex-direction: row;
  }
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  editbutton: {
    cursor: "pointer",
    float: "right",
  },
  saveButton: {
    display: "1 1 auto",
    margin: "0 0.5rem 0 0.5rem",
  },
  cancelButton: {
    display: "1 1 auto",
    marginRight: "0.5rem",
  },
  breadcrumb: {
    margin: "1rem",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    outline: "none !important",
  },
  form: {
    padding: "1.2rem",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    margin: "2rem auto",
  },
}));
