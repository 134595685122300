import { useRef } from "react";
import { useSelector } from "react-redux";
import {
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DocumentDownload, useStyles } from "./styles";
import { useEffect } from "react";
import { useDocumentApi } from "./hooks/useDocumentApi";

const Documents = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const userId = useSelector(
    (state) => state.nonPersistedReducers?.userSliceReducer?.currentUser?.id
  );

  const {
    getDocuments,
    downloadDocument,
    loading,
    documents
  } = useDocumentApi();
  const buttonRef = useRef(null);

  useEffect(() => {
    getDocuments()
  }, [userId]);

  return (
    <Grid container style={{ margin: "1rem 0.5rem" }}>
      <Grid item xs={12}>
        <Typography className={classes.desktopTitle}>
          Letölthető dokumentumok
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={6}>
          <a ref={buttonRef} />
          <Paper style={{ width: "100%", height: "100%" }}>
            <Grid container item xs={12} spacing={2}>
              {documents?.map((el) => {
                return (
                  <Grid item xs={12} style={{ display: "flex", margin: "2rem" }}>
                  <Typography>
                    {el.name}
                  </Typography>
                  <DocumentDownload
                    onClick={() => {
                      downloadDocument(el.id, buttonRef, el.name);
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{ marginLeft: "0.5rem" }}
                        size={24}
                      />
                    ) : (
                      <FileDownloadIcon />
                    )}
                  </DocumentDownload>
                </Grid>)
              })}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Documents;
