import { useState, useEffect } from "react";
import { getApi } from "api/axios";

import { Typography } from "@material-ui/core";

const ActualPrice = ({ modifierId, total, minimals, netAmount, taxRate }) => {
  const [minimal, setMinimal] = useState();

  const getActualMPrice = async () => {
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).post(`/admin/quotes/calculate/minimals`, [
        {
          modifierId: modifierId,
          itemPrice: {
            netAmount: 0,
            taxRate: 0,
            currency: "HUF",
          },
          formulaParameters: {
            minimalPrice: [],
          },
        },
      ]);

      setMinimal(
        res?.data?.find((price) => price.modifierId === modifierId)?.price
          ?.grossAmount
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getActualMPrice();
  }, [modifierId, minimals, netAmount, taxRate, total]);

  return (
    <Typography>
      {minimal} {"Ft"}
    </Typography>
  );
};

export default ActualPrice;
