import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import styled from "styled-components";

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    transform: "translateY(-1px)",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translate(16px, -1px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    width: "100%",
  },
  content: {
    width: "100%",
  },

  btn: {
    width: "100%",
    marginTop: "1.5rem",
  },
  container: {
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    // padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
    position: "relative",
  },
  title: {
    color: theme.palette.primary.textPrimary,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    lineHeight: "2rem",
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  sectionTitle: {
    color: theme.palette.primary.backgroundSecondary,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    lineHeight: "2rem",
    fontWeight: "bold",
    fontSize: "1.2rem",
    margin: "2rem 0",
  },

  detail: { margin: "0 1rem ", width: "100%" },
  fixedHeight: {
    height: 240,
  },
  breadcrumb: {
    margin: "1rem",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    outline: "none !important",
    color: theme.palette.primary.backgroundSecondary,
  },
}));
export const Row = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Title = styled.p`
  font-weight: 600;
  font-size: 1rem;
`;
export const Detail = styled.p`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
`;

export const ControlBtnContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 0.9rem;
`;

export const NameContainer = styled.div`
  padding: 1rem;
  background: ${({ theme }) => theme.palette.primary.backgroundSecondary};
  height: 4rem;
`;

export const OverLay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: grey;
  opacity: 0.4;
`;
