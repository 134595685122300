const queryOptions = [
  {
    name: "id",
    displayName: "Azonosító",
    type: "string",
    used: false,
    value: "",
  },

  {
    name: "orderId",
    displayName: "Rendelési azonosító",
    type: "string",
    used: false,
    value: "",
  },


  {
    name: "shopId",
    displayName: "Bolt azonosító",
    type: "string",
    used: false,
    value: "",
  },
  {
    name: "deliveryTarget",
    displayName: "Kiszállítási cím",   
    type: "string",
    // This will be needed if the delivery target editor is fixed
    // type: "deliveryTarget",
    used: false,
    value: "",
  },
  {
    name: "deliveryType",
    displayName: "Megrendelés típusa",
    type: "select",
    values: ["EMAIL", "POST"],
    displayedValues: ["DIGITÁLIS", "NYOMTATANDÓ"],
    used: false,
    value: "",
  },


  {
    name: "createdAt",
    displayName: "Rendelés dátuma",
    type: "date",
    used: false,
    value: "",
  },

  {
    name: "expiration",
    displayName: "Lejárat dátuma",
    type: "date",
    used: false,
    value: "",
  },
  {
    name: "status",
    displayName: "Státusz",
    type: "select",
    values: ["PENDING", "SHIPPED", "SHIPPING_READY", "SHIPPING", "REJECTED"],
    displayedValues: [
      "FÜGGŐBEN",
      "KISZÁLLÍTVA",
      "KISZÁLLÍTÁSRA KÉSZ",
      "KISZÁLLÍTÁS ALATT",
      "VISSZAUTASÍTVA",
    ],
    used: false,
    value: "",
  },

  {
    name: "sort",
    displayName: "Rendezési szempont",
    type: "key-value",
    keys: [
      { name: "id", displayName: "Azonosító" },
      { name: "shopId", displayName: "Bolt azonosító" },
      { name: "orderId", displayName: "Rendelés azonosító" },
      { name: "customerName", displayName: "Ügyfél neve" },
      { name: "status", displayName: "Státusz" },
      { name: "deliveryTarget", displayName: "Kiszállítási cím" },

    ],
    values: [
      { name: "asc", displayName: "Növekvő" },
      { name: "desc", displayName: "Csökkenő" },
    ],
    used: false,
    value: "",
  },
  {
    name: "pagination",
    displayName: "",
    type: "pagination",
    used: true,
    value: "",
  },
];

export default queryOptions;
