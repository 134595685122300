import { Dialog } from '@material-ui/core';
import GroupDetails from 'components/PhotoAdminComponents/Group/GroupDetails/GroupDetails';
import React from 'react';

const EditGroupDialog = ({
  shopId,
  editModal,
  Transition,
  editGroupModalRef,
  toggleEditModal,
  header,
  refresh,
  parent
}) => {
  return (
    <Dialog
      open={editModal}
      TransitionComponent={Transition}
      keepMounted
      ref={editGroupModalRef}
      maxWidth={false}
      onClose={toggleEditModal}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'>
      <GroupDetails
        shopId={shopId}
        currentGroup={header}
        parentHeader={parent}
        belongsTo={header.belongsTo}
        toggle={toggleEditModal}
        refresh={refresh}
        createModal={false}
      />
    </Dialog>
  );
};

export default EditGroupDialog;
