import { useTheme, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import AuthService from 'services/AuthService';

import { Wrapper, useStyles } from './styles';

const Login = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    AuthService.doLogin();
  }, []);

  return (
    <Wrapper>
      <Typography component='h1' variant='h1' className={classes.mainText}>
        Egy pillanat...
      </Typography>
      <Typography component='h2' variant='h2' className={classes.secondaryText}>
        Átirányítás a bejelentkezési felületre..
      </Typography>
    </Wrapper>
  );
};

export default Login;
