import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LayoutWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const ResultList = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Counter = styled.div`
  width: 100%;

  display: flex;
  flex-directon: row;
  justify-content: space-between;
  align-items: center;

  font-size: 1.25rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Button = styled(IconButton)({
    '&': {
        fontSize: '3rem !important',
    },
});
