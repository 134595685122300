import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    width: "100%",
  },
  content: {
    width: "100%",
  },

  btn: {
    width: "100%",
    marginTop: "1.5rem",
  },
  container: {
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    // padding: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.backgroundSecondary}`,
    borderRadius: "7px",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
    height: "300px",
    position: "relative",
  },
  title: {
    color: theme.palette.primary.textPrimary,

    lineHeight: "2rem",
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  card: { width: "100%", height: "100%" },

  cardActionArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    borderRadius: "5px",
  },

  detail: { margin: "0 1rem ", width: "100%" },
  fixedHeight: {
    height: 240,
  },

  cardTitle: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontWeight: "bold",
    letterSpacing: ".1rem",
    lineHeight: "1.54",
    textAlign: "center",
    display: "block",
    color: theme.palette.primary.backgroundSecondary,
    height: "1.8rem",
    width: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  breadcrumb: {
    margin: "1rem",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    outline: "none !important",
    color: theme.palette.primary.backgroundSecondary,
  },
}));
export const Row = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Title = styled.p`
  font-weight: 600;
  font-size: 1rem;
`;
export const Detail = styled.p`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
`;

export const ControlBtnContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 0.9rem;
`;

export const NameContainer = styled.div`
  padding: 1rem;
  background: ${({ theme }) => theme.palette.primary.backgroundSecondary};
  height: 4rem;
`;
