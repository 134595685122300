import * as yup from "yup";

const validationSchema = yup.object({
  name: yup
    .string("Adja meg az új galéria nevét")
    .min(3, "A galéria nevének legalább 3 karakter hosszúnak kell lennie")
    .required("Szükséges mező"),
  accessKey: yup
    .string("Adja meg az új galéria jelszavát")
    .min(5, "A jelszónak legalább 5 karakter hosszúnak kell lennie")
    .nullable(),
});

export default validationSchema;
