import { makeStyles } from "@material-ui/core/styles";
import sizes from "constants/sizes";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    width: "100%",
  },
  content: {
    width: "100%",
  },

  btn: {
    width: "100%",
    marginTop: "1.5rem",
  },
  container: {
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    // padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",

    position: "relative",
  },

  select: {
    margin: "1rem",
  },
  title: {
    color: theme.palette.primary.textPrimary,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    lineHeight: "2rem",
    fontWeight: "bold",
    fontSize: "1.2rem",
  },

  detail: { width: "100%" },

  accordion: {
    width: "100%",
    "& .MuiAccordion-root, & .MuiPaper-root, & .MuiAccordion-rounded, & .MuiPaper-elevation1, & .MuiPaper-rounded":
      {
        border: "0px solid transparent !important",
        borderRadius: "0px !important",
        boxShadow: "none !important",
      },
  },

  saveBtn: {
    background: theme.palette.primary.backgroundSecondary,
    color: theme.palette.primary.textPrimary,
  },

  fixedHeight: {
    height: 240,
  },

  breadcrumb: {
    margin: "1rem",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    outline: "none !important",
    color: theme.palette.primary.backgroundSecondary,
  },
}));
export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${sizes.SM}px) {
    flex-direction: row;
  }
`;
export const Row = styled.div`
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Title = styled.p`
  font-weight: 600;
  font-size: 1rem;
`;
export const Detail = styled.p`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0;
`;

export const LoadingIndicator = styled.div`
  position: absolute;
  top: 1.3rem;
  right: 2rem;
  display: flex;
  color: ${({ theme }) => theme.palette.primary.textPrimary};
`;

export const NameContainer = styled.div`
  padding: 1rem;
  background: ${({ theme }) => theme.palette.primary.backgroundSecondary};
  height: 4rem;
  position: relative;
`;

export const CalculatorTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CalculatorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const IconBorder = styled.div`
  border: 1px solid grey;
  border-radius: 5px;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
