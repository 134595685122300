import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    width: "100%",
  },
  content: {
    width: "100%",
  },

  btn: {
    width: "100%",
    marginTop: "1.5rem",
  },
  container: {
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    // padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  title: {
    color: theme.palette.primary.textPrimary,
    padding: "1rem",
    background: theme.palette.primary.backgroundSecondary,
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  fixedHeight: {
    height: 240,
  },
  breadcrumb: {
    margin: "1rem",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    outline: "none !important",
    color: theme.palette.primary.backgroundSecondary,
  },
}));
export const Row = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
`;
export const Title = styled.p`
  font-weight: 600;
`;
export const Detail = styled.p`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
`;
