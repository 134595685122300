import { useMutation } from "@tanstack/react-query";
import React from "react";
//import queries from "../../../api/queries/ProductQueries";
import { useToasterLogic } from "../../../utils/useToasterLogic";
import queries from "api/queries/ImageQueries";

export const useImageUploaderLogic = (id, setImage, baseUrl, parentId) => {
  const { successToast } = useToasterLogic();
  const [loading, setLoading] = React.useState();
  const [imageList, setImageList] = React.useState();
  const [progress, setProgress] = React.useState();

  const coverDelete = useMutation({
    mutationFn: () =>
      queries.deleteImage({
        url: baseUrl,
      }),
    onSuccess: () => {
      successToast("Sikeres törlés");
      setLoading(false);
    },
  });

  const onChange = (imageFileList, addUpdateIndex) => {
    setImageList(imageFileList);
    if (imageFileList[0]) {
      setImage(imageFileList[0]["data_url"]);
    } else {
      setImage(null);
    }
  };

  const onProgress = (uid, progress) => {
    setProgress(progress);
  };

  const onSuccess = (uid, progress) => {
    console.log("progress: ", uid, progress);
  };

  const handleUpload = async () => {
    await queries.uploadRequest({
      setLoading: setLoading,
      file: imageList[0].file,
      onProgress: onProgress,
      onSuccess: onSuccess,
      url: baseUrl,
    });
    setImageList(null);
  };

  const handleImageDelete = () => {
    coverDelete.mutate();
  };

  return {
    loading,
    progress,
    handleUpload,
    handleImageDelete,
    onChange,
    imageList,
  };
};
