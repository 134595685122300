/** @format */

import { getApi } from "api/axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useInvoiceAPI = () => {
    const [invoice, setInvoice] = useState([]);
    const [invoiceLoading, setInvoiceLoading] = useState([]);
    const [invoiceVendors, setInvoiceVendors] = useState([]);
    const shopId = useSelector(
        (state) => state.nonPersistedReducers.shopSliceReducer.shopId
    );

    const fetchInvoice = useCallback(
        async ({ IDS }) => {
            setInvoiceLoading(true);
            setInvoiceVendors([]);
            try {
                for (const id of IDS) {
                    try {
                        const res = await (
                            await getApi({
                                baseUrl:
                                    window._env_.REACT_APP_QDAK_PAYMENT_URL,
                            })
                        ).get(`/admin/shops/${shopId}/invoices/${id}`);
                        setInvoice((oldArray) => [...oldArray, res.data]);
                        setInvoiceLoading(false);
                        getInvoiceVendors({ vendorId: res.data.vendorId });
                    } catch (error) {
                        console.log(error);
                        setInvoiceLoading(false);
                    }
                }
                /*const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PAYMENT_URL })
        ).get(`/admin/shops/${shopId}/invoices/${id}`);
        setInvoice(oldArray => [...oldArray, res.data]);
        console.log(res.data)
        if(invoice.length === 0){
          setInvoice(oldArray => [...oldArray, res.data]);
          console.log(invoice)
        }
        else{
          setInvoice(oldArray => [...oldArray, res.data]);
          console.log(invoice)
        }  */
            } catch (e) {
                console.log(e);
            }
            setInvoiceLoading(false);
        },
        [shopId]
    );

    const getInvoiceVendors = useCallback(
        async ({ vendorId }) => {
            try {
                const res = await (
                    await getApi({
                        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                    })
                ).get(`/admin/shops/${shopId}/invoices/vendors/${vendorId}`);
                if (res.status === 200) {
                    setInvoiceVendors([...invoiceVendors, res.data]);
                }
            } catch (e) {
                console.log(e);
            }
        },
        [shopId]
    );

    return {
        fetchInvoice,
        invoice,
        setInvoice,
        invoiceLoading,
        getInvoiceVendors,
        invoiceVendors,
    };
};
