import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  inputBase: {},
  autocomplete: {
    "& .MuiAutocomplete-root": {
      fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    },
  },
}));
