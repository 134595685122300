import * as yup from "yup";

const nameChangeValidationSchema = yup.object({
  lastName: yup
    .string("Adja meg vezetéknevét")
    .matches(
      /^([A-ZÁÉÚŐÓÜÖÍ]([A-ZÁÉÚŐÓÜÖÍa-záéúőóüöí -.]+\s?))$/,
      "A névnek nagybetűvel kell kezdődnie és számokat nem tartalmazhat"
    )
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  firstName: yup
    .string("Adja meg vezetéknevét")
    .matches(
      /^([A-ZÁÉÚŐÓÜÖÍ]([A-ZÁÉÚŐÓÜÖÍa-záéúőóüöí -.]+\s?))$/,
      "A névnek nagybetűvel kell kezdődnie és számokat nem tartalmazhat"
    )
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
});

export default nameChangeValidationSchema;
