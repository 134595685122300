import { getApi } from "api/axios";
import { useState } from "react";

export const useNotificationAPI = () => {
  const [notifications, setNotifications] = useState();

  const fetchNotifications = async () => {
    try {
      const { data, status } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/admin/pop-up-notifications/active`);

      if (status === 200) {
        setNotifications(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return { notifications, fetchNotifications };
};
