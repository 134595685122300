import { useEffect, useState } from "react";

import {
  Breadcrumbs,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Typography,
  useTheme,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

import {
  ButtonsWrapper,
  CalculatorTitleWrapper,
  CalculatorWrapper,
  IconBorder,
  LoadingIndicator,
  NameContainer,
  Row,
  RowWrapper,
  Title,
  useStyles,
} from "./styles";

import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";
import Calculator from "components/SuperAdminComponents/Calculator/Calculator";

import { useModifierApi } from "./hooks/useModifierApi";
import { useQuotesApi } from "./hooks/useQuotesApi";
import MinimalPrice from "components/SuperAdminComponents/ActualPrice/ActualPrice";
import { useDispatch, useSelector } from "react-redux";
import { setFormulas } from "redux/slices/FormulaSlice";

const PriceAdjustment = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [loading, setLoading] = useState();

  const dispatch = useDispatch();

  const { getModifiers, modifiers } = useModifierApi();
  const {
    getMinimals,
    postMinimal,
    minimals,
    getCommissions,
    postCommission,
    commissions,
  } = useQuotesApi();

  const [editing, setEditing] = useState();
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedModifier, setSelectedModifier] = useState(3);
  const [selectedCommission, setSelectedCommission] = useState(
    commissions?.find((commission) => commission.modifierId === 3)
  );
  const [selectedMinimal, setSelectedMinimal] = useState(
    minimals?.find((commission) => commission.modifierId === 3)
  );
  const [cCalcFormula, setCCalcFormula] = useState();
  const [cICalcFormula, setCICalcFormula] = useState();
  const [mCalcFormula, setMCalcFormula] = useState();

  const cFormula = useSelector(
    (state) => state.nonPersistedReducers?.formulaSliceReducer?.cFormula
  );
  const cIFormula = useSelector(
    (state) => state.nonPersistedReducers?.formulaSliceReducer?.cIFormula
  );
  const mFormula = useSelector(
    (state) => state.nonPersistedReducers?.formulaSliceReducer?.mFormula
  );

  const [dtcp, setDtcp] = useState({
    formulaKey: "dtcp",
    formulaValue: 0,
    isDynamic: false,
  });
  const [dtcf, setDtcf] = useState({
    formulaKey: "dtcf",
    formulaValue: 0,
    isDynamic: false,
  });
  const [print, setPrint] = useState({
    formulaKey: "print",
    formulaValue: 0,
    isDynamic: false,
  });
  const [simplePay, setSimplePay] = useState({
    formulaKey: "simplePay",
    formulaValue: 0,
    isDynamic: false,
  });
  const [userInput] = useState({
    formulaKey: "userInput",
    isDynamic: true,
  });

  const handleCopy = () => {
    dispatch(
      setFormulas({
        cFormula: cCalcFormula,
        cIFormula: cICalcFormula,
        mFormula: mCalcFormula,
      })
    );
  };

  const handlePaste = () => {
    setCCalcFormula(cFormula);
    setCICalcFormula(cIFormula);
    setMCalcFormula(mFormula);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (editing) {
      setExpanded(isExpanded ? panel : false);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    console.log({
      modifierId: selectedCommission.modifierId,
      calcFormula: cCalcFormula,
      formulaParams: [print, dtcf, dtcp, simplePay, userInput],
      commissionInverse: {
        calcFormula: cICalcFormula,
        modifierId: selectedCommission.modifierId,
        formulaParams: [print, dtcf, dtcp, simplePay, userInput],
      },
    });

    await postCommission({
      modifierId: selectedCommission.modifierId,
      calcFormula: cCalcFormula,
      formulaParams: [print, dtcf, dtcp, simplePay, userInput],
      commissionInverse: {
        calcFormula: cICalcFormula,
        modifierId: selectedCommission.modifierId,
        formulaParams: [print, dtcf, dtcp, simplePay, userInput],
      },
    });
    await postMinimal({
      modifierId: selectedMinimal.modifierId,
      formulaParams: [print, dtcf, dtcp, simplePay],
      calcFormula: mCalcFormula,
    });
    await getCommissions();
    await getMinimals();
    setLoading(false);
    setSelectedModifier(3);
  };

  const handleReset = () => {
    setDtcf({
      ...dtcf,
      formulaValue: selectedCommission?.formulaParams?.find(
        (param) => param.formulaKey === "dtcf"
      )?.formulaValue,
    });
    setDtcp({
      ...dtcp,
      formulaValue: selectedCommission?.formulaParams?.find(
        (param) => param.formulaKey === "dtcp"
      )?.formulaValue,
    });
    setPrint({
      ...print,
      formulaValue: selectedCommission?.formulaParams?.find(
        (param) => param.formulaKey === "print"
      )?.formulaValue,
    });
    setSimplePay({
      ...simplePay,
      formulaValue: selectedCommission?.formulaParams?.find(
        (param) => param.formulaKey === "simplePay"
      )?.formulaValue,
    });
    setCCalcFormula(selectedCommission?.calcFormula);
    setCICalcFormula(selectedCommission?.commissionInverse?.calcFormula);
    setMCalcFormula(selectedMinimal?.calcFormula);
  };

  useEffect(() => {
    getModifiers();
    getMinimals();
    getCommissions();
  }, []);

  useEffect(() => {
    setSelectedCommission(
      commissions?.findLast((commission) => commission.modifierId === 3)
    );
    setSelectedMinimal(
      minimals?.findLast((minimal) => minimal.modifierId === 3)
    );
    console.log(commissions?.findLast((minimal) => minimal.modifierId === 3));
  }, [commissions, minimals]);

  useEffect(() => {
    setSelectedCommission(
      commissions?.findLast(
        (commission) => commission.modifierId === selectedModifier
      )
    );
    setSelectedMinimal(
      minimals?.findLast((minimal) => minimal.modifierId === selectedModifier)
    );
  }, [selectedModifier]);

  useEffect(() => {
    setDtcf({
      ...dtcf,
      formulaValue: selectedCommission?.formulaParams?.find(
        (param) => param.formulaKey === "dtcf"
      )?.formulaValue,
    });
    setDtcp({
      ...dtcp,
      formulaValue: selectedCommission?.formulaParams?.find(
        (param) => param.formulaKey === "dtcp"
      )?.formulaValue,
    });
    setPrint({
      ...print,
      formulaValue: selectedCommission?.formulaParams?.find(
        (param) => param.formulaKey === "print"
      )?.formulaValue,
    });
    setSimplePay({
      ...simplePay,
      formulaValue: selectedCommission?.formulaParams?.find(
        (param) => param.formulaKey === "simplePay"
      )?.formulaValue,
    });
    setCCalcFormula(selectedCommission?.calcFormula);
    setCICalcFormula(selectedCommission?.commissionInverse?.calcFormula);
    setMCalcFormula(selectedMinimal?.calcFormula);
  }, [selectedCommission, selectedMinimal]);

  useEffect(() => {
    console.log("cFormula:", cFormula);
    console.log("cIFormula:", cIFormula);
    console.log("mFormula:", mFormula);
  }, [cFormula, cIFormula, mFormula]);

  return (
    <>
      <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
          <Typography color="inherit" variant="body1">
            Árak szabályozása
          </Typography>
        </Breadcrumbs>
        <div>
          <CssBaseline />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth={"xl"} className={classes.container}>
              <Grid
                container
                spacing={3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={12} md={8}>
                  <Paper className={classes.paper}>
                    <NameContainer>
                      <Typography className={classes.title}>
                        Ár kalkulátor
                      </Typography>
                    </NameContainer>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className={classes.select}
                      value={selectedModifier}
                      label="Age"
                      onChange={(e) => {
                        setSelectedModifier(e.target.value);
                      }}
                    >
                      {modifiers?.map((modifier) => (
                        <MenuItem value={modifier?.id}>
                          {modifier?.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <RowWrapper>
                      <Row>
                        <Title>DT jutalék (százalék):</Title>
                        <TextField
                          className={classes.detail}
                          disabled={!editing}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setDtcp({ ...dtcp, formulaValue: e.target.value });
                          }}
                          value={dtcp?.formulaValue}
                        />
                      </Row>
                      <Row>
                        <Title>SimplePay jutalék:</Title>
                        <TextField
                          className={classes.detail}
                          disabled={!editing}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setSimplePay({
                              ...simplePay,
                              formulaValue: e.target.value,
                            });
                          }}
                          value={simplePay?.formulaValue}
                        />
                      </Row>
                    </RowWrapper>
                    <RowWrapper>
                      <Row>
                        <Title>Előállítási költség:</Title>
                        <TextField
                          className={classes.detail}
                          disabled={!editing}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                Ft
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setPrint({
                              ...print,
                              formulaValue: e.target.value,
                            });
                          }}
                          value={print?.formulaValue}
                        />
                      </Row>
                      <Row>
                        <Title>DT jutalék (fix):</Title>
                        <TextField
                          className={classes.detail}
                          disabled={!editing}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                Ft
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setDtcf({ ...dtcf, formulaValue: e.target.value });
                          }}
                          value={dtcf?.formulaValue}
                        />
                      </Row>
                    </RowWrapper>

                    <RowWrapper>
                      <Accordion
                        style={{
                          width: "100%",
                        }}
                        disabled={!editing}
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                      >
                        <AccordionSummary>
                          <CalculatorTitleWrapper>
                            <Title>
                              Jutalék számító formula (
                              {"Fotós profit => Teljes összeg"}):
                            </Title>
                            <Typography
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "0.5rem",
                              }}
                            >
                              {cCalcFormula}
                            </Typography>
                          </CalculatorTitleWrapper>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "auto",
                            }}
                          >
                            <IconBorder>
                              <EditIcon style={{ color: "grey" }} />
                            </IconBorder>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CalculatorWrapper>
                            <Calculator
                              setCalcFormula={setCCalcFormula}
                              calcFormula={cCalcFormula}
                              formulaParams={[
                                print,
                                dtcf,
                                dtcp,
                                simplePay,
                                userInput,
                              ]}
                            />
                          </CalculatorWrapper>
                        </AccordionDetails>
                      </Accordion>
                    </RowWrapper>
                    <RowWrapper>
                      <Accordion
                        style={{
                          width: "100%",
                        }}
                        disabled={!editing}
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                      >
                        <AccordionSummary>
                          <CalculatorTitleWrapper>
                            <Title>
                              Inverz jutalék számító formula (
                              {"Teljes összeg => Fotós profit"}):
                            </Title>
                            <Typography
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "0.5rem",
                              }}
                            >
                              {cICalcFormula}
                            </Typography>
                          </CalculatorTitleWrapper>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "auto",
                            }}
                          >
                            <IconBorder>
                              <EditIcon style={{ color: "grey" }} />
                            </IconBorder>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CalculatorWrapper>
                            <Calculator
                              inverse={true}
                              setCalcFormula={setCICalcFormula}
                              calcFormula={cICalcFormula}
                              formulaParams={[
                                print,
                                dtcf,
                                dtcp,
                                simplePay,
                                userInput,
                              ]}
                            />
                          </CalculatorWrapper>
                        </AccordionDetails>
                      </Accordion>
                    </RowWrapper>

                    <RowWrapper>
                      <Accordion
                        className={classes.accordion}
                        disabled={!editing}
                        expanded={expanded === "panel3"}
                        onChange={handleChange("panel3")}
                      >
                        <AccordionSummary>
                          <CalculatorTitleWrapper>
                            <Title>Minimális ár számító formula:</Title>
                            <Typography
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "0.5rem",
                              }}
                            >
                              {mCalcFormula}
                            </Typography>
                          </CalculatorTitleWrapper>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "auto",
                            }}
                          >
                            <IconBorder>
                              <EditIcon style={{ color: "grey" }} />
                            </IconBorder>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CalculatorWrapper>
                            <Calculator
                              setCalcFormula={setMCalcFormula}
                              calcFormula={mCalcFormula}
                              formulaParams={[print, dtcf, dtcp, simplePay]}
                            />
                          </CalculatorWrapper>
                        </AccordionDetails>
                      </Accordion>
                    </RowWrapper>
                    <RowWrapper
                      style={{
                        justifyContent: "space-around",
                        marginTop: "1rem",
                      }}
                    >
                      <Button
                        style={{
                          background: editing
                            ? theme.palette.primary.backgroundSecondary
                            : "grey",
                          color: editing
                            ? theme.palette.primary.textPrimary
                            : "lightgrey",
                        }}
                        disabled={!editing}
                        onClick={() => {
                          handleCopy();
                        }}
                      >
                        Formulák másolása
                        <ContentCopyIcon />
                      </Button>
                      <Button
                        style={{
                          background:
                            !editing ||
                            (cFormula === "" &&
                              cIFormula === "" &&
                              mFormula === "")
                              ? "grey"
                              : theme.palette.primary.backgroundSecondary,
                          color: !editing
                            ? "lightgrey"
                            : theme.palette.primary.textPrimary,
                        }}
                        disabled={
                          !editing ||
                          (cFormula === "" &&
                            cIFormula === "" &&
                            mFormula === "")
                        }
                        onClick={() => {
                          handlePaste();
                        }}
                      >
                        Formulák beillesztése
                        <ContentPasteIcon />
                      </Button>
                    </RowWrapper>
                    <ButtonsWrapper>
                      {!editing && (
                        <Button onClick={() => setEditing(true)}>
                          <EditIcon
                            style={{
                              color: theme.palette.primary.backgroundSecondary,
                            }}
                          />{" "}
                          Szerkesztés
                        </Button>
                      )}
                      {editing && (
                        <Button
                          className={classes.saveBtn}
                          onClick={() => {
                            setOpen(true);
                            setExpanded(false);
                          }}
                        >
                          Mentés
                        </Button>
                      )}
                      {editing && (
                        <Button
                          onClick={() => {
                            handleReset();
                            setEditing(false);
                            setExpanded(false);
                          }}
                        >
                          Mégsem
                        </Button>
                      )}
                    </ButtonsWrapper>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Paper className={classes.paper}>
                    <NameContainer>
                      <Typography className={classes.title}>
                        Minimális árak
                      </Typography>
                      {loading && (
                        <LoadingIndicator>
                          <Typography>Árak frissítése folyamatban</Typography>
                          <CircularProgress
                            size={20}
                            style={{
                              color: theme.palette.primary.textPrimary,
                              marginLeft: "0.5rem",
                            }}
                          />
                        </LoadingIndicator>
                      )}
                    </NameContainer>
                    <Table>
                      <TableHead>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Kép formátum
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Minimális Ár
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {modifiers?.map((modifier, idx) => (
                          <TableRow>
                            <TableCell>{modifier?.name}</TableCell>
                            <TableCell
                              style={{
                                color: theme.palette.primary.info,
                                fontWeight: "bold",
                              }}
                            >
                              <MinimalPrice
                                modifierId={modifier?.id}
                                minimals={minimals}
                                printer
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
            <ConfirmDialog
              open={open}
              onClose={() => setOpen(false)}
              onOk={() => {
                handleUpdate();
                setEditing(false);
                setOpen(false);
              }}
              title={"Biztosan így akarod elmenteni az adatokat?"}
              closeButtonText={"Nem"}
              functionButtonText={"Igen"}
              functionButtonColor={theme.palette.primary.success}
              multipleButtons={true}
            />
          </main>
        </div>
      </div>
    </>
  );
};

export default PriceAdjustment;
