import { getApi } from "api/axios";
import { errorToast, successToast } from "components/common/Toast/Toast";
import { useCallback, useState } from "react";

export const usePrinterApi = () => {
  const [deliveries, setDeliveries] = useState();
  const [deliveriesLength, setDeliveriesLength] = useState(0);
  const [loading, setLoading] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [groups, setGroups] = useState();
  const [groupsLength, setGroupsLength] = useState(0);

  const fetchPrintableGroups = useCallback(async ({ queryString }) => {
    setLoading(true);
    try {
      setGroups(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(
        `/admin/groups/filter${queryString}${
          queryString ? "&" : "?"
        }topLevel=true&isPrinted=true&isPrintingDone=false`
      );
      if (res.status === 200) {
        const groups = res.data._embedded.groupDTOList.map((group) => ({
          ...group,
          selected: false,
        }));
        setLoading(false);
        setGroupsLength(res.data.page.totalElements);
        setGroups(groups);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, []);

  const printGroupFinal = async ({ group }) => {
    setLoading(true);
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).patch(`/admin/groups/${group.id}/printingDone?isPrintingDone=true`);
      if (res.status >= 200 && res.status < 300) {
        successToast(
          `Sikeresen továbbította nyomtatásra a ${group.name} nevű galériához tartozó rendeléseket!`
        );
      }
    } catch (e) {
      errorToast(
        `Sikertelen művelet! A ${group.name} nevű galériához tartozó rendeléseket nem sikerült nyomtatásra küldeni! Kérjük próbálja újra később!`
      );
      console.log(e);
    }
  };

  const fetchDeliveries = useCallback(async ({ queryString }) => {
    setLoading(true);
    try {
      setDeliveries(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).get(`admin/deliveries${queryString}`);
      if (res.status === 200) {
        setLoading(false);
        const deliveries = res.data._embedded.deliveryDTOList.map(
          (delivery) => ({
            ...delivery,
            selected: false,
          })
        );
        setDeliveries(deliveries);
        setDeliveriesLength(res.data.page.totalElements);
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage(e);
      console.log(e);
    }
  }, []);

  const [delivery, setDelivery] = useState(null);

  const fetchDelivery = useCallback(async ({ id }) => {
    setDelivery(null);
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).get(`/admin/deliveries/${id}`);

      setDelivery(res.data);
    } catch (e) {
      setErrorMessage(e);
      console.log(e);
    }
  }, []);

  const putDelivery = useCallback(async ({ body }) => {
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).put(`/admin/deliveries`, body);
      setDelivery(res.data);
    } catch (e) {
      setErrorMessage(e);
      console.log(e);
    }
  }, []);

  //   const postOrderVerified = useCallback(
  //     async ({ orderBody }) => {
  //       try {
  //         await (
  //           await getApi({ baseUrFl: window._env_.REACT_APP_QDAK_ORDER_URL })
  //         ).put(`/admin/shops/${shopId}/orders`, {
  //           ...orderBody,
  //           status: "VERIFIED",
  //         });
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     },
  //     [shopId]
  //   );

  const handlePrint = useCallback(async ({ deliveries, setAllSelected }) => {
    try {
      setLoading(true);
      await deliveries.forEach(async (delivery) => {
        if (delivery.selected) {
          const { status } = await (
            await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PAYMENT_URL })
          ).post(`/admin/deliveries/${delivery.id}/reprint`);
          if (status === 200) {
            successToast("Sikeres művelet");
          } else {
            errorToast("Sikertelen művelet!");
          }
        }
      });
      setLoading(false);
      fetchDeliveries();

      setAllSelected(false);
    } catch (e) {
      setLoading(false);
      errorToast("Sikertelen művelet!");
      console.log(e);
    }
  }, []);
  const handleDeliveryShipped = useCallback(
    async ({ deliveries, setAllSelected }) => {
      setLoading(true);
      try {
        await deliveries.forEach(async (delivery) => {
          if (delivery.selected) {
            delete delivery.selected;
            await (
              await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PAYMENT_URL })
            ).put(`/admin/deliveries`, {
              ...delivery,
              status: "SHIPPED",
            });
          }
        });
        setLoading(false);
        fetchDeliveries();
        successToast("Sikeres művelet");
        setAllSelected(false);
      } catch (e) {
        setLoading(false);
        errorToast("Sikertelen művelet!");
        console.log(e);
      }
    },
    []
  );

  const deleteDelivery = useCallback(async ({ id }) => {
    try {
      await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).delete(`/admin/deliveries/${id}`);
    } catch (e) {
      setErrorMessage(e);
      console.log(e);
    }
  }, []);

  return {
    fetchPrintableGroups,
    groupsLength,
    printGroupFinal,
    groups,
    setGroups,
    fetchDeliveries,
    deliveries,
    setDeliveries,
    deliveriesLength,
    fetchDelivery,
    delivery,
    putDelivery,
    handlePrint,
    handleDeliveryShipped,
    deleteDelivery,
    errorMessage,
    loading,
    // postOrderVerified,
  };
};
