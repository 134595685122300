import { getApi } from "api/axios";
import { useCallback, useState } from "react";

export const useShopAPI = () => {
  const [galleriesOfShop, setGalleriesOfShop] = useState(null);
  const [shopLoading, setShopLoading] = useState(false);

  const getGalleriesOfShop = useCallback(async (id) => {
    setShopLoading(true);
    try {
      setGalleriesOfShop(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/admin/shops/${id}/groups`);
      if (res.status === 200) {
        setShopLoading(false);
        setGalleriesOfShop(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);
  return {
    getGalleriesOfShop,
    galleriesOfShop,
    shopLoading,
  };
};
