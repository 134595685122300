import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 1.5rem 0;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  padding: 0.5rem 2rem;
`;

export const Title = styled.h1`
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
  font-size: 20px;
  margin: 40px 0;
  text-align: center;

  height: 4rem;
  @media (min-width: 600px) {
    font-size: 32px;
    margin: 20px 0;
  }
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0rem",
    overflow: "auto",
  },
  confirmBtn: {
    color: "white",
    width: "100%",
    height: "auto",
    padding: "1rem",
    margin: "0 2rem",
    backgroundColor: theme.palette.primary.backgroundSecondary,
    "&:hover": {
      backgroundColor: theme.palette.primary.backgroundPrimary,
    },
  },

  title: {
    color: theme.palette.primary.backgroundSecondary,
    borderBottom: `3px solid ${theme.palette.primary.backgroundSecondary}`,
  },
  cancelBtn: {
    color: "white",
    width: "100%",
    height: "auto",
    padding: "1rem",
    margin: "0 2rem",
    backgroundColor: theme.palette.primary.backgroundSecondary,

    "&:hover": {
      backgroundColor: theme.palette.primary.backgroundPrimary,
    },
  },
  groupPass: {
    marginTop: "24px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0",
    },
    width: "100%",
  },
  tableBorder: {
    borderTop: "2px solid black",
  },
  customCell: {
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: ".6rem",
    // fontSize: ".8rem",
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      fontSize: ".8rem",
    },
  },
  disabledCell: {
    color: "gray",
    background: "whitesmoke",
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: ".6rem",
    // fontSize: ".8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: ".8rem",
    },
  },
  categoryRow: {
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: ".8rem",
    fontWeight: "bold",
    letterSpacing: ".1rem",
    lineHeight: "1.54",
    backgroundColor: "#f0f0f0",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 0,
    margin: 0,
  },

  formlabel: {
    "& .MuiTypography-root": {
      fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    },
  },
  tablecontainer: {
    marginTop: "1rem",
    width: "100%",
    // width: "max-content",
  },
  grid: {
    width: "330px",
    alignSelf: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      width: "600px",
    },
  },
  huf: {
    width: "6rem",
  },
  date: {
    width: "10rem",
  },
}));
