const queryOptions = [
  {
    name: "name",
    displayName: "Iskola neve",
    type: "groupName",
    used: false,
    value: "",
  },
  {
    name: "createdAt",
    displayName: "Létrehozás dátuma",
    type: "date",
    used: false,
    value: "",
  },
  {
    name: "isActive",
    displayName: "Státusz",
    type: "select",
    values: [true, false],
    displayedValues: ["AKTÍV", "INAKTÍV"],
    used: false,
    value: "",
  },
  {
    name: "sort",
    displayName: "Rendezési szempont",
    type: "key-value",
    keys: [
      { name: "name", displayName: "Galéria neve" },
      { name: "createdAt", displayName: "Galéria létrehozásának dátuma" },
    ],
    values: [
      { name: "asc", displayName: "Növekvő" },
      { name: "desc", displayName: "Csökkenő" },
    ],
    used: false,
    value: "",
  },
  {
    name: "pagination",
    displayName: "",
    type: "pagination",
    used: true,
    value: "",
  },
];

export default queryOptions;
