import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserApi } from "../hooks/useUserApi";
import { useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import { Detail, Row, Title, useStyles } from "./styles";
import { Button, Link, Typography } from "@material-ui/core";
import { useShopAPI } from "../hooks/useShopApi";
import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";
import { useState } from "react";

const User = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = useState(false);

  const { getGalleriesOfShop, galleriesOfShop } = useShopAPI();
  const { fetchUser, user, putUser } = useUserApi();

  const getNumberOfActiveGroups = (groups) => {
    let numberOfActiveGroups = 0;
    groups?.forEach((group) => group?.isActive && numberOfActiveGroups++);
    return numberOfActiveGroups;
  };

  const handleStatus = async (user) => {
    const newUser = { ...user, active: !user?.active };
    await putUser(newUser);
    fetchUser(id);
  };

  useEffect(() => {
    if (id !== null) {
      fetchUser(id);
    }
  }, []);

  useEffect(() => {
    if (user !== null) {
      getGalleriesOfShop(user?.ownedShops[0]?.id);
    }
  }, [user]);

  return (
    <>
      {user && (
        <div className={classes.root}>
          <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
            <Link onClick={() => navigate("/superadmin/users")}>
              {" "}
              <Typography
                color="inherit"
                variant="body1"
                style={{ cursor: "pointer" }}
              >
                Felhasználók kezelése
              </Typography>
            </Link>
            <Typography>{user?.username}</Typography>
          </Breadcrumbs>
          <div>
            <CssBaseline />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Container maxWidth={"xl"} className={classes.container}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8} lg={6}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.title}>
                        Kapcsolati adatok
                      </Typography>
                      <Row>
                        <Title>Név:</Title>
                        <Detail>{`${user?.contact?.firstname} ${user?.contact?.lastname}`}</Detail>
                      </Row>
                      <Row>
                        <Title>Felhasználónév:</Title>
                        <Detail>{user?.username}</Detail>
                      </Row>
                      <Row>
                        <Title>Email:</Title>
                        <Detail>{user?.contact?.email}</Detail>
                      </Row>
                      <Row>
                        <Title>Telefon:</Title>
                        <Detail>{user?.contact?.phone}</Detail>
                      </Row>
                      <Row>
                        <Title>Cím:</Title>
                        <Detail>{`${user?.contact?.address?.zipCode},${user?.contact?.address?.city} ${user?.contact?.address?.streetAndNumber}`}</Detail>
                      </Row>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4} lg={6}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.title}>
                        Számlázási adatok
                      </Typography>
                      <Row>
                        <Title>Név:</Title>
                        <Detail>{`${user?.contact?.firstname} ${user?.contact?.lastname}`}</Detail>
                      </Row>
                      <Row>
                        <Title>Adószám:</Title>
                        <Detail>{user?.billingInfo?.vatNumber}</Detail>
                      </Row>

                      <Row>
                        <Title>Cím:</Title>
                        <Detail>{`${user?.billingInfo?.billingAddress?.zipCode},${user?.billingInfo?.billingAddress?.city} ${user?.billingInfo?.billingAddress?.streetAndNumber}`}</Detail>
                      </Row>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.title}>
                        Bolt adatok
                      </Typography>
                      <Row>
                        <Title>Bolt neve:</Title>
                        <Detail>{user.ownedShops[0]?.name}</Detail>
                      </Row>
                      <Row>
                        <Title>Galériák száma:</Title>
                        <Detail>
                          {galleriesOfShop?.length
                            ? galleriesOfShop?.length
                            : "0"}{" "}
                          db
                        </Detail>
                      </Row>

                      <Row>
                        <Title>Aktív galériák száma:</Title>
                        <Detail>
                          {`${getNumberOfActiveGroups(galleriesOfShop)} db`}
                        </Detail>
                      </Row>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.title}>
                        Fiók állapota
                      </Typography>

                      <Row>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            color: user?.active
                              ? theme.palette.primary.success
                              : theme.palette.primary.error,
                          }}
                        >
                          {user?.active ? "Aktív" : "Inaktív"}
                        </Typography>
                      </Row>
                    </Paper>
                  </Grid>
                  {/*  {JSON.stringify(user)}
                   {galleriesOfShop && JSON.stringify(galleriesOfShop)} */}
                </Grid>
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  className={classes.btn}
                >
                  {user?.active ? "Fiók deaktiválása" : "Fiók aktiválása"}
                </Button>
              </Container>
              <ConfirmDialog
                open={open}
                onClose={() => setOpen(false)}
                onOk={() => {
                  handleStatus(user);
                  setOpen(false);
                }}
                title={
                  user?.active
                    ? "Biztosan deaktiválni akarod a fiókot?"
                    : "Biztosan aktiválni akarod a fiókot?"
                }
                closeButtonText={"Nem"}
                functionButtonText={"Igen"}
                functionButtonColor={theme.palette.primary.success}
                multipleButtons={true}
              />
            </main>
          </div>
        </div>
      )}
    </>
  );
};

export default User;
