import { toast } from 'react-toastify';

export const useToasterLogic = () => {
  const toastStlye = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  };
  const progressToast = (message) => {
    toast.info(message, toastStlye);
  };
  const successToast = (message) => {
    toast.success(message, toastStlye);
  };
  const errorToast = (errorMsg) => {
    toast.error(`${'Hiba'}: ${errorMsg}`, toastStlye);
  };

  const warningToast = (warningMsg) => {
    toast.error(`${'Figyelem'}: ${warningMsg}`, toastStlye);
  };
  return { successToast, errorToast, warningToast, progressToast };
};
