import styled from "styled-components";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/styles";
import { forwardRef } from "react";
import sizes from "constants/sizes";

export const Transition = forwardRef((props, ref) => {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  appBarSpacer: {
    height: "80px",
  },
  content: {
    marginTop: "-3rem",
    marginBottom: "3rem",
    flexGrow: 1,
    overflow: "auto",
  },
  newGroupBtn: {
    padding: "1.5rem 5rem",
    margin: "3rem 0",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.backgroundSecondary,
    },
  },
  shopsTitle: {
    fontSize: "2rem",
  },
  breadcrumb: {
    margin: "1rem",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    outline: "none !important",
    color: theme.palette.primary.backgroundSecondary,
  },
  container: {},
}));

export const NewShopWrapper = styled.div`
  width: 100%;
  padding-top: 3rem;
  display: grid;
  place-items: center;
`;

export const ShopsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
export const UpdateIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  @media (min-width: ${sizes.MD}px) {
    position: absolute;
    margin-bottom: 0;
    bottom: 0.5rem;
    right: 0.5rem;
  }
`;

export const ShopCreator = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;
