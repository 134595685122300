import * as yup from "yup";

const billingAddressChangeSchema = yup.object({
  country: yup
    .string("Adja meg országát")
    .min(1, "Adja meg országát")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  city: yup
    .string("Adja meg városát")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  zipCode: yup
    .number("Adja meg városának irányítószámát")
    .typeError("Kizárólag számok megengedettek")
    .min(999, "Irányítószáma egy 4 jegyű szám")
    .max(9999, "Irányítószáma egy 4 jegyű szám")
    .required("Szükséges mező"),
  streetAndNumber: yup
    .string("Adja meg a közterület nevét")
    .min(1, "Adja meg a közterület nevét")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
});

export default billingAddressChangeSchema;
