const reportingQueryOptions = [ 
    {
      name: "report",
      displayName: "Jelentés dátuma",
      type: "reportingDate",
      used: false,
      value: "",
    },    
    {
      name: "pagination",
      displayName: "",
      type: "pagination",
      used: true,
      value: "",
    },
  ];
  
  export default reportingQueryOptions;
  