import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0rem",
    overflow: "auto",
  },
  table: {
    width: "350px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  confirmBtn: {
    color: "white",
    width: "100%",
    height: "auto",
    padding: "1rem",
    margin: "0 2rem",
    backgroundColor: "#D2B48C",
    "&:hover": {
      backgroundColor: "#C4A484",
    },
  },
  cancelBtn: {
    color: "white",
    width: "100%",
    height: "auto",
    padding: "1rem",
    margin: "0 2rem",
    backgroundColor: "#D2B48C",

    "&:hover": {
      backgroundColor: "#C4A484",
    },
  },
  groupPass: {
    marginTop: "24px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0",
    },
    width: "100%",
  },
  tableBorder: {
    borderTop: "2px solid black",
  },
  customCell: {
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    padding: "0.5rem",
    fontSize: ".6rem",
    // fontSize: ".8rem",
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      fontSize: ".8rem",
    },
  },
  nameCell: {
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    padding: "0.5rem",
    fontSize: ".6rem",
    maxWidth: "200px",
    // fontSize: ".8rem",
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      fontSize: ".8rem",
    },
  },
  disabledNameCell: {
    color: "gray",
    background: "whitesmoke",
    textTransform: "uppercase",
    maxWidth: "200px",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: ".6rem",
    // fontSize: ".8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: ".8rem",
    },
  },
  disabledCell: {
    color: "gray",
    background: "whitesmoke",
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: ".6rem",
    // fontSize: ".8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: ".8rem",
    },
  },
  categoryRow: {
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: ".8rem",
    fontWeight: "bold",
    letterSpacing: ".1rem",
    lineHeight: "1.54",
    backgroundColor: "#f0f0f0",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 0,
    margin: 0,
  },
  tablecontainer: {
    marginTop: "1rem",
    width: "100%",
    // width: "max-content",
  },
  grid: {
    width: "330px",
    alignSelf: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      width: "600px",
    },
  },
  huf: {
    width: "6rem",
  },
  date: {
    width: "10rem",
  },
}));
