import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "calc(100% - 1rem)",
    padding: "0.5rem",
    wordBreak: "break-word",
    margin: "0.5rem",
  },
}));
