import React from 'react'
import { IconButton } from "@material-ui/core"

const ActionButton = ({ icon, clickHandler }) => {
    return (
        <IconButton onClick={clickHandler}>
            {icon}
        </IconButton>
    )
}

export default ActionButton