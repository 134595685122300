import {
  Breadcrumbs,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Zoom,
} from "@material-ui/core";
import LoadIndicator from "components/common/LoadIndicator/LoadIndicator";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useUserApi } from "./hooks/useUserApi";
import { useStyles } from "./styles";

const Users = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();

  const { loading, users, fetchUsers } = useUserApi();

  const routeChange = (id) => {
    navigate(`${id}`);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
        <Typography color="inherit" variant="body1">
          Felhasználók kezelése
        </Typography>
      </Breadcrumbs>
      {loading ? (
        <LoadIndicator color={theme.palette.primary.light} height={202} />
      ) : (
        <Grid
          container
          spacing={3}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid xs={12} md={8} item>
            <Zoom in={true} timeout={700}>
              <Paper className={classes.paper}>
                <Table size="small">
                  <TableHead
                    style={{
                      backgroundColor:
                        theme.palette.primary.backgroundSecondary,
                      color: "whitesmoke",
                    }}
                  >
                    <TableRow>
                      <TableCell className={classes.hideXs} align="center">
                        <Typography className={classes.heading}>#</Typography>
                      </TableCell>
                      <TableCell className={classes.hideXs} align="center">
                        <Typography className={classes.heading}>
                          Azonosító
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography className={classes.heading}>
                          Felhasználó név
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography className={classes.heading}>
                          Státusz
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users &&
                      users?.map((user, idx) => (
                        <TableRow
                          onClick={() => {
                            routeChange(user?.id);
                          }}
                          key={`order_${idx}`}
                          className={classes.tableRow}
                        >
                          <TableCell className={classes.hideXs} align="center">
                            <Typography>{idx + 1}</Typography>
                          </TableCell>
                          <TableCell className={classes.hideXs} align="center">
                            <Typography>{user?.id}</Typography>
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            <Typography>{user?.username}</Typography>
                          </TableCell>

                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            <Typography>
                              {user?.active === true ? "Aktív" : "Inaktív"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>{" "}
              </Paper>
            </Zoom>{" "}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Users;
