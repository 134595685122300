import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Checkbox } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import huLocale from "date-fns/locale/hu";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import validationSchema from "validation/Group/Schema";
import { useStyles, ButtonsWrapper, Title } from "./styles";
import { useProductAPI } from "../hooks/useProductAPI";

const ProductDetails = ({
  refresh,
  toggle,
  availableModifiers,
  currentProduct,
  productImage,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { putProduct, deleteProduct } = useProductAPI();

  const getCurrentHeader = (cg) => {
    if (cg !== undefined && cg) {
      let cgCopy = { ...cg };
      delete cgCopy.modifiers;
      return cgCopy;
    } else {
      return {
        name: "",
        createdAt: new Date(),
        isActive: false,
        inheritModifiers: false,
      };
    }
  };

  const [currentProductHeader, setCurrentGroupHeader] = useState(
    getCurrentHeader(currentProduct)
  );

  const getCurrentModifiers = (am, cg) => {
    let cm = [...am];
    // For now, modifiers are enabled by default which could result in a strange behaviour (objects with null unit price)
    cm = cm.map((m) => {
      let newM = { ...m };
      newM.enabled = false;
      // Prio 1 modifiers have no price - customer request
      if (m.priority === 1) {
        newM.unitPrice = {
          grossAmount: 0,
          netAmount: 0,
          vatAmount: 0,
          currency: "HUF",
          taxRate: 0,
        };
      }
      return newM;
    });
    if (cg?.modifiers) {
      cg?.modifiers?.forEach((cgm) => {
        cm = cm.filter((m) => m.id !== cgm.id);
        cm.push(cgm);
      });
    }
    return cm;
  };
  const [currentModifiers, setCurrentModifiers] = useState(
    getCurrentModifiers(availableModifiers, currentProduct)
  );

  useEffect(() => {
    setCurrentGroupHeader(getCurrentHeader(currentProduct));
    setCurrentModifiers(
      getCurrentModifiers(availableModifiers, currentProduct)
    );
  }, [availableModifiers, currentProduct]);

  const handleSave = async (values) => {
    let body = {
      ...currentProduct,
      id: currentProduct?.id,
      name: values.name,
      isActive: currentProductHeader?.isActive,
      modifiers: null,
    };
    delete body.selected;
    await putProduct({ body: body });
    refresh();
  };

  const handleDelete = async () => {
    await deleteProduct({ id: currentProduct?.id });
    setConfirmOpen(false);
    refresh();
  };

  const open = () => {
    setConfirmOpen(true);
  };

  const modiGroupComparator = (a, b) => {
    return a.priority < b.priority ||
      (a.priority === b.priority && a.name < b.name)
      ? -1
      : a.priority > b.priority ||
        (a.priority === b.priority && a.name >= b.name)
      ? 1
      : 0;
  };
  const separatePrios = (modifications) => {
    let prioSorted = modifications.sort(modiGroupComparator); // one array, sorted by prio
    let curPrio = prioSorted[0].priority;
    let separatedPrios = [];
    let curIdx = 0;
    prioSorted.forEach((curModi) => {
      if (curModi.priority === curPrio) {
        if (separatedPrios[curIdx] == null) {
          separatedPrios[curIdx] = [];
        }
        separatedPrios[curIdx].push(curModi);
      } else {
        // we have reached a modification which has a higher prio than current
        curPrio = curModi.priority;
        curIdx = curIdx + 1;
        if (separatedPrios[curIdx] == null) {
          separatedPrios[curIdx] = [];
        }
        separatedPrios[curIdx].push(curModi);
      }
    });
    return separatedPrios;
  };
  const [visibleModifications, setVisibleModifications] = useState([]);

  useEffect(() => {
    if (currentModifiers?.length > 0) {
      let prioSorted = currentModifiers.sort(modiGroupComparator); // one array, sorted by prio
      let separatedPrioModis = separatePrios(prioSorted);
      let joinedArrays = [];

      separatedPrioModis.forEach((arr) => {
        arr.forEach((elem) => {
          joinedArrays.push(elem);
        });
      });
      setVisibleModifications(joinedArrays);
    } else {
      setVisibleModifications([]);
    }
  }, [currentModifiers]);

  useEffect(() => {
    if (visibleModifications.length > 0) {
      let newModiGroups = {};
      visibleModifications.forEach((modification) => {
        // visibleModifications is an array sorted by category name
        if (!newModiGroups[modification.category]) {
          newModiGroups[modification.category] = [];
        }
        newModiGroups[modification.category].push(modification);
      });
      let nameSortedGroups = {};
      Object.keys(newModiGroups).forEach((key) => {
        nameSortedGroups[key] = newModiGroups[key].sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else {
            return -1;
          }
        });
      });
    }
  }, [visibleModifications]);

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Paper align="center" component={Paper} className={classes.root}>
      <Dialog
        open={confirmOpen}
        onClose={(e) => {
          setConfirmOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className={classes.customDialogTitle}
          id="alert-dialog-title"
        >
          <Typography
            style={{
              fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
            }}
          >
            Biztosan törölni szeretnéd?
          </Typography>
        </DialogTitle>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.customButton}
            onClick={handleDelete}
            color="primary"
          >
            Törlés
          </Button>
          <Button
            className={classes.customButton}
            onClick={() => {
              setConfirmOpen(false);
            }}
            color="primary"
          >
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
      <LocalizationProvider locale={huLocale} dateAdapter={AdapterDateFns}>
        <Title
          className={classes.title}
          style={{
            fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
          }}
        >
          Fénykép módosítása
        </Title>

        <Formik
          initialValues={{
            ...currentProduct,
            name: currentProduct?.name,
            category: currentProduct?.category,
            belongsToId: currentProduct?.belongsToId,
            accessKey: currentProduct?.accessKey || "",
            isActive: currentProduct?.isActive,
            inheritModifiers: currentProduct?.inheritModifiers,
            validUntil: currentProduct?.validUntil,
          }}
          style={{ width: "100%" }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSave(values);
            resetForm();
            toggle();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
          }) => (
            <Form className={classes.form}>
              <Grid container alignContent="center" className={classes.grid}>
                <Grid item xs={12}>
                  <div className={classes.imageContainer}>
                    <img
                      className={classes.image}
                      src={productImage}
                      alt={values.name}
                    />
                  </div>
                  <Button
                    className={classes.deleteBtn}
                    variant="contained"
                    onClick={open}
                  >
                    <DeleteIcon style={{ fontSize: "2rem" }}></DeleteIcon>
                  </Button>
                  <FormControlLabel
                    style={{ width: "80%" }}
                    className={classes.formlabel}
                    control={
                      <TextField
                        variant="outlined"
                        required
                        id="name"
                        name="name"
                        autoFocus
                        value={values.name}
                        onChange={handleChange}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        className={classes.productName}
                        InputProps={{
                          style: {
                            fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                          },
                        }}
                      />
                    }
                    labelPlacement="top"
                    label="Fénykép neve"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    className={classes.formlabel}
                    checked={
                      values.isActive === undefined ? true : values.isActive
                    }
                    value={
                      values.isActive === undefined ? true : values.isActive
                    }
                    control={
                      <Checkbox
                        checked={currentProductHeader.isActive}
                        value={currentProductHeader.isActive}
                        onChange={(e) => {
                          setCurrentGroupHeader({
                            ...currentProductHeader,
                            isActive: e.target.checked,
                          });
                        }}
                      />
                    }
                    labelPlacement="start"
                    label="Aktív?"
                  />
                </Grid>
              </Grid>

              <ButtonsWrapper>
                <Button
                  className={classes.cancelBtn}
                  variant="contained"
                  onClick={toggle}
                >
                  Mégse
                </Button>
                <Button
                  className={classes.confirmBtn}
                  variant="contained"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  Mentés
                </Button>
              </ButtonsWrapper>
            </Form>
          )}
        </Formik>
      </LocalizationProvider>
    </Paper>
  );
};

export default ProductDetails;
