import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  appbar: {
    height: "75px",
    position: "fixed",
    top: "0",
    maxWidth: "100%",
    backgroundColor: theme.palette.primary.main,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
  },
  toolbar: {
    height: "100%",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  menuIcon: {
    fontSize: "2rem",
  },
  listItemText: {
    fontSize: "0.9rem !important",
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
  },

  bigListItemText: {
    fontSize: "1.25rem !important",
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
  },
  title: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    margin: "0.5rem",
    flexGrow: 1,
    textAlign: "start",
    maxWidth: "150px",
    width: "100%",
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      cursor: "pointer",
      outline: "none !important",
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  smallList: {
    width: 250,
    fontSize: "1rem",
  },
  bigList: {
    display: "flex",
  },
  paper: {
    backgroundColor: theme.palette.primary.backgroundSecondary,
    color: theme.palette.primary.contrastText,
  },
  listItemIcon: {
    color: theme.palette.primary.contrastText,
    fontSize: "1.5rem",
  },
  logoutItem: {
    color: theme.palette.primary.contrastText,
    fontSize: "25px",
  },
  listItem: {
    position: "relative",
    color: theme.palette.primary.contrastText,
    whiteSpace: "nowrap",
    width: "100%",
    textTransform: "uppercase",
    textDecoration: "none",
    letterSpacing: "0.15em",
    padding: "15px 20px",

    "&:hover, &:focus": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "&:focus": {
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      outline: "none !important",
    },
  },
  activeItem: {
    position: "relative",
    color: theme.palette.primary.backgroundSecondary,
    whiteSpace: "nowrap",
    width: "100%",
    textTransform: "uppercase",
    textDecoration: "none",
    letterSpacing: "0.15em",
    padding: "15px 20px",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    outline: "none !important",
  },
  collapse: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderBottomLeftRadius: "11px",
    borderBottomRightRadius: "11px",
    background: theme.palette.primary.backgroundSecondary,
    color: theme.palette.primary.backgroundSecondary,
    position: "absolute",
    top: "100%",
    left: "0",
    right: "0",
    zIndex: "100",
    "& wrapperInner": {
      border: "5px solid red",
    },
  },
  smallMenu: {
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down("md")]: {
      display: "initial",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  bigMenu: {
    color: theme.palette.primary.contrastText,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
}));
