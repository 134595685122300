/** @format */

import { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
    Breadcrumbs,
    Button,
    Dialog,
    DialogTitle,
    Link,
    Slide,
    TextField,
    useMediaQuery,
} from "@material-ui/core";
import OrderList from "./OrderList/OrderList";
import { useStyles } from "./styles";
import { useOrderAPI } from "../hooks/useOrderAPI";
import { usePaymentAPI } from "./hooks/usePaymentAPI";
import { useInvoiceAPI } from "./hooks/useInvoiceAPI";
import { useNavigate, useParams } from "react-router-dom";
import CheckIcon from "components/common/CheckIcon/CheckIcon";
import LoadIndicator from "components/common/LoadIndicator/LoadIndicator";
import DetailsCard from "components/PhotoAdminComponents/Orders/DetailsCard/DetailsCard";
import { useDeliveryAPI } from "./hooks/useDeliveryAPI";
import OrderHistoryWidget from "components/PhotoAdminComponents/Orders/OrderHistoryWidget/OrderHistoryWidget";
import HistoryIcon from "@mui/icons-material/History";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { useRef } from "react";
import { getApi } from "api/axios";
import HistoryViewer from "components/PhotoAdminComponents/Orders/HistoryViewer/HistoryViewer";
import sizes from "constants/sizes";
import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";
import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import { Tooltip } from "@mui/material";

const OrderDetails = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { id } = useParams();
    const [downloadProgress, setDownloadProgress] = useState();

    const shopId = useSelector(
        (state) => state.nonPersistedReducers?.shopSliceReducer?.shopId
    );

    const user = useSelector(
        (state) => state.nonPersistedReducers?.userSliceReducer?.currentUser
    );

    const navigate = useNavigate();
    const [orderHistoryVisible, setOrderHistoryVisible] = useState(false);
    const toggleOrderHistoryVisible = () =>
        setOrderHistoryVisible(!orderHistoryVisible);
    const [paymentHistoryVisible, setPaymentHistoryVisible] = useState(false);
    const togglePaymentHistoryVisible = () =>
        setPaymentHistoryVisible(!paymentHistoryVisible);
    const [reprintDialogOpen, setReprintDialogOpen] = useState(false);
    const toogleReprintDialogOpen = () =>
        setReprintDialogOpen(!reprintDialogOpen);

    const desktop = useMediaQuery(`(min-width:${sizes.SM.toString()}px)`);

    const { fetchOrder, order, loading } = useOrderAPI();

    const { fetchPayment, payment } = usePaymentAPI();
    const { fetchDelivery, delivery, handleReprint, handleResend } =
        useDeliveryAPI();
    const {
        fetchInvoice,
        invoice,
        setInvoice,
        invoiceLoading,
        invoiceVendors,
    } = useInvoiceAPI();

    const getDateString = (date) => {
        return new Date(date).toLocaleDateString("hu-HU", {
            hour: "2-digit",
            minute: "2-digit",
            seconds: "2-digit",
        });
    };

    useEffect(() => {
        fetchOrder({ id: id });
    }, [fetchOrder, id]);

    const [emailDeliveryFound, setEmailDeliveryFound] = useState(null);
    const [postDeliveryFound, setPostDeliveryFound] = useState(null);
    const [printStatus, setPrintStatus] = useState("-");
    const [emailStatus, setEmailStatus] = useState("-");

    const [newEmail, setNewEmail] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const getPrintStatus = () => {
        let lastStatus = null;
        let lastPrintDelivery = null;

        order?.deliveryReferences?.forEach((deliveryReference) => {
            if (
                deliveryReference?.type === "POST" ||
                deliveryReference?.type === "LELKES_SELF_PRINT"
            ) {
                lastStatus = `#${deliveryReference?.id}:${
                    deliveryReference?.status
                } - ${getDateString(deliveryReference?.createdAt)}`;
                lastPrintDelivery = deliveryReference;
            }
        });
        if (lastStatus) {
            setPrintStatus(lastStatus);
            setPostDeliveryFound(lastPrintDelivery);
        }
    };

    const getEmailStatus = () => {
        let lastStatus = null;
        let lastEmailDelivery = null;

        order?.deliveryReferences?.forEach((deliveryReference) => {
            if (deliveryReference?.type === "EMAIL") {
                lastStatus = `#${deliveryReference?.id}:${
                    deliveryReference?.status
                } - ${getDateString(deliveryReference?.createdAt)}`;
                lastEmailDelivery = deliveryReference;
            }
        });
        if (lastStatus) {
            setEmailStatus(lastStatus);
            setEmailDeliveryFound(lastEmailDelivery);
        }
    };
    useEffect(() => {
        getPrintStatus();
        getEmailStatus();
    }, [order]);

    const resendDigital = () => {
        let newOrder = order;
        newOrder.customerContact = {
            ...newOrder.customerContact,
            email: newEmail,
        };
        handleResend({
            id: emailDeliveryFound?.deliveryId,
            body: order,
        });
        setNewEmail("");
    };

    const handleStates = (status) => {
        if (status === "PENDING") {
            return "FÜGGŐBEN";
        }
        if (status === "PAID") {
            return "FIZETVE";
        }
        if (status === "CONFIRMED") {
            return "FIZETÉSRE VÁR";
        }
        if (status === "RECEIVED") {
            return "FIZETÉSRE VÁR";
        }
        if (status === "INVOICED") {
            return "SZÁMLÁZVA";
        }
        if (status === "SHIPPING") {
            return "KISZÁLLÍTÁS ALATT";
        }
        if (status === "DONE") {
            return "TELJESÍTVE";
        }
        if (status === "REJECTED") {
            return "VISSZAUTASÍTVA";
        }
        if (status === "CANCELLED") {
            return "VISSZAUTASÍTVA";
        }
        if (status === "PRINTED") {
            return "KIÁLLÍTVA";
        }
    };

    const handleGetPdf = async (id) => {
        setDownloadProgress(true);
        try {
            const res = await (
                await getApi({
                    baseUrl: window._env_.REACT_APP_QDAK_PAYMENT_URL,
                })
            ).get(`/admin/shops/${shopId}/invoices/${id}/document`, {
                headers: {
                    "Content-Type": "application/pdf",
                },
                responseType: "blob",
            });
            setDownloadProgress(false);
            const href = window.URL.createObjectURL(
                new Blob([res.data], { type: "application/pdf" })
            );

            const a = linkRef.current;
            a.download = `rendeles_${order?.id}_szamla_.pdf`;
            a.href = href;
            a.click();
            a.href = "";
        } catch (e) {
            setDownloadProgress(false);
            console.log(e);
        }
    };
    const orderDetailsData = {
        cardTitle: "Megrendelés",
        icon: <HistoryIcon />,
        tooltipText:
            "A megrendelés státuszának változásait itt követheted nyomon",
        buttonText: desktop && "Történet megtekintése",
        handler: toggleOrderHistoryVisible,
        rows: [
            {
                title: "Rendelés azonosító",
                info: order?.id,
            },
            {
                title: "Megrendelő",
                info: order?.billingInfo?.customerName,
            },
            {
                title: "Telefonszám",
                info: order?.customerContact?.phone,
            },
            {
                title: "Email cím",
                info: order?.customerContact?.email,
            },
            {
                title: "Megrendelő címe/székhelye",
                info: `${order?.billingInfo?.address?.country}, ${order?.billingInfo?.address?.zipCode} ${order?.billingInfo?.address?.city}, ${order?.billingInfo?.address?.street}`,
            },
            {
                title: "Rendelés dátuma",
                info:
                    order?.createdAt !== undefined
                        ? new Date(order?.createdAt).toLocaleDateString(
                              "hu-HU",
                              {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  seconds: "2-digit",
                              }
                          )
                        : "No Date",
            },
            {
                title: "Rendelés lejárata",
                info: order?.deliveryTarget?.expirationDate
                    ? new Date(
                          order?.deliveryTarget?.expirationDate
                      ).toLocaleDateString("hu-HU", {
                          hour: "2-digit",
                          minute: "2-digit",
                          seconds: "2-digit",
                      })
                    : "No Date",
            },
            {
                title: "Kosár azonosító",
                info: order?.cartId,
            },
        ],
    };

    const paymentDetailsData = {
        cardTitle: "Fizetés",
        icon: <HistoryIcon />,
        tooltipText: "A fizetés státuszának változásait itt követheted nyomon",
        buttonText: desktop && "Történet megtekintése",
        buttonDisabled: !payment,
        requiredState: "PENDING",
        handler: togglePaymentHistoryVisible,
        rows: [
            {
                title: "Fizetés azonosítója",
                info: order?.paymentTrxId,
            },
            {
                title: "Fizetési mód",
                info: order?.paymentMethod
                    ? `${order?.paymentMethod}, ${order?.paymentMode || "-"}`
                    : "Fizetésre vár",
            },
            {
                title: "Külső azonosító",
                info: payment?.externalId,
            },
            {
                title: "Fizetendő összeg",
                info: `${order && order?.totalPrice?.grossAmount} HUF`,
            },
            {
                title: "Fizetés időpontja",
                info: payment
                    ? new Date(payment.createdAt).toLocaleDateString("hu-HU", {
                          hour: "2-digit",
                          minute: "2-digit",
                          seconds: "2-digit",
                      })
                    : " - ",
            },
            {
                title: "Fizetés Státusza",
                info: (
                    <span
                        style={{
                            color:
                                (payment?.status === "REJECTED" ||
                                    payment?.status === "CANCELLED") &&
                                theme.palette.primary.error,
                        }}
                    >
                        {payment?.status
                            ? handleStates(payment?.status)
                            : "Fizetésre vár"}
                    </span>
                ),
            },
        ],
    };

    const shippingDetailsData = {
        cardTitle: "Szállítás",
        icon: <ForwardToInboxIcon />,
        tooltipText: "Digitális képek újboli kézbesítése",
        buttonText: desktop && "Újraküldés",
        handler: () => setIsOpen(true),
        rows: [
            {
                title: "Szállítási mód",
                info: "Átvétel az iskolában",
            },
            {
                title: "Szállítási cím",
                info: order?.deliveryTarget?.deliveryTarget,
            },
            {
                title: "Nyomtatás",
                info: printStatus,
            },
            {
                title: "Digitális képek küldése",
                info: emailStatus,
            },
        ],
    };

    const billingDetailsData = {
        cardTitle: "Számlázás",
        requiredState: "INVOICED",
        rows: [
            {
                title: "Számlázási név",
                info: order?.billingInfo?.companyName
                    ? order?.billingInfo?.companyName
                    : order?.billingInfo?.customerName,
            },
            {
                title: "Számlázási cím",
                info: `${order?.billingInfo?.address?.country}, ${order?.billingInfo?.address?.zipCode}, ${order?.billingInfo?.address?.city}, ${order?.billingInfo?.address?.street}`,
            },
        ],
    };

    useEffect(() => {
        if (!(typeof order?.paymentTrxId === "undefined")) {
            fetchPayment({ id: order?.paymentTrxId });
        }
        if (!(typeof order?.billingTransactionIds === "undefined")) {
            setInvoice([]);
            fetchInvoice({ IDS: order?.billingTransactionIds });
            /*order?.billingTransactionIds.forEach((el) => {
        fetchInvoice({ id: el });
      })*/
        }
        if (!(typeof order?.deliveryRefs?.deliveryId === "undefined")) {
            fetchDelivery({
                id: order?.deliveryRefs?.deliveryId,
            });
        }
    }, [fetchDelivery, fetchInvoice, fetchPayment, order]);

    const routeChange = () => {
        navigate(-1);
    };

    const linkRef = useRef(null);

    return (
        <Container className="root" maxWidth={"xl"}>
            <a ref={linkRef} />
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                <Link
                    color="inherit"
                    className={classes.link}
                    onClick={routeChange}
                >
                    Vissza
                </Link>
            </Breadcrumbs>
            <Container maxWidth={"xl"}>
                <OrderHistoryWidget order={order} handler={getDateString} />
                {loading ? (
                    <LoadIndicator
                        color={theme.palette.primary.light}
                        height={800}
                    />
                ) : (
                    <>
                        <Grid container spacing={3}>
                            <Slide
                                direction="up"
                                in={true}
                                timeout={1000}
                                unmountOnExit
                            >
                                <Grid
                                    key={`order_details_card`}
                                    item
                                    xs={12}
                                    md={6}
                                    lg={6}
                                >
                                    <Paper className={classes.cardHolder}>
                                        <DetailsCard
                                            title={orderDetailsData.cardTitle}
                                            rows={orderDetailsData.rows}
                                            icon={orderDetailsData.icon}
                                            tooltipText={
                                                orderDetailsData.tooltipText
                                            }
                                            buttonText={
                                                orderDetailsData.buttonText
                                            }
                                            buttonDisabled={false}
                                            handler={orderDetailsData?.handler}
                                        />
                                        {(delivery?.status === "SHIPPED" ||
                                            order?.status === "DONE") && (
                                            <CheckIcon />
                                        )}
                                    </Paper>
                                </Grid>
                            </Slide>
                            <Slide
                                direction="up"
                                in={true}
                                timeout={1000}
                                unmountOnExit
                            >
                                <Grid
                                    key={`payment_details_card`}
                                    item
                                    xs={12}
                                    md={6}
                                    lg={6}
                                >
                                    <Paper className={classes.cardHolder}>
                                        <DetailsCard
                                            title={paymentDetailsData.cardTitle}
                                            rows={paymentDetailsData.rows}
                                            icon={paymentDetailsData.icon}
                                            tooltipText={
                                                paymentDetailsData.tooltipText
                                            }
                                            buttonText={
                                                paymentDetailsData.buttonText
                                            }
                                            buttonDisabled={
                                                paymentDetailsData.buttonDisabled
                                            }
                                            handler={
                                                paymentDetailsData?.handler
                                            }
                                        />
                                        {(payment?.status === "PAID" ||
                                            order?.status === "DONE") && (
                                            <CheckIcon />
                                        )}
                                    </Paper>
                                </Grid>
                            </Slide>
                            <Slide
                                direction="up"
                                in={true}
                                timeout={1000}
                                unmountOnExit
                            >
                                <Grid
                                    key={`shipping_details_card`}
                                    item
                                    xs={12}
                                    md={6}
                                    lg={6}
                                >
                                    <Paper className={classes.cardHolder}>
                                        <DetailsCard
                                            title={
                                                shippingDetailsData.cardTitle
                                            }
                                            rows={shippingDetailsData.rows}
                                            icon={shippingDetailsData.icon}
                                            tooltipText={
                                                shippingDetailsData.tooltipText
                                            }
                                            buttonText={
                                                shippingDetailsData.buttonText
                                            }
                                            buttonDisabled={
                                                !emailDeliveryFound ||
                                                (user?.ownedShops[0]?.id !==
                                                    shopId &&
                                                    !user?.maintainedShops
                                                        ?.find(
                                                            (shop) =>
                                                                shop.shop.id ===
                                                                shopId
                                                        )
                                                        ?.scopes?.includes(
                                                            "MANAGE_ORDERS"
                                                        ))
                                            }
                                            handler={
                                                shippingDetailsData?.handler
                                            }
                                        />
                                        {(delivery?.status === "SHIPPED" ||
                                            order?.status === "DONE") && (
                                            <CheckIcon />
                                        )}
                                    </Paper>
                                </Grid>
                            </Slide>
                            <Slide
                                direction="up"
                                in={true}
                                timeout={1000}
                                unmountOnExit
                            >
                                <Grid
                                    key={`billing_details_card`}
                                    item
                                    xs={12}
                                    md={6}
                                    lg={6}
                                >
                                    <Paper className={classes.cardHolder}>
                                        <DetailsCard
                                            title={billingDetailsData.cardTitle}
                                            rows={billingDetailsData.rows}
                                            icon={billingDetailsData.icon}
                                            tooltipText={
                                                billingDetailsData.tooltipText
                                            }
                                            buttonText={
                                                billingDetailsData.buttonText
                                            }
                                            buttonDisabled={
                                                billingDetailsData.buttonDisabled
                                            }
                                            handler={
                                                billingDetailsData?.handler
                                            }
                                            downloadProgress={downloadProgress}
                                        />
                                        {/* {(invoice?.status === "PRINTED" ||
                      order?.status === "DONE") && <CheckIcon />} */}
                                        <div className={classes.invoicesCard}>
                                            <ul
                                                className={
                                                    classes.listItemTable
                                                }
                                            >
                                                {invoiceLoading ? (
                                                    <LoadIndicator
                                                        color={
                                                            theme.palette
                                                                .primary.light
                                                        }
                                                        height={200}
                                                    />
                                                ) : invoice?.length > 0 ? (
                                                    invoice?.map((inv) => (
                                                        <li
                                                            className={
                                                                classes.listItem
                                                            }
                                                            width="100%"
                                                            key={inv.id}
                                                        >
                                                            <div
                                                                className={
                                                                    classes.hideXSCell
                                                                }
                                                                width="25%"
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    <b>
                                                                        Számla{" "}
                                                                        {
                                                                            inv?.id
                                                                        }
                                                                    </b>
                                                                </Typography>
                                                            </div>
                                                            <div
                                                                className={
                                                                    classes.showXSCell
                                                                }
                                                                width="35px"
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    <b>
                                                                        {
                                                                            inv?.id
                                                                        }
                                                                    </b>
                                                                </Typography>
                                                            </div>
                                                            <div>
                                                                {invoiceVendors && (
                                                                    <Typography
                                                                        className={
                                                                            classes.tableCell
                                                                        }
                                                                    >
                                                                        {invoiceVendors?.find(
                                                                            (
                                                                                v
                                                                            ) =>
                                                                                v.id ===
                                                                                inv.vendorId
                                                                        )
                                                                            ? invoiceVendors?.find(
                                                                                  (
                                                                                      v
                                                                                  ) =>
                                                                                      v.id ===
                                                                                      inv.vendorId
                                                                              )
                                                                                  ?.vendorType ===
                                                                              "SZAMLAZZ_HU"
                                                                                ? "Számlázz.hu"
                                                                                : "Billingo"
                                                                            : "-"}
                                                                    </Typography>
                                                                )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    classes.hideCell
                                                                }
                                                                width="20%"
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {inv?.status ===
                                                                    "PRINTED"
                                                                        ? "KIÁLLíTVA"
                                                                        : inv?.status ===
                                                                          "REJECTED"
                                                                        ? "ELUTASÍTVA"
                                                                        : inv?.status}
                                                                </Typography>
                                                            </div>
                                                            <div width="30px">
                                                                {inv?.status ===
                                                                "REJECTED" ? (
                                                                    <div
                                                                        className={
                                                                            classes.redDot
                                                                        }
                                                                    ></div>
                                                                ) : (
                                                                    <CheckIcon
                                                                        position={
                                                                            "unset"
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                            <div width="50px">
                                                                {!invoiceVendors?.find(
                                                                    (v) =>
                                                                        v.id ===
                                                                        inv.vendorId
                                                                ) ? (
                                                                    <Tooltip
                                                                        title={
                                                                            "Kapcsolódó számlakibocsátó nem található, így a letöltés nem lehetséges"
                                                                        }
                                                                    >
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                handleGetPdf(
                                                                                    inv?.id
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                true
                                                                            }
                                                                        >
                                                                            <DownloadIcon></DownloadIcon>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <IconButton
                                                                        onClick={() =>
                                                                            handleGetPdf(
                                                                                inv?.id
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            inv?.status ===
                                                                            "REJECTED"
                                                                        }
                                                                    >
                                                                        <DownloadIcon></DownloadIcon>
                                                                    </IconButton>
                                                                )}
                                                            </div>
                                                        </li>
                                                    ))
                                                ) : (
                                                    ""
                                                )}
                                            </ul>
                                        </div>
                                    </Paper>
                                </Grid>
                            </Slide>
                        </Grid>
                        {order?.items && (
                            <Slide
                                direction="up"
                                in={true}
                                timeout={1000}
                                unmountOnExit
                                style={{ transitionDelay: "1500ms" }}
                            >
                                <Paper className={classes.orderListWrapper}>
                                    <OrderList
                                        shopId={shopId}
                                        cartItems={order?.items}
                                    />
                                </Paper>
                            </Slide>
                        )}
                    </>
                )}
            </Container>
            <HistoryViewer
                data={order}
                historyVisible={orderHistoryVisible}
                toggleHistoryVisible={toggleOrderHistoryVisible}
            />
            <HistoryViewer
                data={payment}
                historyVisible={paymentHistoryVisible}
                toggleHistoryVisible={togglePaymentHistoryVisible}
            />

            <ConfirmDialog
                title={"Biztosan újra akarod nyomtatni a képeket?"}
                functionButtonText={"Igen"}
                closeButtonText={"Mégsem"}
                multipleButtons={true}
                functionButtonColor={theme.palette.primary.success}
                onClose={toogleReprintDialogOpen}
                open={reprintDialogOpen}
                onOk={() => {
                    handleReprint(postDeliveryFound?.deliveryId);
                    toogleReprintDialogOpen();
                }}
            />
            <Dialog
                open={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
            >
                <DialogTitle>Add meg az új kézbesítési címet!</DialogTitle>
                <TextField
                    className={classes.newEmail}
                    autoFocus
                    type="email"
                    label={"Új kézbesítési cím"}
                    variant="outlined"
                    onChange={(e) => {
                        setNewEmail(e.target.value);
                    }}
                />
                <Button
                    color="primary"
                    disabled={newEmail === ""}
                    className={classes.resendConfirm}
                    onClick={() => {
                        resendDigital();
                        setIsOpen(false);
                    }}
                >
                    Küldés
                </Button>
            </Dialog>
        </Container>
    );
};

export default OrderDetails;
