import * as yup from "yup";

const bankAccountNumberChangeValidationSchema = yup.object({
  bankAccountNumber: yup
    .string("Adja meg bankszámlaszámát")
    .matches(
      /^[0-9]{8}[-][0-9]{8}[-][0-9]{8}$|^[0-9]{8}[-][0-9]{8}$|^[0-9]{16}$|^[0-9]{24}$/,
      "Helyes formátum: 12345678-12345678 / 12345678-12345678-12345678"
    )
    .max(26, "Helyes formátum: 12345678-12345678 / 12345678-12345678-12345678")
    .required("Szükséges mező"),
});

export default bankAccountNumberChangeValidationSchema;
