import { Tooltip, Typography } from "@material-ui/core";
import { useState } from "react";
import { useTheme } from "@material-ui/core";
import { useUploaderBl } from "./hooks/useUploaderBl";
import * as Styles from "./styles";

const Uploader = ({ group, fetchProducts }) => {
  const {
    batchSize,
    batchSizeRef,
    submitbatchSize,
    fileInputRef,
    handleInputClick,
    handleFileUpload,
  } = useUploaderBl();

  const [isHovering, setIsHovering] = useState(false);

  const theme = useTheme();

  return (
    <Styles.Wrapper>
      <Tooltip
        title="Itt állíthatod be hány elemből álló kötegekben kerüljenek feltöltésre a képeid. Ez csupán a feltöltés sebességét befolyásolja"
        placement="right"
      >
        <Styles.BatchSettingsWrapper>
          <Typography variant="p" color="primary">
            Köteg méret:
          </Typography>
          <Styles.BatchCountTextField
            size="small"
            inputRef={batchSizeRef}
            defaultValue={batchSize}
            color="primary"
            variant="outlined"
            type="number"
            InputProps={{
              inputProps: { min: 5, max: 50 },
              style: {
                fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
              },
            }}
          />
          <Styles.BatchButton
            size="small"
            onClick={submitbatchSize}
            variant="outlined"
            color="primary"
          >
            OK
          </Styles.BatchButton>
        </Styles.BatchSettingsWrapper>
      </Tooltip>
      <Styles.UploadButton
        onClick={handleInputClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Styles.UploadIcon hover={isHovering} />
        <Styles.UploadText hover={isHovering}>Feltöltés</Styles.UploadText>
      </Styles.UploadButton>
      <input
        ref={fileInputRef}
        hidden
        type="file"
        accept=".jpg, .jpeg"
        onChange={(e) => {
          handleFileUpload(e, group?.id, group?.modifiers, fetchProducts);
        }}
        multiple
      />
    </Styles.Wrapper>
  );
};

export default Uploader;
