import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  listItemText: {
    fontSize: "1.15rem",
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontWeight: "bold",
  },
  title: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    margin: "0.5rem",
    flexGrow: 1,
    textAlign: "start",
    maxWidth: "150px",
    width: "100%",
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      cursor: "pointer",
      outline: "none !important",
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  listWrapper: {
    width: 250,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
  },
  titleIcon: {
    color: "whitsesmoke",
  },
  paper: {
    backgroundColor: theme.palette.primary.backgroundPrimary,
    color: theme.palette.primary.contrastText,
  },
  listItemIcon: {
    color: theme.palette.primary.contrastText,
    fontSize: "1.5rem",
  },
  smallMenu: {
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down("md")]: {
      display: "initial",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
