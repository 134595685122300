import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import { useStyles } from "./styles";
import SettingsCard from "components/PhotoAdminComponents/Settings/Card/SettingsCard";
import ActionButton from "components/common/ActionButton/ActionButton";
import { Form, Formik } from "formik";
import { useRef } from "react";
import { CircularProgress } from "@material-ui/core";

import bankAccountNumberChangeValidationSchema from "validation/UserSettings/BankAccountNumberChangeValidationSchema";

const ChangeBankAccountNumberCard = ({
  user,
  putUserBankAccountNumber,
  inProgress,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const formikRef = useRef(null);

  const [bankAccountData, setBankAccountData] = useState({
    bankAccountNumber: "",
  });

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.bankAccountNumber =
        bankAccountData?.bankAccountNumber;
    }
  }, [user, formikRef]);

  useEffect(() => {
    if (isEditing) {
      setBankAccountData({ bankAccountNumber: "" });
    } else {
      setBankAccountData({
        bankAccountNumber: user?.billingInfo?.bankAccountNumber,
      });
    }
  }, [user, isEditing]);

  return (
    <SettingsCard
      title={"Bankszámlaszám"}
      actionButton={
        <ActionButton
          icon={<Edit color="primary" />}
          clickHandler={toggleIsEditing}
        />
      }
    >
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          bankAccountNumber: "",
        }}
        validationSchema={bankAccountNumberChangeValidationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          await putUserBankAccountNumber({
            ...user,
            billingInfo: {
              ...user?.billingInfo,
              bankAccountNumber: values.bankAccountNumber,
            },
          });
          setIsEditing(false);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant="outlined"
                  fullWidth
                  required
                  label={"Bankszámlaszám"}
                  id="bankAccountNumber"
                  name="bankAccountNumber"
                  value={
                    isEditing
                      ? values.bankAccountNumber
                      : bankAccountData?.bankAccountNumber
                  }
                  onChange={handleChange}
                  error={
                    touched.bankAccountNumber &&
                    Boolean(errors.bankAccountNumber)
                  }
                  helperText={
                    touched.bankAccountNumber && errors.bankAccountNumber
                  }
                />
              </Grid>{" "}
            </Grid>
            <Grid
              item
              container
              style={{
                margin: "2rem 0 0 0",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Grid item xs={12}>
                <Button
                  disabled={!isEditing}
                  type="submit"
                  fullWidth
                  onClick={handleSubmit}
                  variant="outlined"
                  className={classes.button}
                >
                  {inProgress === "bankAccountNumber" ? (
                    <>
                      Folyamatban
                      <CircularProgress
                        style={{ marginLeft: "0.5rem" }}
                        size={24}
                      />
                    </>
                  ) : (
                    "Bankszámlaszám frissítése"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangeBankAccountNumberCard;
