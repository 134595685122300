import KeyCloak from "keycloak-js";

const _kc = new KeyCloak({
  realm: window._env_.REACT_APP_KC_REALM,
  url: window._env_.REACT_APP_KC_URL,
  "redirect-uri": window._env_.REACT_APP_KC_REDIRECT_URI,
  "ssl-required": window._env_.REACT_APP_KC_SSL_REQUIRED,
  "public-client": window._env_.REACT_APP_KC_PUBLIC_CLIENT,
  clientId: window._env_.REACT_APP_KC_CLIENT_ID,
});

const initKeyCloak = (onInitCallback, onFailCallback) => {
  _kc
    .init({
      onLoad: "check-sso",
      pkceMethod: "S256",
    })
    .then(() => {
      onInitCallback();
    })
    .catch((e) => {
      console.log(e);
      onFailCallback();
    });
};

const doLogin = () => {
  _kc.login({
    redirectUri: window._env_.REACT_APP_KC_REDIRECT_URI,
  });
};
const doLogout = () => {
  localStorage.clear();
  _kc.logout({
    redirectUri: window._env_.REACT_APP_KC_REDIRECT_URI,
  });
};
const getToken = () => _kc.token;

const updateToken = (successCb) => _kc.updateToken(5).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getFirstName = () => _kc.tokenParsed?.given_name;
const isLoggedIn = () => !!_kc.token;
const hasRole = (role) => _kc.tokenParsed.realm_access.roles.includes(role);

const authHeader = () => {
  const token = getToken();
  return token ? `Bearer ${token}` : null;
};

const AuthService = {
  initKeyCloak,
  isLoggedIn,
  authHeader,
  doLogin,
  doLogout,
  getToken,
  updateToken,
  getUsername,
  getFirstName,
  hasRole,
};

export default AuthService;
