import * as yup from "yup";

const companyNameChangeSchema = yup.object({
  companyName: yup
    .string("Adja meg számlázási nevét")
    .matches(
      /^([A-ZÁÉÚŐÓÜÖÍ]([A-ZÁÉÚŐÓÜÖÍa-záéúőóüöí -.]+\s?))$/,
      "A névnek nagybetűvel kell kezdődnie és számokat nem tartalmazhat"
    )
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
});

export default companyNameChangeSchema;
