import { getApi } from 'api/axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export const useModifierApi = () => {
  const [modifiers, setModifiers] = useState([]);
  const [modifiersLoading, setModifiersLoading] = useState(false);
  const [modifiersError, setModifiersError] = useState(null);

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const getModifiers = async () => {
    try {
      setModifiersLoading(true);

      const res = await (await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })).get(`/admin/shops/${shopId}/modifiers`);
      setModifiers(res.data);
      setModifiersLoading(false);
    } catch (error) {
      setModifiersLoading(false);
      setModifiersError(error);
    }
  };

  return {
    modifiers,
    modifiersLoading,
    modifiersError,
    getModifiers,
  };
};
