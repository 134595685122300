import styled from "styled-components";
import { forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SIZES from "constants/sizes";
import Slide from "@material-ui/core/Slide";

export const Title = styled.h1`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
`;

export const Title2 = styled.h1`
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;

  margin: 1rem 1rem 0.5rem 1rem;
  text-transform: none;
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
  display: block;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
`;

export const DialogContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media (min-width: ${SIZES.MD}px) {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    max-width: 1000px;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const useStyles = makeStyles((theme) => {
  return {};
});
