import React from "react";
import { useTheme } from "@material-ui/core";
import { useState } from "react";

import { SelectWrapper } from "./styles";
import { IconButton } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const StatusEditor = ({ option, setOption }) => {
  const theme = useTheme();
  
  const [options, setOptions] = useState(
    option.value !== "" ? option.value.split("=")[1].split(",") : []
  );
  const addOption = (value) => {
    setOptions([...options, value]);
    setOption({
      ...option,
      value: `${option.name}=${[...options, value].join(",")}`,
    });
  };

  const removeOption = (value) => {
    setOptions(options.filter((o)=> o !== value));
    setOption({
        ...option,
        value: `${option.name}=${options.filter((o)=> o !== value).join(",")}`,
      });
   }
  return (
    <SelectWrapper>
      {option.values?.map((value, idx) => (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          {options?.find((o) => o === value) ? (
            <p style={{fontWeight: 'bolder', color: theme.palette.primary.main}}> {option.displayedValues[idx]}</p>
          ) : (
            <p style={{color: 'lightgrey'}}>{option.displayedValues[idx]}</p>
          )}
          {options?.find((o) => o === value) ? (
            <IconButton onClick={() => removeOption(value)}>
              <Remove />
            </IconButton>
          ) : (
            <IconButton onClick={() => addOption(value)}>
              <Add />
            </IconButton>
          )}
        </div>
      ))}
    </SelectWrapper>
  );
};

export default StatusEditor;
