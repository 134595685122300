import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { useStyles } from './styles';

const HeaderLogo = ({ currentHome, icon, siteName }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return !!icon ? (
    <RouterLink variant='h5' className={classes.title} to={currentHome}>
      <img src={icon} alt='Site icon' className={classes.title} />
    </RouterLink>
  ) : (
    <Typography variant='h4' className={classes.title}>
      <RouterLink to='/' className={classes.title}>
        {siteName}
      </RouterLink>
    </Typography>
  );
};

export default HeaderLogo;
