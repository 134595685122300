import {
  Card,
  CardActionArea,
  Grid,
  Paper,
  Typography,
  Zoom,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useStyles } from "./styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React from "react";

const AddNewNotificationCard = ({ setCreate }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Zoom in={true} timeout={700}>
      <Grid item xs={12} md={6}>
        <Paper className={classes.paper}>
          <Card className={classes.card}>
            <CardActionArea
              className={classes.cardActionArea}
              onClick={() => {
                setCreate(true);
              }}
            >
              <AddCircleIcon
                style={{
                  color: theme.palette.primary.backgroundSecondary,
                  fontSize: "5rem",
                }}
              />
              <Typography className={classes.cardTitle}>
                Új üzenet hozzáadása
              </Typography>
            </CardActionArea>
          </Card>
        </Paper>
      </Grid>
    </Zoom>
  );
};

export default AddNewNotificationCard;
