import { getApi } from "api/axios";
import { errorToast, successToast } from "components/common/Toast/Toast";
import { useState } from "react";

export const useNotificationsApi = () => {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [adminNotifications, setAdminNotifications] = useState();
  const [requiredActions, setRequiredActions] = useState();

  const [activeNotifications, setActiveNotifications] = useState();
  const [inactiveNotifications, setInactiveNotifications] = useState();

  const fetchAdminNotification = async () => {
    try {
      setLoading(true);
      const { data, status } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/admin/pop-up-notifications`);

      if (status === 200) {
        setLoading(false);
        setAdminNotifications(data);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      const res1 = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/admin/pop-up-notifications`);
      const res2 = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/pop-up-notifications`);
      if (res1.status === 200 && res2.status === 200) {
        setLoading(false);
        setNotifications([...res1.data, ...res2.data]);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const fetchActiveNotifications = async () => {
    try {
      setLoading(true);
      const res1 = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/admin/pop-up-notifications/active`);
      const res2 = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/pop-up-notifications/active`);
      if (res1.status === 200 && res2.status === 200) {
        setLoading(false);
        setActiveNotifications([...res1.data, ...res2.data]);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const fetchInactiveNotifications = async () => {
    try {
      setLoading(true);
      const res1 = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/admin/pop-up-notifications/inactive`);
      const res2 = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/pop-up-notifications/inactive`);
      if (res1.status === 200 && res2.status === 200) {
        setLoading(false);
        setInactiveNotifications([...res1.data, ...res2.data]);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const postNotification = async (body) => {
    try {
      await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).post(`/admin/pop-up-notifications`, body);
      successToast("Sikeres létrehozás");
      fetchNotifications();
    } catch (e) {
      errorToast("Sikertelen létrehozás!");
      console.log(e);
    }
  };
  const patchMessage = async ({ id, message }) => {
    try {
      await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).patch(`/admin/pop-up-notifications/${id}`, message);
      fetchActiveNotifications();
      fetchInactiveNotifications();
      successToast("Sikeres módosítás");
    } catch (e) {
      errorToast("Sikertelen módosítás!");
      console.log(e);
    }
  };

  const disableNotification = async (id) => {
    try {
      await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).patch(`/admin/pop-up-notifications/${id}/disable`);
      fetchActiveNotifications();
      fetchInactiveNotifications();
      successToast("Sikeres inaktiválás");
    } catch (e) {
      errorToast("Sikertelen inaktiválás!");
      console.log(e);
    }
  };
  const enableNotification = async (id) => {
    try {
      await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).patch(`/admin/pop-up-notifications/${id}/enable`);
      fetchActiveNotifications();
      fetchInactiveNotifications();
      successToast("Sikeres aktiválás");
    } catch (e) {
      errorToast("Sikertelen aktiválás!");
      console.log(e);
    }
  };
  const deleteNotification = async (id) => {
    try {
      await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).delete(`/admin/pop-up-notifications/${id}`);
      successToast("Sikeres törlés");
      fetchNotifications();
    } catch (e) {
      errorToast("Sikertelen törlés!");
      console.log(e);
    }
  };

  const fetchRequiredActions = async () => {
    try {
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/admin/pop-up-notifications/required-actions`);
      if (status === 200) {
        setLoading(false);
        setRequiredActions(data);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return {
    loading,
    notifications,
    activeNotifications,
    inactiveNotifications,
    adminNotifications,
    fetchNotifications,
    fetchActiveNotifications,
    fetchInactiveNotifications,
    fetchAdminNotification,
    postNotification,
    disableNotification,
    enableNotification,
    deleteNotification,
    patchMessage,
    fetchRequiredActions,
    requiredActions,
  };
};
