import {Card, CardActionArea, Zoom, Typography} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react'
import { useStyles } from './styles';

const CreateInvoiceVendorCard = ({toggleCreateVendor}) => {
    const theme = useTheme();
    const classes = useStyles(theme);
  return (
    <Zoom in={true} timeout={1000}>
      <Card className={classes.cardStyle}>
        <CardActionArea
          className={classes.cardActionArea}
          onClick={() => {
            toggleCreateVendor();
          }}>
          <AddCircleIcon
            style={{
              color: theme.palette.primary.backgroundSecondary,
              fontSize: '5rem'
            }}
          />
          <Typography className={classes.cardTitle}>
            Számlakibocsátó hozzáadása
          </Typography>
        </CardActionArea>
      </Card>
    </Zoom>
  )
}

export default CreateInvoiceVendorCard