import { createSlice } from "@reduxjs/toolkit";

export const scopeSlice = createSlice({
  name: "currentScopes",
  initialState: {
    scopes: [],
  },
  reducers: {
    setScopes: (state, action) => {
      state.shopId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setScopes } = scopeSlice.actions;

export default scopeSlice.reducer;
