import { useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const Title = (props) => {
  const theme = useTheme();
  return (
    <Typography
      component="h2"
      variant="h6"
      color="primary"
      gutterBottom
      style={{ fontFamily: `${theme.typography.main}, Sans-serif, Arial` }}
    >
      {props.children}
    </Typography>
  );
};

export default Title;
