import { useUploaderBl } from "components/PhotoAdminComponents/Group/Uploader/hooks/useUploaderBl";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import * as Styles from "./styles";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";

const ResultItem = ({ name, success, promiseConfig }) => {
  const { handleRetry } = useUploaderBl();
  const uploadLoading = useSelector(
    (state) => state.nonPersistedReducers.uploadSliceReducer.loading
  );

  return (
    <Styles.Wrapper>
      <Styles.Name variant="h3" color="primary">
        {name}
      </Styles.Name>
      {success === "loading" ? (
        <CircularProgress size={"1.25rem"} color="primary" />
      ) : (
        <>
          {success === "true" ? (
            <CheckCircleIcon
              style={{ height: "1.25rem", width: "auto" }}
              color="success"
            />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Button
                outlined
                disabled={uploadLoading}
                onClick={() => handleRetry({ promiseConfig: promiseConfig })}
              >
                Újra
              </Button>
              <CancelIcon
                style={{ height: "1.25rem", width: "auto" }}
                color="error"
              />
            </div>
          )}
        </>
      )}
    </Styles.Wrapper>
  );
};

export default ResultItem;
