import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    width: "100%",
    fontWeight: 600,
  },
  text: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  heading: { color: theme.palette.primary.textPrimary },
  hide: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hide1: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  tableRow: {
    "&:hover": {
      background: "rgb(230,230,230)",
      cursor: "pointer",
    },
  },
  pendig: {
    color: "gray",
    fontWeight: "bold",
  },
  paid: {
    color: "rgb(25,109,235)",
    fontWeight: "bold",
  },
  verified: {
    color: "rgb(0, 204, 204)",
    fontWeight: "bold",
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: "2rem",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  breadcrumb: {
    margin: "1rem",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    outline: "none !important",
    color: theme.palette.primary.backgroundSecondary,
  },
  invoiced: {
    color: "rgb(0, 255, 0)",
    fontWeight: "bold",
  },
  shipping: {
    color: "rgb(204, 204, 0 )",
    fontWeight: "bold",
  },
  done: {
    color: "rgb(0, 153, 0)",
    fontWeight: "bold",
  },
  rejected: {
    color: "rgb(204, 0, 0 )",
    fontWeight: "bold",
  },
}));
