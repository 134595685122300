import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  useTheme,
} from "@material-ui/core";
import React from "react";

import { useStyles } from "./styles";

const DeleteDialog = ({
  confirmOpen,
  deleteModalRef,
  setConfirmOpen,
  handleDelete,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Dialog
      open={confirmOpen}
      ref={deleteModalRef}
      onClose={(e) => {
        setConfirmOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          style={{
            fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
          }}
        >
          Biztosan törölni szeretnéd?
        </Typography>
      </DialogTitle>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.customButton}
          onClick={handleDelete}
          color="primary"
        >
          Törlés
        </Button>
        <Button
          className={classes.customButton}
          onClick={() => {
            setConfirmOpen(false);
          }}
          color="primary"
        >
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
