import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Title = styled.p`
  font-weight: 600;
`;
export const Detail = styled.p`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
`;
export const OrderDetail = styled.div`
  margin: 0 2rem 2rem 2rem;
  position: relative;
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    minheight: "1000px",
    flexDirection: "column",
  },
  title: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontWeight: "600",
    padding: "1rem",
  },
  titlecontainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "3px solid",
    borderBottomColor: theme.palette.primary.main,
    justifyContent: "space-between",
  },
  detailbutton: {
    height: "70%",
    marginRight: "1rem",
    fontSize: "11px",
    fontWeight: 600,
  },

  link: {
    cursor: "pointer",
  },
}));
