import {
  Button,
  CircularProgress,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Row, Title, Detail, OrderDetail, useStyles } from "./styles";

const DetailsCard = ({
  title,
  handler,
  icon,
  buttonText,
  buttonDisabled,
  rows,
  button2Text,
  handler2,
  icon2,
  tooltipText,
  tooltipText2,
  downloadProgress,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <div className={classes.titlecontainer}>
        <Typography className={classes.title}>{title} részletei</Typography>{" "}
        {!!icon2 && (
          <Tooltip title={tooltipText2}>
            <div>
              <Button
                children={icon2}
                className={classes.detailbutton}
                type="submit"
                disabled={buttonDisabled}
                variant="outlined"
                onClick={() => {
                  handler2();
                }}
              >
                {button2Text}
                {icon2}
              </Button>
            </div>
          </Tooltip>
        )}
        {!!icon && (
          <Tooltip title={tooltipText}>
            <div>
              <Button
                children={icon}
                className={classes.detailbutton}
                type="submit"
                disabled={buttonDisabled || downloadProgress}
                variant="outlined"
                onClick={() => {
                  handler();
                }}
              >
                {buttonText}
                {downloadProgress ? <CircularProgress size={24} /> : icon}
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
      <OrderDetail>
        {rows.map((row, idx) => (
          row?.title !== "Rendelés lejárata" ?
          (<Row key={idx}>
            <Title>{row?.title}:</Title>
            <Detail>{row?.info}</Detail>
          </Row>) : ""
        ))}
      </OrderDetail>
    </>
  );
};

export default DetailsCard;
