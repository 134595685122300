import { makeStyles } from "@material-ui/core/styles";
import { forwardRef } from "react";
import Slide from "@material-ui/core/Slide";
import styled from "styled-components";
import sizes from "constants/sizes";

export const Transition = forwardRef((props, ref) => {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: "0",
    margin: "0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  media: {
    width: "100%",
    height: "200px",
    margin: "0 auto",
  },
  editbutton: {
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    borderRadius: "4px",
    backgroundColor: "#c8c8c8",
    color: "white",
    fontSize: "20px",
    position: "absolute",
    top: "30%",
    left: "50%",
    opacity: "0.5",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      transition: "all 300ms",
      opacity: "1",
    },
    [theme.breakpoints.up("md")]: {
      top: "40%",
    },
  },
  deletebutton: {
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    backgroundColor: "#c8c8c8",
    opacity: "0.5",
    borderRadius: "4px",
    color: "white",
    fontSize: "20px",
    position: "absolute",
    bottom: "0%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    "&:hover": {
      color: "red",
      transition: "all 300ms",
      backgroundColor: theme.palette.primary.main,
      opacity: "1",
    },
    [theme.breakpoints.up("md")]: {
      bottom: "30%",
    },
  },
  editGroupButton: {
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    borderRadius: "4px",
    fontSize: "20px",
    backgroundColor: theme.palette.primary.backgroundPrimary,
    color: theme.palette.primary.textPrimary,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.backgroundSecondary,
    },
    marginBottom: "2rem",
  },
  confirmBtn: {
    width: "100%",
    height: "auto",
    padding: "1rem",
    margin: "0 2rem",
  },
  cancelBtn: {
    width: "100%",
    height: "auto",
    padding: "1rem",
    margin: "0 2rem",
  },
  tableBorder: {
    borderTop: "2px solid black",
  },
  customCell: {
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: ".8rem",
    fontWeight: "bold",
    letterSpacing: ".1rem",
    lineHeight: "1.54",
  },
  cardStyle: {
    fontSize: "1rem",
    position: "relative",
    width: "100%",
    transition: "all 1s",
    boxShadow:
      "0 4px 15px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    zIndex: "1",
    flex: "0 0 calc(100% - 20px)",

    margin: "20px 10px",
    [theme.breakpoints.up("sm")]: {
      flex: "0 0 calc(50% - 20px)",
    },
    [theme.breakpoints.up("md")]: {
      flex: "0 0 calc(33% - 20px)",
    },
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 calc(25% - 20px)",
    },
    [theme.breakpoints.up("xl")]: {
      flex: "0 0 calc(16.66% - 20px)",
    },
  },
  customPrices: {
    textTransform: "uppercase",
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    fontSize: "11px",
    fontWeight: "400",
    letterSpacing: ".1rem",
    lineHeight: "1.54",
  },
  editicon: {
    marginRight: "1rem",
  },
  group: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    width: "90%",
    margin: "0 auto",
  },

  hiddenGroup: {
    display: "none",
  },
  table: {
    marginTop: "2rem",
  },
  price: {
    width: "5rem",
    marginRight: "1rem",
  },
  breadcrumbWrapper: {
    padding: "1rem 0 ",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    outline: "none !important",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  breadcrumb: {
    marginLeft: "1rem",
    color: theme.palette.primary.backgroundSecondary,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },

  rootLink: {
    cursor: "pointer",
    color: theme.palette.primary.backgroundSecondary,
  },

  stickyBreadcrumb: {
    marginLeft: "1rem",
    color: "whitesmoke",
  },
  newGroupBtn: {
    width: "15rem",
    padding: "1rem",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    margin: "1rem auto",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.backgroundSecondary,
    },
  },
  uploadWrapper: {
    paddingTop: "2rem",
    width: "100%",
    border: "2px dashed black",
    borderRadius: "4px",
    fontSize: "3rem",
    display: "grid",
    placeItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
    "& svg": {
      fontSize: "3rem",
    },
  },
  hiddenUploadWrapper: {
    display: "none",
  },
  switchRoot: {
    width: "64px",
    padding: "10px 12px",
  },
  switchBase: {
    top: "50%",
    padding: "4px",
    /* To center element
       marginTop: calc(-elemntHeight / 2 - padding) */
    marginTop: "calc(-26px / 2 - 4px)",
    backgroundColor: "#212121",
    "&:hover": {
      backgroundColor: "#212121 !important",
    },
  },
  switchBaseChecked: {
    transform: "translateX(30px) !important",
  },

  pagination: {
    "& .MuiTypography-root, & .MuiInputBase-root": {
      fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    },
  },
  switchTrack: {
    borderRadius: "20px",
  },
  selectedDelete: {
    // alignSelf: "flex-end",
    // marginRight: "5rem",
    backgroundColor: theme.palette.primary.backgroundPrimary,
    color: theme.palette.primary.textPrimary,
    fontSize: "1rem",
    marginTop: "1rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.backgroundSecondary,
    },
  },
  deleteIcon: {
    color: theme.palette.primary.error,
    marginRight: "1rem",
  },
  deactivate: {
    color: theme.palette.primary.textPrimary,
    marginRight: "1rem",
  },
  ordersBtn: {
    margin: "0 0.5rem",
  },
}));

export const BreadcrumbWrapper = styled.div`
  height: 56px;
  width: 100%;
`;

export const BackgroundContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: black;
`;
export const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.22);
`;

export const IntroSection = styled.div`
      width: 100%;
      margin: 0;
      display: inline-block;
      flex-direction: column;
  
      @media (min-width: 768px) {
          flex-direction: row;
          align-items: center;
          justify-content: center;
  `;
export const BackgroundImage = styled.img`
  border-radius: 4px;
  margin: 5px;
  width: auto;
  max-width: 100%;
  z-index: 100;
  height: 40vh;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px solid transparent;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 0 20px rgba(0 0 0 0.12);
  text-align: center;
`;
export const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin: 2rem;
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
`;

export const MultipleControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UpdateIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  @media (min-width: ${sizes.MD}px) {
    position: absolute;
    margin-bottom: 0;
    bottom: 0.5rem;
    right: 0.5rem;
  }
`;

export const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
  @media (min-width: 1200px) {
    width: 90%;
    align-self: center;
  }
  width: 100%;
`;

export const GroupsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

export const BugWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  width: 96%;
  @media (min-width: 1000px) {
    width: 100%;
  }
`;
export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500px;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export const AddGroup = styled.div``;
