import { useEffect } from "react";
import { useSelector } from "react-redux";
// import { Redirect, Route, Switch } from "react-router-dom";
import {
  CssBaseline,
  useTheme,
  Container,
  Typography,
} from "@material-ui/core";

import { useStyles, ShopsWrapper, ShopCreator } from "./styles";
import ShopItem from "./ShopItem/ShopItem";
import LoadIndicator from "components/common/LoadIndicator/LoadIndicator";
import AddShopCard from "components/PhotoAdminComponents/SelectShop/AddShopCard/AddShopCard";

import { useShopAPI } from "pages/PhotoAdmin/SelectShop/hooks/useShopApi";

const SelectShop = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const {
    getShops,
    shopsLoading,
    createShop,
    shopLoading,
  } = useShopAPI();

  const user = useSelector(
    (state) => state.nonPersistedReducers.userSliceReducer.currentUser
  );

  useEffect(() => {
    getShops();
  }, []);

  useEffect(() => {}, []);

  return (
    <div className={classes.root}>
     
      <CssBaseline />
      <Container maxWidth={"xl"} className={classes.container}>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />

          {shopsLoading ? (
            <LoadIndicator color={theme.palette.primary.light} height={300} />
          ) : (
            <>
              <ShopsWrapper>
                <ShopCreator>
                  {!user?.ownedShops[0] && (
                    <div style={{ height: "100%" }}>
                      <Typography>
                        Még nincs saját boltja? Hozzon létre egyet!
                      </Typography>
                      <AddShopCard
                        user={user}
                        createShop={createShop}
                        shopLoading={shopLoading}
                      />
                    </div>
                  )}
                </ShopCreator>
              </ShopsWrapper>
              <Typography
                className={classes.shopsTitle}
                style={{ textAlign: "center" }}
              >
                Az Ön boltja
              </Typography>
              <ShopsWrapper>
                {user?.ownedShops?.map((shop) => {
                  return (
                    <ShopItem
                      key={`shop_${shop.id}`}
                      id={shop.id}
                      name={shop.name}
                    />
                  );
                })}
              </ShopsWrapper>
              <Typography
                className={classes.shopsTitle}
                style={{ textAlign: "center" }}
              >
                Az Ön által kezelt boltok
              </Typography>
              <ShopsWrapper>
                {user?.maintainedShops?.map((shop) => {
                  return (
                    <ShopItem
                      key={`shop_${shop.id}`}
                      id={shop?.shop?.id}
                      name={shop?.shop?.name}
                    />
                  );
                })}
              </ShopsWrapper>
            </>
          )}
        </main>
      </Container>
    </div>
  );
};

export default SelectShop;
