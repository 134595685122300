import { Button, Grow, Typography } from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ResultItem from "./ResultItem/ResultItem";
import * as Styles from "./styles";
import { useEffect } from "react";
import { useUploaderBl } from "../hooks/useUploaderBl";

const Results = () => {
  const batches = useSelector(
    (state) => state.nonPersistedReducers.uploadSliceReducer.batches
  );
  const fileCount = useSelector(
    (state) => state.nonPersistedReducers.uploadSliceReducer.fileCount
  );
  const completedUploads = useSelector(
    (state) => state.nonPersistedReducers.uploadSliceReducer.completedUploads
  );
  const errorCount = useSelector(
    (state) => state.nonPersistedReducers.uploadSliceReducer.errorCount
  );
  const uploadLoading = useSelector(
    (state) => state.nonPersistedReducers.uploadSliceReducer.loading
  );

  const { handleRetryAll } = useUploaderBl();

  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const hasNext = () => page < batches.length - 1;
  const hasPrev = () => page > 0;

  const nextPage = () => {
    if (hasNext()) {
      setPage(page + 1);
    }
  };
  const previousPage = () => {
    if (hasPrev()) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (completedUploads === 0) {
      setPage(0);
    }
  }, [completedUploads]);
  useEffect(() => {
    if (batches.length > 0) {
      setIsOpen(true);
    }
  }, [batches]);

  return (
    <Grow in={isOpen} timeout={300}>
      <Styles.Wrapper>
        <Styles.LayoutWrapper>
          <Styles.Button
            onClick={previousPage}
            color={hasPrev() ? "primary" : "secondary"}
          >
            <ArrowLeftIcon />
          </Styles.Button>
          <Styles.ResultList>
            <Styles.Counter>
              <Typography color="textPrimary" variant="p">
                {fileCount} / {completedUploads} kész
              </Typography>
              <Typography color="textPrimary" variant="p">
                <Button
                  disabled={errorCount === 0 || uploadLoading}
                  outlined
                  onClick={handleRetryAll}
                >
                  Hibásak újrapróbálása
                </Button>
                {errorCount} hiba
              </Typography>
            </Styles.Counter>
            {batches &&
              batches[page] &&
              batches[page].map(({ promiseConfig, success }) => (
                <ResultItem
                  key={`result_item_${promiseConfig.name}_${
                    promiseConfig.success ? "success" : "failed"
                  }`}
                  name={promiseConfig.name}
                  success={success}
                  promiseConfig={promiseConfig}
                />
              ))}
          </Styles.ResultList>
          <Styles.Button
            onClick={nextPage}
            color={hasNext() ? "primary" : "secondary"}
          >
            <ArrowRightIcon />
          </Styles.Button>
        </Styles.LayoutWrapper>
      </Styles.Wrapper>
    </Grow>
  );
};

export default Results;
