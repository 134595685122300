/** @format */

import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import sizes from "constants/sizes";

export const useStyles = makeStyles((theme) => ({
    normalWrapper: {
        width: "100%",
        margin: "0 auto",
    },
    groupWrapper: {
        width: "100%",
        margin: "0 auto",
        [theme.breakpoints.up("md")]: {
            width: "100%",
        },
    },
    printerWrapper: {
        width: "100%",
        margin: "0 auto",
        [theme.breakpoints.up("md")]: {
            width: "80%",
        },
    },
}));

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 0.5rem;

    border-radius: 4px;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;
export const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 0.5rem 2rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (min-width: ${sizes.SM}px) {
        justify-content: space-between;
        flex-direction: row;
    }
`;
export const SearchWrapper = styled.div`
    display: flex;
    align-items: center;

    flex-wrap: wrap;
    padding: 0.5rem;
    border-radius: 4px 4px 0 0;

    background-color: ${({ backgroundColor }) => backgroundColor};
`;
export const ParamsWrapper = styled.div`
    display: flex;
    align-items: center;

    flex-wrap: wrap;
    padding: 0.5rem;

    background-color: ${({ backgroundColor }) => backgroundColor};
`;
export const ActionsWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const AddWrapper = styled.div`
    margin-left: 2rem;
    position: relative;
`;
export const OptionsWrapper = styled.ul`
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    min-width: 100%;
    margin: 0;
    border: 2px solid ${({ borderColor }) => borderColor};
    border-radius: 4px;
    padding: 0.25rem;
    width: wrap-content;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const Option = styled.li`
    font-size: 1.05rem;
    text-align: center;
    font-weight: 400;
    color: ${({ color }) => color};
    list-style-type: none;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ color }) => color};
    font-family: ${({ fontFamily }) => fontFamily};

    &:hover {
        cursor: pointer;
        color: ${({ backgroundColor }) => backgroundColor};
        background-color: ${({ color }) => color};
    }
`;
