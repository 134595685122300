import * as yup from "yup";

const vatNumberChangeValidationSchema = yup.object({
  vatNumber: yup
    .string("Adja meg adószámát")
    .matches(/^[0-9]{8}[-][0-9][-][0-9]{2}$/, "Helyes formátum: 12345678-3-21")
    .required("Szükséges mező"),
});

export default vatNumberChangeValidationSchema;
