import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import groupSliceReducer from "redux/slices/GroupSlice";
import headerSliceReducer from "redux/slices/HeaderSlice";
import uploadQueueSliceReducer from "redux/slices/UploadQueueSlice";
import shopSliceReducer from "redux/slices/ShopSlice";
import uploadSliceReducer from "redux/slices/UploadSlice";
import userSliceReducer from "redux/slices/UserSlice";
import scopeSliceReducer from "redux/slices/ScopeSlice";
import formulaSliceReducer from "redux/slices/FormulaSlice";
import alertSliceReducer from "redux/slices/AlertSlice"

const nonPersistedReducers = combineReducers({
  groupSliceReducer,
  headerSliceReducer,
  shopSliceReducer,
  uploadQueueSliceReducer,
  uploadSliceReducer,
  userSliceReducer,
  scopeSliceReducer,
  formulaSliceReducer,
  alertSliceReducer
});

const combinedReducers = combineReducers({ nonPersistedReducers });

const store = configureStore({
  reducer: combinedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
