import { Grid, TextField } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { usePostalCodeFields } from "./hooks/usePostalCodeFields";

const PostalCodeFields = ({
  data,
  setData,
  disabled,
  handleChange,
  zipHelper,
  zipError,
  cityHelper,
  cityError,
}) => {
  const { calcAddress, calcPostalCode } =
    usePostalCodeFields();

  useEffect(() => {
    setData({ ...data, zipCode: data?.zipCode });
    if (data?.zipCode?.length > 0) {
      let suggestion = calcAddress(data?.zipCode, data?.city);
      if (suggestion === data?.zipCode) return;
      if (typeof suggestion === "undefined") {
        setData({ ...data, city: "" });
        return;
      } else {
        setData({ ...data, city: suggestion });
      }
    }
  }, [data?.zipCode]);

  useEffect(() => {
    setData({ ...data, city: data?.city });
    if (data?.city?.length > 0) {
      let suggestion = calcPostalCode(data?.city, data?.zipCode);
      if (suggestion === data?.city) return;
      if (typeof suggestion === "undefined") {
        setData({ ...data, zipCode: "" });
        return;
      } else {
        setData({ ...data, zipCode: suggestion });
      }
    }
  }, [data?.city]);

  return (
    <>
      <Grid container spacing={2} style={{ marginLeft: "0rem" }}>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            fullWidth
            id="zip"
            name="zip"
            required
            disabled={disabled}
            label={"Irányítószám"}
            margin="normal"
            placeholder="0000"
            value={data?.zipCode}
            key="postal_code_field"
            onChange={(e) => {
              // handleChange(e);
              setData({ ...data, zipCode: e.target.value });
            }}
            error={zipHelper && Boolean(zipError)}
            helperText={zipHelper && zipError}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            id="city"
            required
            disabled={disabled}
            label={"Település"}
            margin="normal"
            name="city"
            value={data?.city}
            fullWidth
            key="address_field"
            onChange={(e) => {
              // handleChange(e);
              setData({ ...data, city: e.target.value });
            }}
            error={cityHelper && Boolean(cityError)}
            helperText={cityHelper && cityError}
          />
        </Grid>
        {/* <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={calcAddressSuggestions(data?.city)}
          inputValue={data?.city}
          onChange={(e) =>
            setData({
              ...data,
              city: e.target.innerHTML.includes("<")
                ? ""
                : e.target.innerHTML,
            })
          }
          renderInput={(params) => (
            <TextField
              id="city"
              required
              name="city"
              value={data?.city}
              className={classes.input}
              {...params}
              key="address_field"
              onChange={(e) => {
                handleChange(e);
                setData({ ...data, city: e.target.value });
              }}
              error={cityHelper && Boolean(cityError)}
              helperText={cityHelper && cityError}
            />
          )}
        /> */}
      </Grid>
    </>
  );
};

export default PostalCodeFields;
