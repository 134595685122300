import { useTheme } from "@material-ui/core/styles";
import { SearchField, useStyles } from "./styles";
import { useEffect } from "react";
import {
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { useState } from "react";

const UserSearch = ({
  shop,
  putShop,
  getAllUsers,
  handleRefresh,
  allUsers,
  setShopLoaded,
  maintainers,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [selectedUser, setSelectedUser] = useState();

  const [loading, setLoading] = useState();

  const shopId = useSelector(
    (state) => state.nonPersistedReducers?.shopSliceReducer?.shopId
  );

  const user = useSelector(
    (state) => state.nonPersistedReducers.userSliceReducer.currentUser
  );

  const handleAdd = async () => {
    setLoading(true);
    await putShop({
      body: {
        ...shop,
        maintainers: [
          ...shop.maintainers,
          { scopes: [], user: { ...selectedUser } },
        ],
      },
    });
    setSelectedUser(null);
    setLoading(false);
    handleRefresh();
  };

  useEffect(() => {
    getAllUsers({ maintainers: maintainers, shopOwner: shop?.owner });
  }, [maintainers]);
  return (
    <>
      <Typography className={classes.searchTitle}>
        Új boltkezelő hozzáadása
      </Typography>
      <SearchField>
        {allUsers && (
          <Autocomplete
            // disabled={!groups || groups?.length === 0}
            disablePortal
            disableClearable
            id="user-autocomplete"
            options={allUsers && allUsers}
            getOptionLabel={(option) => option?.username || ""}
            sx={{ width: 300, fontFamily: theme.typography.main }}
            value={selectedUser || ""}
            className={classes.autocomplete}
            onChange={(event, selectedUser) => {
              setSelectedUser(selectedUser);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { fontFamily: `${theme.typography.main}` },
                }}
                label="Felhasználók keresése"
              />
            )}
          />
        )}
        {user?.ownedShops[0]?.id !== shopId &&
        !user?.maintainedShops
          ?.find((shop) => shop.shop.id === shopId)
          ?.scopes?.includes("MANAGE_SHOP") ? (
          <Tooltip title="Csak a megfelelő BOLT_KEZELÉSE jogosultság birtokában adhat hozzá felhasználót a bolt kezelőihez!">
            <Button className={classes.disabledAddBtn}>
              <AddIcon />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Felhasználó hozzáadása a bolt kezelőihez">
            <Button
              className={classes.addBtn}
              disabled={!selectedUser || loading}
              onClick={handleAdd}
            >
              {loading ? (
                <CircularProgress style={{ color: "whitesmoke" }} />
              ) : (
                <AddIcon />
              )}
            </Button>
          </Tooltip>
        )}
      </SearchField>
    </>
  );
};

export default UserSearch;
