import React from "react";
import queries from "../../../api/queries/BlogsQueries";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToasterLogic } from "../../../utils/useToasterLogic";

export const useBlogsLogic = () => {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const queryClient = useQueryClient();
  const { successToast, errorToast } = useToasterLogic();

  const putBlog = useMutation({
    mutationFn: (blogData) => queries.putBlog({ blogsDTO: blogData }),
    onSuccess: () => {
      queryClient.invalidateQueries(["blogs"]);
      successToast("Sikeres módosítás");
    },
    onError: () => {
      errorToast("Sikertelen módosítás");
    },
  });

  const deleteBlog = useMutation({
    mutationFn: (id) => queries.deleteBlog({ blogId: id }),
    onSuccess: () => {
      queryClient.invalidateQueries(["blogs"]);
      successToast("Sikeres törlés");
    },
    onError: () => {
      errorToast("Sikertelen törlés");
    },
  });

  const fetchBlogs = useQuery({
    queryKey: ["blogs", paginationModel.page],
    queryFn: () => queries.fetchBlogs({ paginationModel }),
    onSuccess: () => {},
    retry: (failureCount, error) =>
      error.response.status === 404 ? false : true,
    refetchOnWindowFocus: false,
  });

  const postBlog = useMutation({
    mutationFn: (blogData) => queries.postBlog({ blogsDTO: blogData }),
    onSuccess: () => {
      queryClient.invalidateQueries(["blogs"]);
      successToast("Sikeres létrehozás");
    },
    onError: () => {
      errorToast("Sikertelen létrehozás");
    },
  });

  return {
    putBlog,
    deleteBlog,
    fetchBlogs,
    postBlog,
    setPaginationModel,
    paginationModel,
  };
};
