const queryOptions = [
    {
      name: "id",
      displayName: "Azonosító",
      type: "string",
      used: false,
      value: "",
    },
    {
      name: "name",
      displayName: "Iskola neve",
      type: "groupName",
      used: false,
      value: "",
    },
    {
      name: "createdAt",
      displayName: "Létrehozás dátuma",
      type: "date",
      used: false,
      value: "",
    },
    {
      name: "isActive",
      displayName: "Státusz",
      type: "select",
      values: [true, false,"ISPRINTED", "ISNOTPRINTED","ISPRINTINGDONE"],
      displayedValues: ["AKTÍV", "INAKTÍV","NYOMTATÁSRA VÁR","NYOMTATHATÓ","NYOMTATVA"],
      used: false,
      value: "",
    },
    {
      name: "sort",
      displayName: "Rendezési szempont",
      type: "key-value",
      keys: [
        { name: "id", displayName: "Azonosító" },
        { name: "name", displayName: "Galéria neve" },
      ],
      values: [
        { name: "asc", displayName: "Növekvő" },
        { name: "desc", displayName: "Csökkenő" },
      ],
      used: false,
      value: "",
    },
    {
      name: "pagination",
      displayName: "",
      type: "pagination",
      used: true,
      value: "",
    },
  ];
  
  export default queryOptions;
  