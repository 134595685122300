import { Button, Grid, Grow, Typography, useTheme } from "@material-ui/core";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { ButtonOverlay } from "./styles";
import { useState } from "react";
import { useEffect } from "react";
import { Remove } from "@material-ui/icons";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

const ScopeSelector = ({
  allScopes,
  currentId,
  currentUser,
  currentScopes,
  setCurrentScopes,
  maintainers,
  setMaintainers,
  handleTranslate,
}) => {
  const theme = useTheme();

  // const [selectSet, setSelectSet] = useState([]);
  const [hover, setHover] = useState(false);
  const [currentMaintainer, setCurrentMaintainer] = useState();

  const [currentVisibleScopes, setCurrentVisibleScopes] = useState(
    currentScopes?.filter(
      (scope) =>
        scope !== "ALLOW_PRINT_POST" &&
        scope !== "ALLOW_PRINT_DIGITAL" &&
        scope !== "MANAGE_PAYMENT_VENDORS" &&
        scope !== "VIEW_PAYMENT_VENDORS" &&
        scope !== "MANAGE_INVOICES" &&
        scope !== "MANAGE_PAYMENTS" &&
        scope !== "MANAGE_DELIVERY_VENDORS" &&
        scope !== "VIEW_DELIVERY_VENDORS"
    )
  );

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setSelectSet(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  const handleRemove = (scope) => {
    if (scope === "MANAGE_ORDERS") {
      setCurrentScopes(
        currentScopes.filter(
          (cscope) => cscope !== scope && cscope !== "ALLOW_PRINT_DIGITAL"
        )
      );
      setCurrentVisibleScopes(
        currentScopes.filter(
          (cscope) =>
            cscope !== scope &&
            cscope !== "ALLOW_PRINT_POST" &&
            cscope !== "MANAGE_PAYMENT_VENDORS" &&
            cscope !== "VIEW_PAYMENT_VENDORS" &&
            cscope !== "MANAGE_INVOICES" &&
            cscope !== "MANAGE_PAYMENTS" &&
            cscope !== "MANAGE_DELIVERY_VENDORS" &&
            cscope !== "VIEW_DELIVERY_VENDORS" &&
            cscope !== "ALLOW_PRINT_DIGITAL"
        )
      );

    } else {
      setCurrentScopes(currentScopes.filter((cscope) => cscope !== scope));
      setCurrentVisibleScopes(
        currentScopes.filter(
          (cscope) =>
            cscope !== scope &&
            cscope !== "ALLOW_PRINT_POST" &&
            cscope !== "MANAGE_PAYMENT_VENDORS" &&
            cscope !== "VIEW_PAYMENT_VENDORS" &&
            cscope !== "MANAGE_INVOICES" &&
            cscope !== "MANAGE_PAYMENTS" &&
            cscope !== "MANAGE_DELIVERY_VENDORS" &&
            cscope !== "VIEW_DELIVERY_VENDORS" &&
            cscope !== "ALLOW_PRINT_DIGITAL"
        )
      );
    }
  };
  const handleAdd = (scope) => {
    if (scope === "MANAGE_ORDERS") {
      const newScopes = [...currentScopes];
      const newVisibleScopes = [...currentVisibleScopes];
      newScopes.push(scope, "ALLOW_PRINT_DIGITAL");
      newVisibleScopes.push(scope);
      setCurrentScopes(newScopes);
      setCurrentVisibleScopes(newVisibleScopes);
    } else {
      const newScopes = [...currentScopes];
      const newVisibleScopes = [...currentVisibleScopes];
      newScopes.push(scope);
      newVisibleScopes.push(scope);
      setCurrentScopes(newScopes);
      setCurrentVisibleScopes(newVisibleScopes);
    }
  };

  useEffect(() => {
    if (currentUser) {
      setCurrentMaintainer({
        id: currentId,
        user: currentUser,
        scopes: currentScopes,
      });
    }
  }, [currentScopes, currentUser]);

  useEffect(() => {
    if (currentMaintainer) {
      const newMaintainers = maintainers?.filter(
        (maintainer) => maintainer?.user?.id !== currentMaintainer?.user?.id
      );
      newMaintainers.push(currentMaintainer);

      setMaintainers(newMaintainers);
    }
  }, [currentMaintainer]);

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        display: "flex",
        flexDirection: "row-wrap",
        justifyContent: "flex-start",
        width: "100%",
        padding: "1rem",
        margin: "1rem 0",
        // justifyContent: "center",
      }}
    >
      {/* <FormControl sx={{ m: 1, width: 600 }} fullWidth>
        <InputLabel id="scope-selector-label">Jogosultság</InputLabel>
        <Select
          labelId="scope-selector-label"
          label="Jogosultság"
          id="scope-selector"
          multiple
          value={selectSet}
          onChange={handleChange}
          input={<OutlinedInput label="Jogosultság" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {allScopes.map((scope) => (
            <MenuItem key={scope} value={scope}>
              <Checkbox
                color="primary"
                checked={selectSet.indexOf(scope) > -1}
              />
              <ListItemText color="primary" primary={scope} />
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      <Grid item container xs={12} style={{ overflow: "hidden" }}>
        <Grid xs={12}>
          <Typography
            style={{
              fontSize: "1.2rem",
              fontFamily: theme.typography.main,
              fontWeight: "bold",
            }}
          >
            {currentUser?.username} jogosultságai
          </Typography>
        </Grid>

        {currentVisibleScopes &&
          currentVisibleScopes?.map((scope, idx) => (
            <Grow in={true} timeout={500} direction={"left"}>
              <Grid item xs={2.5}>
                <Button
                  onClick={() => {
                    handleRemove(scope);
                  }}
                  style={{ position: "relative" }}
                >
                  {handleTranslate(scope)}
                  <ButtonOverlay
                    onMouseEnter={() => setHover(`${idx}_remove`)}
                    onMouseLeave={() => setHover(null)}
                    style={{ background: theme.palette.primary.error }}
                  >
                    {hover === `${idx}_remove` && (
                      <Remove
                        style={{ color: theme.palette.primary.textPrimary }}
                      />
                    )}
                  </ButtonOverlay>
                </Button>
              </Grid>
            </Grow>
          ))}
      </Grid>
      <Grid item container xs={12}>
        <Grid xs={12}>
          <Typography
            style={{
              fontSize: "1.2rem",
              fontFamily: theme.typography.main,
              fontWeight: "bold",
            }}
          >
            Választható jogosultságok
          </Typography>
        </Grid>
        {allScopes &&
          allScopes
            ?.filter(
              (scope) =>
                scope !== "ALLOW_PRINT_POST" &&
                scope !== "MANAGE_PAYMENT_VENDORS" &&
                scope !== "VIEW_PAYMENT_VENDORS" &&
                scope !== "MANAGE_INVOICES" &&
                scope !== "MANAGE_PAYMENTS" &&
                scope !== "MANAGE_DELIVERY_VENDORS" &&
                scope !== "VIEW_DELIVERY_VENDORS" &&
                scope !== "ALLOW_PRINT_DIGITAL"
            )
            ?.map(
              (scope, idx) =>
                scope !==
                  currentVisibleScopes?.find((cscope) => cscope === scope) && (
                  <Grid item xs={2.5}>
                    <Button
                      onClick={() => {
                        handleAdd(scope);
                      }}
                      style={{ position: "relative" }}
                    >
                      {handleTranslate(scope)}
                      <ButtonOverlay
                        onMouseEnter={() => setHover(`${idx}_add`)}
                        onMouseLeave={() => setHover(null)}
                      >
                        {hover === `${idx}_add` && (
                          <AddIcon
                            style={{ color: theme.palette.primary.textPrimary }}
                          />
                        )}
                      </ButtonOverlay>
                    </Button>
                  </Grid>
                )
            )}
      </Grid>
    </Grid>
  );
};

export default ScopeSelector;
