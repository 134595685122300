import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";

import { useStyles } from "./styles";
import SettingsCard from "components/PhotoAdminComponents/Settings/Card/SettingsCard";
import ActionButton from "components/common/ActionButton/ActionButton";
import { Form, Formik } from "formik";
import { useRef } from "react";
import passwordChangeValidationSchema from "validation/UserSettings/PasswordChangeSchema";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const ChangeEmailAddressCard = ({ user, putUserPassword, inProgress }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isEditing, setIsEditing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const formikRef = useRef(null);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.password = user?.password;
    }
  }, [user, formikRef]);

  return (
    <SettingsCard
      title={"Jelszó "}
      actionButton={
        <ActionButton
          icon={<Edit color="primary" />}
          clickHandler={toggleIsEditing}
        />
      }
    >
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          password: "",
          passwordConfirm: "",
        }}
        validationSchema={passwordChangeValidationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await putUserPassword({
            user: { ...user },
            password: values.password,
          });
          setIsEditing(false);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant="outlined"
                  fullWidth
                  required
                  label={"Jelszó"}
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={values.password === undefined ? '' : values.password}
                  onChange={handleChange}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant="outlined"
                  fullWidth
                  required
                  label={"Jelszó megerősítése"}
                  id="passwordConfirm"
                  name="passwordConfirm"
                  type={showPassword ? "text" : "password"}
                  value={values.passwordConfirm  === undefined ? '' : values.passwordConfirm}
                  onChange={handleChange}
                  error={
                    touched.passwordConfirm && Boolean(errors.passwordConfirm)
                  }
                  helperText={touched.passwordConfirm && errors.passwordConfirm}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              style={{
                margin: "2rem 0 0 0",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={!isEditing}
                  fullWidth
                  onClick={handleSubmit}
                  variant="outlined"
                  className={classes.button}
                >
                  {inProgress === "password" ? (
                    <>
                      Folyamatban
                      <CircularProgress
                        style={{ marginLeft: "0.5rem" }}
                        size={24}
                      />
                    </>
                  ) : (
                    "Jelszó frissítése"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangeEmailAddressCard;
