import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { forwardRef } from "react";
import Slide from "@material-ui/core/Slide";
import sizes from "constants/sizes";

export const Transition = forwardRef((props, ref) => {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "0 !important",
    width: "100%",
    height: "auto",
    padding: "1rem",
  },
  deleteWrapper: {
    color: theme.palette.primary.contrastText,
    transition: "250ms ease-in",
    "&:hover": {
      color: "#f00",
      transition: "0.25s linear",
    },
  },
  editWrapper: {
    color: theme.palette.primary.contrastText,
    transition: "250ms ease-in",
    "&:hover": {
      color: theme.palette.primary.backgroundSecondary,
      transition: "0.25s linear",
    },
  },
  mobileEditWrapper: {
    color: theme.palette.primary.main,
  },
}));

export const ProductWrapper = styled.div`
  border-radius: 4px;
  box-shadow: 0 4px 15px 0 rgb(0 0 0 / 30%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  width: 100%;
  position: relative;
  height: 250px;
  overflow: hidden;
  background: #ffffff;
  flex: 0 0 calc(100% - 20px);
  margin: 10px;
  &:hover,
  &:focus {
    background: #f6f6f6;
    cursor: pointer;
    transform: scale(1.005);
    transition: 250ms ease-in;
  }
  @media (min-width: 600px) {
    flex: 0 0 calc(50% - 20px);
  }
  @media (min-width: 900px) {
    flex: 0 0 calc(33% - 20px);
  }
  @media (min-width: 1200px) {
    flex: 0 0 calc(25% - 20px);
  }
  @media (min-width: 1536px) {
    flex: 0 0 calc(20% - 20px);
  } ;
`;

export const SelectWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  z-index: 1000;
  display: flex;
  justify-content: center;
  clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
  overflow: hidden;
  height: 100px;
  width: 100px;
  background: #fefefe;
  &:focus {
    transform: scale(1.005);
    transition: 250ms ease-in;
  }
  @media (min-width: ${sizes.SM}px) {
    height: 75px;
    width: 75px;
    margin-left: auto;
    margin-top: -0.2rem;
    margin-right: -0.2rem;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
  }
`;

export const ProductSelect = styled.input`
  cursor: pointer;
  margin-top: 0.7rem;
  width: 1.2rem;
  height: 1.2rem;

  @media (min-width: ${sizes.SM}px) {
    position: absolute;
    margin-top: 0;
    top: 0.9rem;
    right: 0.7rem;
  }
`;

export const OverlayWrapper = styled.div`
  z-index: 0;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  &:hover,
  &:focus {
    cursor: pointer;
    transform: scale(1.005);
    transition: 250ms ease-in;
  }
  @media (min-width: 600px) {
    z-index: 100;
  }
`;
export const ProductHiddenOverlay = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
`;
export const ProductImage = styled.img`
  display: block;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
  width: 100%;
`;
export const EditWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 25%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.22);
  display: grid;
  place-items: center;
`;
export const DeleteWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 75%;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.44);
  display: grid;
  place-items: center;
`;
export const MobileDeleteButton = styled.div`
  border-radius: 50px;
  color: red;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  opacity: 60%;
  text-align: center;
  background: whitesmoke;
  transition: opacity 300ms;
  &:hover {
    opacity: 100%;
  }
`;
export const MobileDeleteWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  width: 100px;
  height: 100px;
  background: transparent;
  @media (min-width: 600px) {
    z-index: -1;
  }
`;
export const MobileEditButton = styled.div`
  border-radius: 50px;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  opacity: 60%;
  text-align: center;
  background: whitesmoke;
  transition: opacity 300ms;
  &:hover {
    opacity: 100%;
  }
`;
export const MobileEditWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  width: 100px;
  height: 100px;
  background: transparent;
  @media (min-width: 600px) {
    z-index: -1;
  }
`;
export const ProductInnerWrapper = styled.div`
  z-index: 50;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
export const ProductTop = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 25%;
  left: 0;
  background: black;
`;
export const ProductBottom = styled.div`
  width: 100%;
  position: absolute;
  top: 75%;
  bottom: 0;
  left: 0;
`;

export const ProductTitle = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  padding: 0 1rem;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
