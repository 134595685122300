import * as yup from "yup";

const passwordChangeValidationSchema = yup.object({
  password: yup
    .string("Adja meg jelszavát")
    .min(8, "Legalább 8 hosszú jelszót adjon meg!")
    .required("Szükséges mező"),
  passwordConfirm: yup
    .string("Erősítse meg jelszavát")
    .when("password", (password) => {
      if (password) {
        return yup
          .string("Erősítse meg email címét")
          .required("Szükséges mező")
          .test(
            "do-the-password-match",
            "A két jelszó nem egyezik!",
            function (passwordConfirm) {
              return password === passwordConfirm;
            }
          );
      }
    }),
});

export default passwordChangeValidationSchema;
