import {
  Container,
  Dialog,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect, useState } from "react";

const HistoryViewer = ({ historyVisible, toggleHistoryVisible, data }) => {
  const [history, setHistory] = useState();

  useEffect(() => {
    setHistory(
      data?.orderHistories ? data?.orderHistories : data?.statusUpdates
    );
  }, [data]);

  const pTooltipCreator = (status) => {
    if (status?.description?.includes("to RECEIVED")) {
      return "A fizetési folyamat elindult";
    }
    if (status?.description?.includes("to CONFIRMED")) {
      return "A fizetés sikeresen megtörtént";
    }
    if (status?.description?.includes("to PAID")) {
      return "A fizetés sikeresen megtörtént.";
    }
    if (status?.description?.includes("FAIL translated to REJECTED")) {
      return "A fizetés sikertelen. Oka lehet a sikertelen kártya ellenőrzés";
    }
    if (status?.description?.includes("TIMEOUT translated to REJECTED")) {
      return "A fizetés sikertelen. Oka lehet a kapcsolati vagy szerver hiba";
    }
    if (status?.description?.includes("to CANCELLED")) {
      return "A fizetés megszakítva. Oka lehet hogy a vásárló nem fejezte be a fizetést, azt megszakította, vagy bezárta a böngészőablakot";
    }
  };
  const oTooltipCreator = (status) => {
    if (status?.toStatus === "PAID") {
      return "A fizetés sikeresen megtörtént!";
    }
    if (status?.toStatus === "INVOICED") {
      return "A számla sikeresen kiállításra, és kiküldésre került!";
    }
    if (status?.toStatus === "REJECTED_PAYMENT") {
      return "Fizetés sikertelen. További részleteket a fizetési történetben találhat!";
    }
    if (status?.toStatus === "REJECTED_DELIVERY") {
      return "Kézbesítés sikertelen! Digitális képek sikertelen kézbesítésénél, próbálja az újraküldés lehetőséget. Esetleg lépjen kapcsolatba a megrendelővel, előfordulhat hogy rossz email címet adott meg!";
    }
    if (status?.toStatus === "REJECTED_INVOICE") {
      return "Számlázás sikertelen.";
    }
    if (status?.toStatus === "SHIPPING") {
      return "Szállítás folyamatban. Digitális képek esetén a megrendelt termékek hamarosan megérkeznek a rendelő által megadott email címre. Papírképek esetén amint a fotók nyomtatásra kerültek, kézbesíteni fogjuk a rendelő által választott átvételi pontra.";
    }
    if (status?.toStatus === "SHIPPED") {
      return "Sikeres kézbesítés. A megrendelt termékek megérkeztek rendeltetési helyükre.";
    }
    if (status?.toStatus === "DONE") {
      return "A rendelés sikeresen lezárult, ezzel kapcsolatban már nincs további teendője";
    }
  };

  return (
    <Dialog
      open={historyVisible}
      onClose={toggleHistoryVisible}
      maxWidth={"md"}
    >
      <Container spacing={3}>
        <Grid container>
          {history?.length > 0 && (
            <>
              <Grid
                item
                container
                xs={12}
                style={{ maxHeight: "90vh", backgroundColor: "white" }}
              >
                {history[0]?.updateAt && (
                  <Grid container item xs={6} justifyContent="center">
                    <Typography variant="h4">
                      {"Módosítás időpontja"}
                    </Typography>
                  </Grid>
                )}
                <Grid
                  container
                  item
                  xs={history[0]?.updateAt ? 6 : 12}
                  justifyContent="center"
                >
                  <Typography variant="h4">{"Módosítás leírása"}</Typography>
                </Grid>
              </Grid>
              {history?.map((entry, idx) => (
                <Grid
                  key={idx}
                  item
                  container
                  xs={12}
                  style={{
                    maxHeight: "90vh",
                    backgroundColor: idx % 2 ? "lightgrey" : "white",
                  }}
                >
                  {entry?.updateAt && (
                    <Grid container item xs={6}>
                      {new Date(entry.updateAt).toLocaleDateString(
                        "hu-HU",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          seconds: "2-digit",
                        },
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          seconds: "2-digit",
                        }
                      )}
                    </Grid>
                  )}
                  <Tooltip
                    title={
                      entry?.message
                        ? oTooltipCreator(entry)
                        : pTooltipCreator(entry)
                    }
                    placement="bottom"
                  >
                    <Grid container item xs={history[0]?.updateAt ? 6 : 12}>
                      {entry?.message ? entry?.message : entry?.description}
                      <InfoIcon
                        style={{
                          fontSize: "0.9rem",
                          opacity: "0.5",
                          marginLeft: "5px",
                        }}
                      />
                    </Grid>
                  </Tooltip>
                </Grid>
              ))}
              {history?.length === 0 && (
                <Grid
                  item
                  container
                  xs={12}
                  style={{ maxHeight: "90vh", backgroundColor: "white" }}
                >
                  {"Nincs megjeleníthető elem"}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Container>
    </Dialog>
  );
};

export default HistoryViewer;
