import * as yup from "yup";

const validationSchema = yup.object({
  title: yup.string("Adj meg címet").required("Szükséges mező"),
  //short_description: yup.string('Adj meg egy rövíd leírást'),
  author: yup
    .string("Adj meg kedvezmény százalékot")
    .required("Szükséges mező"),
  thumbnail: yup
    .mixed("Tölts fel egy képet")
    .required("Thumbnail feltöltése kötelező"),
  content: yup.string("Ide írd a blogot"),
  language: yup.string("Válassz egy nyelvet").required("Szükséges mező"),
});

export default validationSchema;
