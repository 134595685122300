import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import * as React from "react";
import styles from "./blogEditor.module.css";
import validationSchema from "validation/Blog/Schema";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import HtmlEditor from "../htmlEditor/HtmlEditor";
import ThumbnailUploader from "../ThumbnailUploader/ThumbnailUploader";
import CloseIcon from "@mui/icons-material/Close";
import huIcon from "../../../assets/icons/hu.png";
import enIcon from "../../../assets/icons/en.png";
import { useBlogsLogic } from "pages/SuperAdmin/Blogs/useBlogsLogic";

const BlogEditor = ({ open, onClose, data }) => {
  const { putBlog, postBlog } = useBlogsLogic();
  const buttonRef = React.useRef(null);
  const [formData, setFormData] = React.useState({
    id: data?.id ?? 0,
    title: data.title ?? "",
    author: data?.author ?? "",
    thumbnail: data?.thumbnail ?? "",
    content: data?.content ?? "",
    language: data?.language ?? "EN",
    createdDate: dayjs(data?.createdDate) ?? new Date(),
  });

  React.useEffect(() => {
    setFormData({
      id: data?.id ?? 0,
      title: data.title ?? "",
      author: data?.author ?? "",
      thumbnail: data?.thumbnail ?? "",
      content: data?.content ?? "",
      language: data?.language ?? "EN",
      createdDate: dayjs(data?.createdDate) ?? new Date(),
    });
  }, [data]);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog
        onClose={handleClose}
        className={styles.dialog}
        open={open}
        maxWidth={false}
      >
        <DialogTitle
          style={{ textAlign: "center" }}
          className={styles.top_toolbar}
        >
          <h2>Blog szerkesztés</h2>
          <IconButton aria-label="close" onClick={() => onClose()}>
            <CloseIcon className={styles.close_icon} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={styles.mainContainer}>
            <Formik
              enableReinitialize
              initialValues={formData}
              validationSchema={validationSchema}
              onSubmit={() => {
                if (
                  formData.id == 0 ||
                  formData.id == null ||
                  formData.id == undefined
                ) {
                  postBlog.mutate(formData, {
                    onSuccess: () => {
                      onClose();
                    },
                  });
                } else {
                  putBlog.mutate(formData, {
                    onSuccess: () => {
                      onClose();
                    },
                  });
                }
              }}
            >
              {({ errors, /*touched*/ handleSubmit }) => (
                <form onSubmit={handleSubmit} className={styles.mainForm}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <ThumbnailUploader
                        thumbnail={formData.thumbnail}
                        onChange={(e) =>
                          setFormData({ ...formData, thumbnail: e })
                        }
                      />
                      <div className={styles.error_text}>
                        {Boolean(errors.thumbnail) && <>{errors.thumbnail}</>}
                      </div>
                    </div>
                    <Select
                      id="language"
                      name="language"
                      value={formData.language}
                      onChange={(e) => {
                        setFormData({ ...formData, language: e.target.value });
                      }}
                      className={styles.lang_selector}
                      error={Boolean(errors.language)}
                    >
                      <MenuItem key={"EN"} value={"EN"}>
                        <div
                          style={{ background: `url(${enIcon})` }}
                          className={styles.lang_icon}
                        ></div>
                      </MenuItem>
                      <MenuItem key={"HU"} value={"HU"}>
                        <div
                          style={{ background: `url(${huIcon})` }}
                          className={styles.lang_icon}
                        ></div>
                      </MenuItem>
                    </Select>
                  </div>

                  <TextField
                    variant="outlined"
                    value={formData.title}
                    fullWidth
                    label="Title"
                    autoFocus
                    onChange={(e) => {
                      setFormData({ ...formData, title: e.target.value });
                    }}
                    error={Boolean(errors.title)}
                    helperText={<>{errors.title}</>}
                  />

                  <TextField
                    variant="outlined"
                    value={formData.author}
                    fullWidth
                    label="Author"
                    autoFocus
                    onChange={(e) => {
                      setFormData({ ...formData, author: e.target.value });
                    }}
                    error={Boolean(errors.author)}
                    helperText={<>{errors.author}</>}
                  />

                  <HtmlEditor
                    customKey={formData.id}
                    value={formData.content}
                    onChange={(editor) => {
                      setFormData({ ...formData, content: editor ?? "" });
                    }}
                  />
                  <button
                    ref={buttonRef}
                    disabled={false}
                    type="submit"
                    style={{ display: "none" }}
                  >
                    SUBMIT
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => buttonRef.current.click()}
            disabled={putBlog.isLoading || postBlog.isLoading}
          >
            {putBlog.isLoading || postBlog.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Mentés"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default BlogEditor;
