import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0rem",
    overflow: "auto",
  },

  title: {
    color: theme.palette.primary.backgroundSecondary,
    borderBottom: `3px solid ${theme.palette.primary.backgroundSecondary}`,
  },
  confirmBtn: {
    color: "white",
    width: "100%",
    height: "auto",
    padding: "1rem",
    margin: "0 2rem",
    backgroundColor: theme.palette.primary.backgroundSecondary,
    "&:hover": {
      backgroundColor: theme.palette.primary.backgroundPrimary,
    },
  },
  cancelBtn: {
    color: "white",
    width: "100%",
    height: "auto",
    padding: "1rem",
    margin: "0 2rem",
    backgroundColor: theme.palette.primary.backgroundSecondary,

    "&:hover": {
      backgroundColor: theme.palette.primary.backgroundPrimary,
    },
  },
  tableBorder: {
    borderTop: "2px solid black",
  },
  customCell: {
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: ".8rem",
    fontWeight: "bold",
  },
  categoryRow: {
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: ".8rem",
    fontWeight: "bold",
    letterSpacing: ".1rem",
    lineHeight: "1.54",
    backgroundColor: "#f0f0f0",
  },
  deleteBtn: {
    color: "red",
    background: "transparent",
    boxShadow: "none",
    borderRadius: "50%",
    minWidth: 0,
    marginTop: "-3rem",
    opacity: "60%",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(30,30,30,0.1)",
      boxShadow: "none",
      opacity: "100%",
      transition: "all 300ms",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      margin: "0",
      top: "6rem",
      right: "3rem",
    },
  },

  formlabel: {
    "& .MuiTypography-root": {
      fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    },
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 0,
    margin: 0,
  },
  tablecontainer: {
    marginTop: "1rem",
    width: "max-content",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "300px",
    height: "300px",
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
    "@media (min-width: 768px)": {},
  },
  productName: {
    width: "300px",
    "@media (min-width: 768px)": {
      width: "100%",
    },
  },
  grid: {
    width: "350px",
    alignSelf: "center",
    padding: 0,
    margin: 0,
    "@media (min-width: 768px)": {
      width: "600px",
    },
  },
  huf: {
    width: "6rem",
  },
  date: {
    width: "10rem",
  },
}));

export const ButtonsWrapper = styled.div`
  width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 1.5rem 0;
`;
export const Title = styled.h1`
  width: 400px
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
  font-size: 25px;
  text-transform: uppercase;
  text-align: center; 
  height: 4rem;
  @media (min-width: 768px){
    font-size: 32px; 
  }
`;
