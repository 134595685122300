import { useState, useEffect, createRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';
import Tooltip from '@mui/material/Tooltip';
import { Skeleton } from '@mui/material';

import ProductDetails from './ProductDetails/ProductDetails';
import {
  Transition,
  useStyles,
  ProductWrapper,
  OverlayWrapper,
  ProductHiddenOverlay,
  ProductImage,
  EditWrapper,
  DeleteWrapper,
  ProductInnerWrapper,
  ProductTop,
  ProductBottom,
  ProductTitle,
  MobileDeleteWrapper,
  MobileEditWrapper,
  MobileDeleteButton,
  MobileEditButton,
  ProductSelect,
  SelectWrapper
} from './styles';
import { useProductAPI } from './hooks/useProductAPI';
import { Typography } from '@material-ui/core';

const Product = ({ shopId, refresh, productHeader, modifiers, parentGroup, selectProduct, selected }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { fetchProduct, product, fetchProductImage, productImage, deleteProduct } = useProductAPI();

  useEffect(() => {
    fetchProductImage({ id: productHeader.id,  });
  }, [productHeader.id]);

  const [hover, setHover] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const toggleOpen = async () => {
    await fetchProduct({ id: productHeader.id });
    setOpen(!open);
  };

  const editProductModalRef = createRef(null);

  const calcName = (name) => {
    let displayedName = name;

    if (name.length > 25) {
      displayedName = name.slice(0, 10) + '...' + name.slice(name.length - 10, name.length);
    }

    return displayedName;
  };

  const handleDelete = async () => {
    await deleteProduct({ id: productHeader.id });
    setConfirmOpen(false);
    setHover(false);
    refresh();
  };

  return (
    <Tooltip title={productHeader.name}>
      <ProductWrapper>
        {!productHeader?.isActive && <ProductHiddenOverlay />}
        <SelectWrapper>
          <ProductSelect onChange={selectProduct} color='primary' type='checkbox' checked={selected} name='' id='' />
        </SelectWrapper>

        <OverlayWrapper onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          {hover && (
            <DeleteWrapper
              className={classes.deleteWrapper}
              onClick={(e) => {
                setConfirmOpen(true);
              }}>
              <DeleteIcon fontSize='large' />
            </DeleteWrapper>
          )}
          {hover && (
            <EditWrapper className={classes.editWrapper} onClick={toggleOpen}>
              <Edit fontSize='large' />
            </EditWrapper>
          )}
        </OverlayWrapper>
        {productHeader && productImage ? (
          <ProductInnerWrapper>
            <ProductTop>
              <MobileDeleteWrapper
                onClick={(e) => {
                  setConfirmOpen(true);
                }}>
                <MobileDeleteButton>
                  <DeleteIcon fontSize='large' style={{ marginTop: '0.1rem' }} />
                </MobileDeleteButton>
              </MobileDeleteWrapper>
              <MobileEditWrapper className={classes.mobileEditWrapper} onClick={toggleOpen}>
                <MobileEditButton>
                  <Edit fontSize='large' style={{ marginTop: '0.1rem' }} />
                </MobileEditButton>
              </MobileEditWrapper>
              <ProductImage src={productImage} alt={`${productHeader?.name}_thumbnail`} />
            </ProductTop>
            <ProductBottom>
              <ProductTitle>{calcName(productHeader?.name)}</ProductTitle>
            </ProductBottom>
          </ProductInnerWrapper>
        ) : (
          <>
            <Skeleton variant='rectangular' width={'auto'} height={187} />
            <Skeleton variant='text' />
          </>
        )}
        <Dialog
          open={confirmOpen}
          onClose={(e) => {
            setConfirmOpen(false);
          }}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle className={classes.customDialogTitle} id='alert-dialog-title'>
            <Typography
              style={{
                fontFamily: `${theme.typography.main}, Sans-serif, Arial`
              }}>
              Biztosan törölni szeretnéd?
            </Typography>
          </DialogTitle>
          <DialogActions className={classes.dialogActions}>
            <Button className={classes.customButton} onClick={handleDelete} color='primary'>
              Törlés
            </Button>
            <Button
              className={classes.customButton}
              onClick={() => {
                setConfirmOpen(false);
              }}
              color='primary'>
              Mégse
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          maxWidth={false}
          ref={editProductModalRef}
          onClose={() => setOpen(false)}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'>
          {open && product && (
            <ProductDetails
              parentGroup={parentGroup}
              currentProduct={{
                ...productHeader,
                modifiers: product?.modifiers
              }}
              availableModifiers={modifiers}
              productImage={productImage}
              refresh={refresh}
              toggle={toggleOpen}></ProductDetails>
          )}
        </Dialog>
      </ProductWrapper>
    </Tooltip>
  );
};

export default Product;
