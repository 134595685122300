/** @format */

import axios from "axios";
import AuthService from "services/AuthService";

export const getApi = async ({ baseUrl }) => {
    const apiInstance = axios.create({
        baseURL: baseUrl,
        timeout: window._env_.REACT_APP_AXIOS_TIMEOUT,
    });

    apiInstance.interceptors.request.use(async (config) => {
        const token = await AuthService.authHeader();
        if (token) {
            config.headers["Authorization"] = token;
        }
        return config;
    });
    apiInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        async function (error) {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                await AuthService.updateToken();
                const token = await AuthService.authHeader();
                axios.defaults.headers.common["Authorization"] = token;
                return apiInstance(originalRequest);
            }
            return Promise.reject(error);
        }
    );

    return apiInstance;
};
