import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    width: "100%",
  },
  content: {
    width: "100%",
  },
  container: {
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 240,
  },
  breadcrumb: {
    margin: "1rem",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    outline: "none !important",
    color: theme.palette.primary.backgroundSecondary,
  },
}));
