import React from "react";
import styles from "./Blogs.module.css";
import { useBlogsLogic } from "./useBlogsLogic";
import { IconButton } from "@mui/material";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../components/datagrid/customNoRowsOverlay/CustomNoRowsOverlay";
import BlogEditor from "../../../components/blogComponents/blogEditor/BlogEditor";
import huIcon from "../../../assets/icons/hu.png";
import enIcon from "../../../assets/icons/en.png";
import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";
import { Typography } from "@material-ui/core";

const Blogs = () => {
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const blogData = React.useRef({});
  const { fetchBlogs, deleteBlog, setPaginationModel, paginationModel } =
    useBlogsLogic();

  const [rowCountState, setRowCountState] = React.useState(
    fetchBlogs.data?.data?.page?.totalElements || 0
  );

  React.useEffect(() => {
    const pageInfo = fetchBlogs.data?.data?.page;
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalElements !== undefined
        ? pageInfo?.totalElements
        : prevRowCountState
    );
  }, [
    fetchBlogs.data?.data?.page,
    fetchBlogs.data?.data?.page?.totalElements,
    setRowCountState,
  ]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={styles.toolbar}>
        <GridToolbarQuickFilter />
        <IconButton
          onClick={() => {
            blogData.current = {};
            setIsEdit(true);
          }}
        >
          <AddIcon />
        </IconButton>
      </GridToolbarContainer>
    );
  }
  const columns = [
    {
      field: "id",
      headerName: "",
      type: "actions",
      renderCell: (params) => (
        <img
          className={styles.thumbnail}
          src={`data:image/png;base64,${params.row.thumbnail}`}
          alt={params.row.title + " Thumbnail"}
          width={"100px"}
        />
      ),
      width: 100,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "author",
      headerName: "Author",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "createdDate",
      headerName: "Creation Date",
      renderCell: (params) => (
        <div>{moment(params.value).format("yyyy.MM.DD hh:mm:ss")}</div>
      ),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "language",
      headerName: "",
      renderCell: (params) => (
        <div
          style={{
            background: `url(${params.value === "HU" ? huIcon : enIcon})`,
            height: "30px",
            width: "30px",
            marginTop: "0.7rem",
          }}
        ></div>
      ),
      width: 30,
    },
    {
      field: "actions",
      type: "actions",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => {
              blogData.current = params.row;
              setIsEdit(true);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              blogData.current = params.row;
              setConfirmOpen(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <main className={styles.main_container}>
      <Typography color="inherit" variant="body1">
        Blogok
      </Typography>
      <div className={styles.datagrid_container}>
        <DataGrid
          loading={fetchBlogs.isLoading}
          rows={fetchBlogs.data?.data?._embedded?.blogDTOList ?? []}
          columns={columns}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{
            toolbar: CustomToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          autoHeight
          paginationModel={paginationModel}
          rowCount={rowCountState}
          pageSizeOptions={[10]}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
        />
      </div>
      <BlogEditor
        open={isEdit}
        onClose={() => {
          blogData.current = {};
          setIsEdit(false);
        }}
        data={blogData.current ?? {}}
      />
      <ConfirmDialog
        open={confirmOpen}
        onOk={() => {
          deleteBlog.mutate(blogData?.current?.id);
          setConfirmOpen(false);
        }}
        onClose={() => setConfirmOpen(false)}
        functionButtonText={"Törlés"}
        closeButtonText={"Mégse"}
        multipleButtons
        title="Biztos törölni szeretnéd ezt a blog cikket?"
      />
    </main>
  );
};

export default Blogs;
