import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  autoComplete: {
    width: "100%",
  },
  input: {
    width: "100%",
  },
  label: {
    fontWeight: 400,
    fontSize: "1rem",
    margin: "1rem 0 0 0",
  },
  zip: {},
  city: {},
  container: {
    padding: "0.1rem",
  },
}));
