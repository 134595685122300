import { getApi } from "../axios";

export default {
  async putBlog({ blogsDTO }) {
    const formData = new FormData();
    const { thumbnail, ...blog } = blogsDTO;
    if (thumbnail instanceof File) {
      formData.append("thumbnail", thumbnail);
    } else {
      //transform to file
      const byteCharacters = atob(thumbnail);
      const byteNumbers = new Array(byteCharacters.length);
      // Convert binary to character codes
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      // Create a Uint8Array from character codes
      const byteArray = new Uint8Array(byteNumbers);
      // Create a Blob from Uint8Array
      const blob = new Blob([byteArray], { type: "image/png" });
      // Create a File from Blob
      const file = new File([blob], "thumbnail.png", { type: "image/png" });
      formData.append("thumbnail", file);
    }

    // Append JSON data
    formData.append(
      "blog",
      new Blob([JSON.stringify(blog)], {
        type: "application/json",
      })
    );
    await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true,
      })
    ).put(`admin/blogs/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async deleteBlog({ blogId }) {
    await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true,
      })
    ).delete(`admin/blogs/${blogId}`);
  },
  async fetchBlogs({ paginationModel }) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true,
      })
    ).get(
      `blogs/?page=${paginationModel.page + 1}&size=${
        paginationModel.pageSize
      }&sort=id,desc`
    );
  },
  async postBlog({ blogsDTO }) {
    const formData = new FormData();
    delete blogsDTO.id;
    const { thumbnail, ...blog } = blogsDTO;

    // Append JSON data
    formData.append(
      "blog",
      new Blob([JSON.stringify(blog)], {
        type: "application/json",
      })
    );

    formData.append("thumbnail", thumbnail);

    await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true,
      })
    ).post(`admin/blogs`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
