import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import AuthService from 'services/AuthService'

const baseUrl = ["product-url"]

export const addFilesToQueue = createAsyncThunk('uploadQueueSlice/addFilesToQueue', async ({ fileList, group }, thunkAPI) => {

  let queue = thunkAPI.getState().nonPersistedReducers.uploadQueueSliceReducer.queue
  const state = thunkAPI.getState().nonPersistedReducers.uploadQueueSliceReducer
  let taskList = [...queue]
  let newTaskList = []

  for (let i = 0; i < fileList.length; i++) {
    newTaskList.push({
      file: fileList.item(i),
      upload: null
    })
  }
  const url = `${baseUrl}/admin/groups/${group.id}/products`
  newTaskList.forEach(async (task) => {
    try {
      const form = new FormData();

      const productDto = {
        name: task.file.name,
        groupId: group.id,
        isActive: false,
        modifiers: group.modifiers
      };

      form.append("imageFile", task.file);
      form.append("productDTO", new Blob([JSON.stringify(productDto)], {
        type: 'application/json'
      }));

      task.upload = axios.post(
        url,
        form,
        {
          headers: {
            'Authorization': AuthService.authHeader(),
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (e) => {
            task.progress = Math.floor(e.loaded / e.total)
            task.startTime = task.startTime ? task.startTime : e.timeStamp
            task.timeStamp = e.timeStamp
            task.timePassed = (task.timeStamp - task.timePassed) / 1000
          }
        }
      )
      await task.upload
    } catch (e) {
      console.log(e)
    }
  })
  queue = [...taskList, ...newTaskList]

  return { ...state, queue: queue }
})

let initialQueueState = {
  queue: [],
  uploads: []
}

export const uploadQueueSlice = createSlice({
  name: 'uploadQueueSlice',
  initialState: initialQueueState,
  reducers: {
    clearUploads: (state, action) => {
      state.uploads = []
    },
    addUploadToQueue: (state, action) => {
      let idx = state.uploads.findIndex(upload => upload.uid === action.payload.uid)

      if (idx !== -1) {
        state.uploads[idx]["size"] = action.payload.size
        state.uploads[idx]["progress"] = action.payload.progress
        state.uploads[idx]["done"] = action.payload.done
        state.uploads[idx]["timeStart"] = state.uploads[idx]["timeStart"] ? state.uploads[idx]["timeStart"] : action.payload.timeStamp
        state.uploads[idx]["time"] = (Number(action.payload.timeStamp) - Number(state.uploads[idx]["timeStart"]))/1000
        state.uploads[idx]["name"] = action.payload.name
        state.uploads[idx]["file"] = action.payload.file
      } else {
        state.uploads.push(action.payload)
      }

    }
  },
  extraReducers: {
    [addFilesToQueue.pending]: (state, action) => {
    },
    [addFilesToQueue.fulfilled]: (state, action) => {
    },
    [addFilesToQueue.rejected]: (state, action) => {
    },
  }
})

export const { addUploadToQueue, clearUploads } = uploadQueueSlice.actions

export default uploadQueueSlice.reducer
