import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import { useStyles } from "./styles";
import SettingsCard from "components/PhotoAdminComponents/Settings/Card/SettingsCard";
import ActionButton from "components/common/ActionButton/ActionButton";
import { Form, Formik } from "formik";
import { useRef } from "react";
import emailChangeValidationSchema from "validation/UserSettings/EmailChangeSchema";
import { CircularProgress } from "@material-ui/core";

const ChangeEmailAddressCard = ({ user, putUserEmail, inProgress }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const formikRef = useRef(null);

  const [emailData, setEmailData] = useState({ email: "" });

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.contactEmail = emailData?.email;
    }
  }, [user, formikRef]);

  useEffect(() => {
    if (isEditing) {
      setEmailData({ email: "" });
    } else {
      setEmailData({
        email: user?.contact?.email,
      });
    }
  }, [user, isEditing]);

  return (
    <SettingsCard
      title={"Email cím "}
      actionButton={
        <ActionButton
          icon={<Edit color="primary" />}
          clickHandler={toggleIsEditing}
        />
      }
    >
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          contactEmail: "",
          emailConfirm: "",
        }}
        validationSchema={emailChangeValidationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await putUserEmail({ user: { ...user }, email: values.contactEmail });
          setIsEditing(false);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant="outlined"
                  fullWidth
                  required
                  label={"Email cím"}
                  id="contactEmail"
                  name="contactEmail"
                  type="email"
                  value={isEditing ? values.contactEmail : emailData?.email}
                  onChange={handleChange}
                  error={touched.contactEmail && Boolean(errors.contactEmail)}
                  helperText={touched.contactEmail && errors.contactEmail}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant="outlined"
                  fullWidth
                  required
                  label={"Email cím megerősítése"}
                  id="emailConfirm"
                  name="emailConfirm"
                  type="email"
                  value={values.emailConfirm}
                  onChange={handleChange}
                  error={touched.emailConfirm && Boolean(errors.emailConfirm)}
                  helperText={touched.emailConfirm && errors.emailConfirm}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              style={{
                margin: "2rem 0 0 0",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Grid item xs={12}>
                <Button
                  disabled={!isEditing}
                  type="submit"
                  fullWidth
                  onClick={handleSubmit}
                  variant="outlined"
                  className={classes.button}
                >
                  {inProgress === "email" ? (
                    <>
                      Folyamatban
                      <CircularProgress
                        style={{ marginLeft: "0.5rem" }}
                        size={24}
                      />
                    </>
                  ) : (
                    "Email cím frissítése"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangeEmailAddressCard;
