import { useStyles, IconWrapper } from "./styles";
import { useTheme } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
const CheckIcon = ({ position, right, bottom, size, speedy }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [start, setStart] = useState(null);

  const timeout = setTimeout(
    () => {
      setStart(true);
    },
    speedy ? 0 : 2000
  );

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <IconWrapper
      style={{
        position: position && position,
        right: right && right,
        bottom: bottom && bottom,
      }}
    >
      {start && (
        <svg
          className={classes.successCheck}
          style={{ width: size && `${size}px`, height: size && `${size}px` }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            className={classes.successCheckCircle}
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            className={classes.succcesCheckCheck}
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      )}
    </IconWrapper>
  );
};

export default CheckIcon;
