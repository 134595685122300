import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  calcOption: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "3rem",
  },

  optionBtn: {
    fontWeight: "bolder",
  },
  specialOBtn: {
    height: "2.3rem",
    fontSize: "2rem",
  },

  calcParam: {
    display: "flex",
    justifyContent: "center",
  },
}));

export const CalculatorWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: ${({ theme }) => theme.palette.primary.backgroundSecondary};
`;
