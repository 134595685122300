/** @format */

import { getApi } from "api/axios";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { convertBlobToBase64 } from "utils/ImageUploadRequest";

export const useGroupAPI = () => {
    const [groups, setGroups] = useState(null);
    const [groupLoading, setGroupLoading] = useState(null);
    const [groupsLength, setGroupsLength] = useState(0);
    const [loading, setLoading] = useState();
    const [coverLoading, setCoverLoading] = useState();
    const shopId = useSelector(
        (state) => state.nonPersistedReducers.shopSliceReducer.shopId
    );

    const fetchGroups = useCallback(async () => {
        setGroupLoading(true);
        try {
            setGroups(null);
            const res = await (
                await getApi({
                    baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                })
            ).get(`/admin/shops/${shopId}/groups`);
            if (res.status === 200) {
                setGroupLoading(false);
                setGroups(res.data);
            }
        } catch (e) {
            setGroupLoading(false);
            console.log(e);
        }
    }, [shopId]);

    const fetchGroupsNew = async ({ queryString }) => {
        try {
            setLoading(true);
            setGroups(null);
            const res = await (
                await getApi({
                    baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                })
            ).get(
                `/admin/shops/${shopId}/groups/filter${queryString}${
                    queryString ? "&" : "?"
                }topLevel=true`
            );
            if (res.status === 200) {
                const groups = res.data._embedded.groupDTOList.map((group) => ({
                    ...group,
                    selected: false,
                }));
                setLoading(false);
                setGroupsLength(res.data.page.totalElements);
                setGroups(groups);
            }
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };

    const [parentHeader, setParentHeader] = useState();

    const fetchParentHeader = useCallback(
        async ({ id }) => {
            try {
                const res = await (
                    await getApi({
                        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                    })
                ).get(`/admin/shops/${shopId}/groups/${id}`);
                setParentHeader(res.data);
            } catch (e) {
                console.log(e);
            }
        },
        [shopId]
    );

    const [header, setHeader] = useState(null);

    const fetchHeader = useCallback(
        async ({ id }) => {
            try {
                setHeader(null);
                const res = await (
                    await getApi({
                        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                    })
                ).get(`/admin/shops/${shopId}/groups/${id}`);
                setHeader(res.data);
            } catch (e) {
                console.log(e);
            }
        },
        [shopId]
    );
    const putHeader = useCallback(
        async ({ body }) => {
            try {
                setLoading(true);
                setHeader(null);
                const res = await (
                    await getApi({
                        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                    })
                ).put(`/admin/shops/${shopId}/groups`, body);
                setHeader(res.data);
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        },
        [shopId]
    );

    const [cover, setCover] = useState(null);

    const fetchCover = useCallback(
        async ({ id }) => {
            try {
                setCoverLoading(true);
                setCover(null);
                const res = await (
                    await getApi({
                        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                    })
                ).get(`/admin/shops/${shopId}/groups/${id}/cover`, {
                    responseType: "blob",
                });
                let image = await convertBlobToBase64(await res.data);
                setCover(image);
                setCoverLoading(false);
            } catch (e) {
                setCoverLoading(false);
                console.log(e);
            }
        },
        [shopId]
    );

    const [products, setProducts] = useState(null);
    const [productsLength, setProductsLength] = useState(null);

    const fetchProducts = useCallback(
        async ({ id, queryString }) => {
            try {
                setProducts(null);
                const res = await (
                    await getApi({
                        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                    })
                ).get(
                    `/admin/shops/${shopId}/groups/${id}/products${
                        queryString
                            ? queryString
                            : "?page=1&size=1&sort=name,asc"
                    }`
                );
                const products = res.data._embedded.productDTOList.map(
                    (product) => ({
                        ...product,
                        selected: false,
                    })
                );
                setProducts(products);
                setProductsLength(res.data.page.totalElements);
            } catch (e) {
                console.log(e);
            }
        },
        [shopId]
    );

    const [innerGroups, setInnerGroups] = useState(null);
    const [innerGroupLoading, setInnerGroupLoading] = useState(null);

    const fetchInnerGroups = useCallback(
        async ({ id }) => {
            setInnerGroupLoading(true);
            try {
                setInnerGroups(null);
                const res = await (
                    await getApi({
                        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                    })
                ).get(`/admin/shops/${shopId}/groups/${id}/groups`);
                if (res.status === 200) {
                    const innerGroups = res.data.map((group) => ({
                        ...group,
                        selected: false,
                    }));
                    setInnerGroupLoading(false);
                    setInnerGroups(innerGroups);
                }
            } catch (e) {
                setInnerGroupLoading(false);
                console.log(e);
            }
        },
        [shopId]
    );

    const [group, setGroup] = useState(null);

    const getBody = (header, modifiersArr) => {
        let actualBody;
        actualBody = {
            ...header,
            isSecured: !!header.accessKey,
            modifiers: [...modifiersArr],
        };
        if (actualBody.modifiers?.length > 0) {
            // group uses modifiers (tech template)
            delete actualBody.modifierTemplate;
            actualBody.inheritModifiers = false;
        } else if (
            actualBody.modifierTemplate &&
            !actualBody.modifierTemplate.technical
        ) {
            // group uses template
            actualBody.modifierTemplate = {
                id: actualBody.modifierTemplate.id,
            };
            actualBody.inheritModifiers = false;
            delete actualBody.modifiers;
        } else {
            // class inherints
            delete actualBody.modifiers;
            delete actualBody.modifierTemplate;
        }
        return actualBody;
    };

    const putGroup = useCallback(
        async ({ body }) => {
            setLoading(true);
            try {
                setGroup(null);
                const res = await (
                    await getApi({
                        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                    })
                ).put(`/admin/shops/${shopId}/groups`, body);
                setGroup(res.data);
                setLoading(false);
            } catch (e) {
                console.log(e);
                setLoading(false);
            }
        },
        [shopId]
    );

    const createSchool = async ({ body }) => {
        setLoading(true);
        try {
            setGroup(null);
            const res = await (
                await getApi({
                    baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                })
            ).post(`/admin/shops/${shopId}/groups`, body);
            setGroup(res.data);
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    };
    const createClass = async ({ body }) => {
        setLoading(true);
        try {
            setGroup(null);
            const res = await (
                await getApi({
                    baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                })
            ).post(`/admin/shops/${shopId}/groups`, body);

            if (res.data) {
                // Creating an inner gallery for group pictures.
                let groupBody = { ...body };
                groupBody.name = "Csoportképek";
                groupBody.belongsTo = {
                    name: res.data.name,
                    id: res.data.id,
                };
                groupBody.inheritModifiers = true;
                groupBody.accessKeyInherited = true;
                groupBody.inheritValidities = true;
                delete groupBody.modifiers;
                delete groupBody.modifierTemplate;
                await (
                    await getApi({
                        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                    })
                ).post(`/admin/shops/${shopId}/groups`, groupBody);
            }

            setGroup(res.data);
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    };

    const postGroup = useCallback(
        async ({ body }) => {
            if (
                body.belongsTo === null ||
                typeof body.belongsTo === "undefined"
            ) {
                await createSchool({ body });
            } else {
                await createClass({ body });
            }
        },
        [shopId]
    );

    const [groupDeleting, setGroupDeleting] = useState(false);

    const deleteGroup = useCallback(
        async ({ id }) => {
            setGroupDeleting(true);
            try {
                await (
                    await getApi({
                        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                    })
                ).delete(`/admin/shops/${shopId}/groups/${id}`);
                setGroupDeleting(false);
            } catch (e) {
                setGroupDeleting(false);
                console.log(e);
            }
        },
        [shopId]
    );

    return {
        getBody,
        fetchGroups,
        fetchGroupsNew,
        groupsLength,
        groups,
        groupLoading,
        fetchHeader,
        putHeader,
        header,
        fetchParentHeader,
        parentHeader,
        fetchCover,
        coverLoading,
        setCoverLoading,
        cover,
        setCover,
        fetchProducts,
        products,
        setProducts,
        productsLength,
        fetchInnerGroups,
        innerGroupLoading,
        innerGroups,
        setInnerGroups,
        postGroup,
        putGroup,
        loading,
        group,
        deleteGroup,
        groupDeleting,
    };
};
