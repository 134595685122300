import { getApi } from "api/axios";
import { useState } from "react";

export const useModifierApi = () => {
  const [modifiers, setModifiers] = useState([]);
  const [modifiersLoading, setModifiersLoading] = useState(false);
  const [modifiersError, setModifiersError] = useState(null);

  const getModifiers = async () => {
    try {
      setModifiersLoading(true);

      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/admin/modifiers`);
      setModifiers(res.data);
      setModifiersLoading(false);
    } catch (error) {
      setModifiersLoading(false);
      setModifiersError(error);
    }
  };

  return { getModifiers, modifiers, modifiersError, modifiersLoading };
};
