const sizes = {
    XS: 0,
    SM: 600,
    MD: 900,
    LG: 1200,
    XL: 1536 
}

export const DRAWER_WIDTH = 240

export default sizes