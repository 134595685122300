import { useTheme } from "@material-ui/core/styles";
import { TextField } from "@mui/material";
import React from "react";

const StringEditor = ({ option, setOption }) => {
  const theme = useTheme();

  return (
    <TextField
      variant="outlined"
      InputProps={{ style: { fontFamily: theme.typography.main } }}
      onChange={(e) =>
        setOption({ ...option, value: `${option.name}=${e.target.value}` })
      }
    />
  );
};

export default StringEditor;
