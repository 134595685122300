import { useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  ControlBtnContainer,
  NameContainer,
  Row,
  Title,
  useStyles,
} from "./styles";
import {
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import { useState } from "react";
import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";

const NewNotificationCard = ({
  setCreate,
  createNotification,
  requiredActions,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [newNotification, setNewNotification] = useState({
    isPublic: false,
    isActive: true,
  });
  const [open, setOpen] = useState(false);
  return (
    <Zoom in={true} timeout={700} unmountOnExit>
      <Grid item xs={12} md={6}>
        <Paper className={classes.paper}>
          <NameContainer>
            <TextField
              placeholder="Üzenet címe"
              className={classes.nameField}
              onChange={(e) => {
                setNewNotification({
                  ...newNotification,
                  description: e.target.value,
                });
              }}
              value={newNotification?.description}
            />
          </NameContainer>

          <ControlBtnContainer>
            <Tooltip title={"Üzenet létrehozása"}>
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                style={{
                  color: theme.palette.primary.textPrimary,
                }}
              >
                <DoneIcon />
                {/* Mentés */}
              </Button>
            </Tooltip>
            <Tooltip title={"Ablak bezárása"}>
              <Button
                onClick={() => {
                  setCreate(false);
                }}
                style={{
                  color: theme.palette.primary.textPrimary,
                }}
              >
                <CancelIcon />
                {/* Mégsem */}
              </Button>
            </Tooltip>
          </ControlBtnContainer>
          {/* <Row>
            <Title>Üzenet:</Title>
            <TextField
              className={classes.detail}
              onChange={(e) => {
                setNewNotification({
                  ...newNotification,
                  message: e.target.value,
                });
              }}
              value={newNotification?.message}
            />
          
          </Row> */}
          <Row>
            <Title>Üzenet:</Title>
            <TextField
              className={classes.detail}
              onChange={(e) => {
                setNewNotification({
                  ...newNotification,
                  message: e.target.value,
                });
              }}
              value={newNotification?.message}
            />
            {/* <Detail>{notification?.title}</Detail> */}
          </Row>
          <Row>
            <Title>Publikus üzenet</Title>
            <Checkbox
              className={classes.check}
              multiline={true}
              color="primary"
              checked={newNotification?.isPublic}
              onChange={(e) => {
                setNewNotification({
                  ...newNotification,
                  isPublic: !newNotification?.isPublic,
                });
              }}
              value={newNotification?.isPublic}
            />
            {/* <Detail>{notification?.mainDesc}</Detail> */}
          </Row>
          {/* <Row>
            <Title>Aktív?</Title>
            <Checkbox
              className={classes.check}
              multiline={true}
              color="primary"
              checked={newNotification?.isActive}
              onChange={(e) => {
                setNewNotification({
                  ...newNotification,
                  isActive: !newNotification?.isActive,
                });
              }}
              value={newNotification?.isActive}
            />
            
          </Row> */}
          <Row>
            <Title>Szükséges műveletek:</Title>
            <Select
              required
              variant="outlined"
              className={classes.detail}
              defaultValue={"NO_ACTION"}
              onChange={(e) => {
                if (e.target.value === "NO_ACTION") {
                  setNewNotification({
                    ...newNotification,
                    requiredActions: [],
                  });
                } else {
                  setNewNotification({
                    ...newNotification,
                    requiredActions: [e.target.value],
                  });
                }
              }}
            >
              <MenuItem
                key="0_action"
                value={"NO_ACTION"}
                style={{
                  fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                }}
              >
                Válassz egyet...
              </MenuItem>
              {requiredActions?.map((action) => (
                <MenuItem
                  key={`${action.id}_action`}
                  value={action}
                  style={{
                    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                  }}
                >
                  {action.action}
                </MenuItem>
              ))}
            </Select>

            {/* <Detail>{notification?.btnText}</Detail> */}
          </Row>
          <ConfirmDialog
            open={open}
            functionButtonColor={theme.palette.primary.success}
            functionButtonText={"Igen"}
            onClose={() => {
              setOpen(false);
            }}
            multipleButtons
            closeButtonText={"Nem"}
            title={"Biztos hogy létrehozod az üzenetet ezekkel az adatokkal?"}
            onOk={() => {
              createNotification(newNotification);
              setCreate(false);
            }}
          />
        </Paper>
      </Grid>
    </Zoom>
  );
};

export default NewNotificationCard;
