import { Dialog } from '@material-ui/core';
import GroupDetails from 'components/PhotoAdminComponents/Group/GroupDetails/GroupDetails';
import React from 'react';

const NewGroupDialog = ({
  shopId,
  createModal,
  editGroupModalRef,
  transition,
  toggleCreateModal,
  parentGroup,
  refresh,
  parent
}) => {
  return (
    <Dialog
      open={createModal}
      maxWidth={false}
      ref={editGroupModalRef}
      TransitionComponent={transition}
      keepMounted
      onClose={toggleCreateModal}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'>
      <GroupDetails
        shopId={shopId}
        createModal={true}
        currentGroup={null}
        isSchool={false}
        belongsTo={parentGroup}
        parentHeader={parent}
        toggle={toggleCreateModal}
        refresh={refresh}
      />
    </Dialog>
  );
};

export default NewGroupDialog;
