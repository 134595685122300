import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  desktopTitle: {
    fontSize: "2rem",
    padding: "0 1rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  addBtn: {
    width: "50%",
  },
}));

export const DocumentDownload = styled.a`
  margin-left: auto;
  color: ${({ theme }) => theme.palette.primary.backgroundSecondary};
  cursor: pointer;
`;
