import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`;

export const useStyles = makeStyles((theme) => ({
  successCheckCircle: {
    strokeDasharray: "166",
    strokeDashoffset: "166",
    strokeWidth: "2",
    strokeMiterlimit: "10",
    stroke: "#7ac142",
    fill: "none",
    animation: "stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards",
  },
  successCheck: {
    position: "relative",
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    display: "block",
    strokeWidth: "2",
    stroke: "#fff",
    strokeMiterlimit: "5",
    margin: "10% auto",
    boxShadow: "inset 0px 0px 0px #7ac142",
    animation:
      "$fill .4s ease-in-out .4s forwards, $scale .3s ease-in-out .9s both",
  },
  succcesCheckCheck: {
    transformOrigin: "50% 50%",
    strokeDasharray: "40",
    strokeDashoffset: "40",
    animation: "$stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards",
  },
  "@keyframes stroke": {
    "100%": {
      strokeDashoffset: "0",
    },
  },
  "@keyframes scale": {
    "100%": {
      transform: "none",
    },
    "50%": {
      transform: "scale3d(1.1, 1.1, 0.5)",
    },
  },
  "@keyframes fill": {
    "100%": {
      boxShadow: `inset 0px 0px 0px 25px #7ac142`,
    },
  },
}));
