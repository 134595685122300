import { TablePagination, useTheme } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useStyles } from "./styles";

const PaginationEditor = ({ options, setOptions, ordersLength, siteType }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  // &size=10&page=2

  const getOptions = () => {
    let options = [5, 10, 25, 50, 100, 200, 500, 1000];
    options.push(parseInt(ordersLength));
    return [...options.sort((a, b) => a - b)];
  };

  const [currentPage, setCurrentPage] = useState(
    parseInt(
      options?.find((o) => o.name === "pagination").value === ""
        ? 1
        : options
            ?.find((o) => o.name === "pagination")
            ?.value.split("&")[1]
            ?.split("=")[1]
    )
  );

  const [currentPageSize, setCurrentPageSize] = useState(
    parseInt(
      options?.find((o) => o.name === "pagination").value === ""
        ? 10
        : options
            ?.find((o) => o.name === "pagination")
            ?.value.split("&")[0]
            ?.split("=")[1]
    )
  );

  const handleChangePage = (e, newPage) => {
    setCurrentPage(newPage + 1);
    setOptions(
      options?.map((o) => {
        if (o.name === "pagination") {
          return {
            ...o,
            value:
              siteType === "reporting"
                ? `pageSize=${currentPageSize}&pageNumber=${newPage + 1}`
                : `size=${currentPageSize}&page=${newPage + 1}`,
          };
        } else return o;
      })
    );
  };

  const handleChangePageSize = (e) => {
    setCurrentPageSize(e.target.value + 1);
    setOptions(
      options?.map((o) => {
        if (o.name === "pagination") {
          return {
            ...o,
            value:
              siteType === "reporting"
                ? `pageSize=${e.target.value}&pageNumber=${1}`
                : `size=${e.target.value}&page=${1}`,
          };
        } else return o;
      })
    );
  };

  useEffect(() => {
    setCurrentPageSize(
      parseInt(
        options?.find((o) => o.name === "pagination").value === ""
          ? 10
          : options
              ?.find((o) => o.name === "pagination")
              ?.value.split("&")[0]
              ?.split("=")[1]
      )
    );
    setCurrentPage(
      parseInt(
        options?.find((o) => o.name === "pagination").value === ""
          ? 1
          : options
              ?.find((o) => o.name === "pagination")
              ?.value.split("&")[1]
              ?.split("=")[1]
      )
    );
  }, [options]);

  return (
    <div className={classes.root}>
      <TablePagination
        className={classes.showXs}
        disabled={ordersLength <= 10 || ordersLength === undefined}
        component="div"
        count={ordersLength}
        page={currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPage={currentPageSize}
        labelRowsPerPage={
          siteType === "printer" || siteType === "group"
            ? "Galéria egy oldalon: "
            : siteType === "reporting"
            ? "Jelentés egy oldalon: "
            : "Rendelés egy oldalon: "
        }
        rowsPerPageOptions={[]}
        onRowsPerPageChange={handleChangePageSize}
      />
      <TablePagination
        className={classes.hideXs}
        disabled={ordersLength <= 10 || ordersLength === undefined}
        component="div"
        count={ordersLength}
        page={currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPage={currentPageSize}
        labelRowsPerPage={
          siteType === "printer" || siteType === "group"
            ? "Galéria egy oldalon: "
            : siteType === "reporting"
            ? "Jelentés egy oldalon: "
            : "Rendelés egy oldalon: "
        }
        rowsPerPageOptions={getOptions()}
        onRowsPerPageChange={handleChangePageSize}
      />
    </div>
  );
};

export default PaginationEditor;
