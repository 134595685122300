import { MenuItem, Select, useTheme } from "@material-ui/core";
import React, { useState } from "react";

import { SelectWrapper } from "./styles";

const SelectEditor = ({ option, setOption }) => {
  const theme = useTheme();

  const [currentOption, setCurrentOption] = useState(
    option.value === "" ? -1 : option.value.split("=")[1]
  );
  return (
    <SelectWrapper>
      {option.values.length > 0 ? (
        <Select
          required
          variant="outlined"
          onChange={(e) => {
            setOption({ ...option, value: `${option.name}=${e.target.value}` });
            setCurrentOption(e.target.value);
          }}
          value={currentOption}
          fullWidth
          style={{
            fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
          }}
        >
          <MenuItem
            fullWidth
            key={`default_template_option`}
            value={-1}
            disabled
            style={{
              fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
            }}
          >
            Kérem válasszon...
          </MenuItem>
          {option.values?.map((value, idx) => (
            <MenuItem
              fullWidth
              key={`${option.name}_option`}
              value={value}
              style={{
                fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
              }}
            >
              {option.displayedValues[idx]}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select variant="outlined" defaultValue={-1} fullWidth>
          <MenuItem fullWidth key={`no_items`} value={-1} disabled>
            Nincs megjeleníthető elem
          </MenuItem>
        </Select>
      )}
    </SelectWrapper>
  );
};

export default SelectEditor;
