import { getApi } from "api/axios";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { convertBlobToBase64 } from "utils/ImageUploadRequest";

export const useOrderAPI = () => {
  const [orders, setOrders] = useState();
  const [ordersLength, setOrdersLength] = useState(0);
  const [loading, setLoading] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const carts = [];
  const shopId = useSelector(
    (state) => state.nonPersistedReducers.shopSliceReducer.shopId
  );

  const fetchOrders = useCallback(
    async ({ queryString }) => {
      setLoading(true);
      try {
        setOrders([]);
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
        ).get(`admin/shops/${shopId}/orders${queryString}`);
        if (res.status === 200) {
          setLoading(false);
          const orders = res.data._embedded.orderDTOList.map((order) => ({
            ...order,
            selected: false,
          }));
          setOrders(orders);
          setOrdersLength(res.data.page.totalElements);
        }
      } catch (e) {
        setLoading(false);
        setErrorMessage(e);
        console.log(e);
      }
    },
    [shopId]
  );

  const [order, setOrder] = useState(null);

  const fetchOrder = useCallback(
    async ({ id }) => {
      setOrder(null);
      try {
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
        ).get(`/admin/shops/${shopId}/orders/${id}`);

        setOrder(res.data);
      } catch (e) {
        setErrorMessage(e);
        console.log(e);
      }
    },
    [shopId]
  );

  const putOrder = useCallback(
    async ({ body }) => {
      try {
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
        ).put(`/admin/shops/${shopId}/orders`, body);
        setOrder(res.data);
      } catch (e) {
        setErrorMessage(e);
        console.log(e);
      }
    },
    [shopId]
  );

  const postOrderVerified = useCallback(
    async ({ orderBody }) => {
      try {
        await (
          await getApi({ baseUrFl: window._env_.REACT_APP_QDAK_ORDER_URL })
        ).put(`/admin/shops/${shopId}/orders`, {
          ...orderBody,
          status: "VERIFIED",
        });
      } catch (e) {
        console.log(e);
      }
    },
    [shopId]
  );

  const [cart, setCart] = useState();

  const fetchCart = useCallback(
    async ({ id }) => {
      // setLoading(true);
      try {
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
        ).get(`/carts/${id}`);
        if (res.status === 200) {
          // setLoading(false);
          setCart(res.data);
          carts.push(res.data);
        }
      } catch (e) {
        setErrorMessage(e);
        console.log(e);
      }
    },
    [shopId]
  );

  const deleteOrder = useCallback(
    async ({ id }) => {
      try {
        await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
        ).delete(`/admin/shops/${shopId}/orders/${id}`);
      } catch (e) {
        setErrorMessage(e);
        console.log(e);
      }
    },
    [shopId]
  );
  const [productImage, setProductImage] = useState();

  const fetchProductImage = useCallback(
    async ({ id }) => {
      try {
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
        ).get(`/admin/shops/${shopId}/products/${id}/thumbnail`, {
          responseType: "blob",
        });
        let image = await convertBlobToBase64(await res.data);
        setProductImage(image);
      } catch (e) {
        setErrorMessage(e);
        console.log(e);
      }
    },
    [shopId]
  );

  return {
    fetchOrders,
    orders,
    setOrders,
    ordersLength,
    loading,
    fetchOrder,
    order,
    deleteOrder,
    fetchCart,
    cart,
    carts,
    putOrder,
    fetchProductImage,
    productImage,
    errorMessage,
    postOrderVerified,
  };
};
