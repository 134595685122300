import { useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  ControlBtnContainer,
  IOSSwitch,
  NameContainer,
  OverLay,
  Row,
  Title,
  useStyles,
} from "./styles";
import {
  Button,
  Checkbox,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  Zoom,
} from "@material-ui/core";

import { useState, useEffect } from "react";
import { useNotificationsApi } from "./hooks/useNotificationsApi";
import LoadIndicator from "components/common/LoadIndicator/LoadIndicator";
import NewNotificationCard from "components/SuperAdminComponents/NewNotificationCard/NewNotificationCard";
import AddNewNotificationCard from "components/SuperAdminComponents/AddNewNotificationCard/AddNewNotificationCard";
import sizes from "constants/sizes";

const Notifications = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [create, setCreate] = useState(false);
  const [extNotifications, setExtNotifications] = useState();
  const [extInactiveNotifications, setExtInactiveNotifications] = useState();

  const desktop = useMediaQuery(`(min-width:${sizes.MD.toString()}px)`);

  const {
    loading,
    fetchActiveNotifications,
    fetchInactiveNotifications,
    activeNotifications,
    inactiveNotifications,
    fetchRequiredActions,
    requiredActions,
    enableNotification,
    disableNotification,
    postNotification,
    patchMessage,
  } = useNotificationsApi();

  const handleEdit = (id) => {
    const newNotifications = extNotifications.map((notification) => {
      if (notification.id === id) {
        return { ...notification, edit: !notification.edit };
      }
      return notification;
    });
    setExtNotifications(newNotifications);
  };

  const handleMessageChange = (e, id) => {
    const newNotifications = extNotifications.map((notification) => {
      if (notification.id === id) {
        return { ...notification, message: e.target.value };
      }
      return notification;
    });
    setExtNotifications(newNotifications);
  };
  const handleIsPublicChange = (e, id) => {
    const newNotifications = extNotifications.map((notification) => {
      if (notification.id === id) {
        return { ...notification, isPublic: !notification?.isPublic };
      }
      return notification;
    });
    setExtNotifications(newNotifications);
  };
  /*const handleIsActiveChange = (e, id) => {
    const newNotifications = extNotifications.map((notification) => {
      if (notification.id === id) {
        return { ...notification, isActive: !notification?.isActive };
      }
      return notification;
    });
    setExtNotifications(newNotifications);
  };

  const handleRequiredActionsChange = (e, id) => {
    const newNotifications = extNotifications.map((notification) => {
      if (notification.id === id) {
        return { ...notification, requiredActions: [e.target.value] };
      }
      return notification;
    });
    setExtNotifications(newNotifications);
  };*/

  useEffect(() => {
    if (activeNotifications) {
      const newNotifications = activeNotifications?.map((notification) => {
        return { ...notification, edit: false };
      });
      setExtNotifications(newNotifications);
    }
  }, [activeNotifications]);
  useEffect(() => {
    if (inactiveNotifications) {
      const newNotifications = inactiveNotifications?.map((notification) => {
        return { ...notification, edit: false };
      });
      setExtInactiveNotifications(newNotifications);
    }
  }, [inactiveNotifications]);

  useEffect(() => {
    fetchActiveNotifications();
    fetchInactiveNotifications();
    fetchRequiredActions();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
          <Typography color="inherit" variant="body1">
            Tájékoztató üzenetek
          </Typography>
        </Breadcrumbs>
        <div>
          <CssBaseline />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container className={classes.container} maxWidth={"xl"}>
              {loading ? (
                <LoadIndicator />
              ) : (
                <>
                  <Typography className={classes.sectionTitle}>
                    Aktív üzenetek
                  </Typography>
                  <Grid
                    container
                    spacing={3}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {extNotifications?.map((notification, idx) => {
                      return (
                        <Zoom in={true} timeout={700} key={idx}>
                          <Grid item xs={12} md={6}>
                            <Paper className={classes.paper}>
                              <NameContainer>
                                {" "}
                                <Typography className={classes.title}>
                                  {notification?.description}
                                </Typography>
                              </NameContainer>
                              <ControlBtnContainer>
                                {/* <Button
                                onClick={() => {
                                  notification?.isActive
                                    ? disableNotification(notification?.id)
                                    : enableNotification(notification?.id);
                                }}
                                style={{
                                  display: notification?.edit && "none",
                                  color: theme.palette.primary.textPrimary,
                                }}
                              >
                                <EditIcon
                                  style={{
                                    color: !notification?.isActive
                                      ? theme.palette.primary.success
                                      : theme.palette.primary.error,
                                  }}
                                />
                                {desktop && notification?.isActive
                                  ? "Inaktiválás"
                                  : desktop && !notification?.isActive
                                  ? "Aktiválás"
                                  : ""}
                              </Button> */}
                                <Button
                                  onClick={() => {
                                    handleEdit(notification?.id);
                                  }}
                                  style={{
                                    display: notification?.edit && "none",
                                    color: theme.palette.primary.textPrimary,
                                  }}
                                >
                                  <EditIcon />
                                  {desktop && "Szerkesztés"}
                                </Button>
                                <Tooltip title={"Üzenet mentése"}>
                                  <Button
                                    onClick={() => {
                                      handleEdit(notification?.id);
                                      const newNotification = notification;
                                      delete newNotification.edit;
                                      patchMessage({
                                        id: newNotification?.id,
                                        message: { ...newNotification },
                                      });
                                    }}
                                    style={{
                                      display: !notification?.edit && "none",
                                      color: theme.palette.primary.textPrimary,
                                    }}
                                  >
                                    <DoneIcon />
                                  </Button>
                                </Tooltip>
                                <Tooltip title={"Szerkesztés bezárása"}>
                                  <Button
                                    onClick={() => {
                                      handleEdit(notification?.id);
                                    }}
                                    style={{
                                      display: !notification?.edit && "none",
                                      color: theme.palette.primary.textPrimary,
                                    }}
                                  >
                                    <CancelIcon />
                                  </Button>
                                </Tooltip>

                                <IOSSwitch
                                  theme={theme}
                                  className={classes.switch}
                                  sx={{ m: 1 }}
                                  onChange={() => {
                                    notification?.isActive
                                      ? disableNotification(notification?.id)
                                      : enableNotification(notification?.id);
                                  }}
                                  checked={notification?.isActive}
                                />
                              </ControlBtnContainer>
                              <div style={{ position: "relative" }}>
                                <Row>
                                  <Title>Üzenet:</Title>
                                  <TextField
                                    className={classes.detail}
                                    disabled={!notification?.edit}
                                    onChange={(e) => {
                                      handleMessageChange(e, notification?.id);
                                    }}
                                    value={notification?.message}
                                  />
                                </Row>
                                {/* <Row>
                                  <Title>Leírás:</Title>
                                  <TextField
                                    className={classes.detail}
                                    disabled={!notification?.edit}
                                    onChange={(e) => {
                                      handleMessageChange(e, notification?.id);
                                    }}
                                    value={notification?.description}
                                  />                            
                                </Row> */}
                                <Row>
                                  <Title>Vásárlói üzenet?</Title>
                                  <Checkbox
                                    className={classes.check}
                                    multiline={true}
                                    color="primary"
                                    checked={notification?.isPublic}
                                    disabled
                                    onChange={(e) => {
                                      handleIsPublicChange(e, notification?.id);
                                    }}
                                    value={notification?.isPublic}
                                  />
                                  {/* <Detail>{notification?.mainDesc}</Detail> */}
                                </Row>

                                {/* <Row>
                                  <Title>Aktív?</Title>
                                  <Checkbox
                                    className={classes.check}
                                    multiline={true}
                                    color="primary"
                                    disabled={!notification?.edit}
                                    checked={notification?.isActive}
                                    onChange={(e) =>
                                      handleIsActiveChange(e, notification?.id)
                                    }
                                    value={notification?.isActive}
                                  />
                                  
                                </Row> */}
                                <Row>
                                  <Title>Szükséges műveletek:</Title>
                                  <Typography
                                    style={{ color: "rgb(144,144,144)" }}
                                    className={classes.detail}
                                  >
                                    {notification?.requiredActions[0]
                                      ? notification?.requiredActions?.map(
                                          (action) => action.action
                                        )
                                      : "Nincs szükséges művelet"}
                                  </Typography>

                                  {/* <Detail>{notification?.btnText}</Detail> */}
                                </Row>
                                {!notification?.isActive && <OverLay />}
                              </div>
                            </Paper>
                          </Grid>
                        </Zoom>
                      );
                    })}

                    {create ? (
                      <NewNotificationCard
                        setCreate={setCreate}
                        createNotification={postNotification}
                        requiredActions={requiredActions}
                        // Real request can be engaged by the function below
                        // createNotification={ postNotification}
                      />
                    ) : (
                      <AddNewNotificationCard setCreate={setCreate} />
                    )}
                  </Grid>
                  <Typography className={classes.sectionTitle}>
                    Inaktív üzenetek
                  </Typography>
                  <Grid
                    container
                    spacing={3}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {extInactiveNotifications?.map((notification, idx) => {
                      return (
                        <Zoom in={true} timeout={700} key={idx}>
                          <Grid item xs={12} md={6}>
                            <Paper className={classes.paper}>
                              <NameContainer>
                                {" "}
                                <Typography className={classes.title}>
                                  {notification?.message}
                                </Typography>
                              </NameContainer>
                              <ControlBtnContainer>
                                {/* <Button
                                onClick={() => {
                                  notification?.isActive
                                    ? disableNotification(notification?.id)
                                    : enableNotification(notification?.id);
                                }}
                                style={{
                                  display: notification?.edit && "none",
                                  color: theme.palette.primary.textPrimary,
                                }}
                              >
                                <EditIcon
                                  style={{
                                    color: !notification?.isActive
                                      ? theme.palette.primary.success
                                      : theme.palette.primary.error,
                                  }}
                                />
                                {desktop && notification?.isActive
                                  ? "Inaktiválás"
                                  : desktop && !notification?.isActive
                                  ? "Aktiválás"
                                  : ""}
                              </Button> */}
                                <Button
                                  onClick={() => {
                                    handleEdit(notification?.id);
                                  }}
                                  style={{
                                    display: notification?.edit && "none",
                                    color: theme.palette.primary.textPrimary,
                                  }}
                                >
                                  <EditIcon />
                                  {desktop && "Szerkesztés"}
                                </Button>
                                <Tooltip title={"Üzenet mentése"}>
                                  <Button
                                    onClick={() => {
                                      handleEdit(notification?.id);
                                    }}
                                    style={{
                                      display: !notification?.edit && "none",
                                      color: theme.palette.primary.textPrimary,
                                    }}
                                  >
                                    <DoneIcon />
                                  </Button>
                                </Tooltip>
                                <Tooltip title={"Szerkesztés bezárása"}>
                                  <Button
                                    onClick={() => {
                                      handleEdit(notification?.id);
                                    }}
                                    style={{
                                      display: !notification?.edit && "none",
                                      color: theme.palette.primary.textPrimary,
                                    }}
                                  >
                                    <CancelIcon />
                                  </Button>
                                </Tooltip>

                                <IOSSwitch
                                  theme={theme}
                                  className={classes.switch}
                                  sx={{ m: 1 }}
                                  onChange={() => {
                                    notification?.isActive
                                      ? disableNotification(notification?.id)
                                      : enableNotification(notification?.id);
                                  }}
                                  checked={notification?.isActive}
                                />
                              </ControlBtnContainer>
                              <div style={{ position: "relative" }}>
                                <Row>
                                  <Title>Üzenet:</Title>
                                  <TextField
                                    className={classes.detail}
                                    disabled={!notification?.edit}
                                    onChange={(e) => {
                                      handleMessageChange(e, notification?.id);
                                    }}
                                    value={notification?.description}
                                  />
                                </Row>
                                {/* <Row>
                                  <Title>Leírás:</Title>
                                  <TextField
                                    className={classes.detail}
                                    disabled={!notification?.edit}
                                    onChange={(e) => {
                                      handleMessageChange(e, notification?.id);
                                    }}
                                    value={notification?.description}
                                  />                            
                                </Row> */}

                                <Row>
                                  <Title>Vásárlói üzenet?</Title>
                                  <Checkbox
                                    className={classes.check}
                                    multiline={true}
                                    color="primary"
                                    checked={notification?.isPublic}
                                    disabled
                                    onChange={(e) => {
                                      handleIsPublicChange(e, notification?.id);
                                    }}
                                    value={notification?.isPublic}
                                  />
                                  {/* <Detail>{notification?.mainDesc}</Detail> */}
                                </Row>

                                {/* <Row>
                                  <Title>Aktív?</Title>
                                  <Checkbox
                                    className={classes.check}
                                    multiline={true}
                                    color="primary"
                                    disabled={!notification?.edit}
                                    checked={notification?.isActive}
                                    onChange={(e) =>
                                      handleIsActiveChange(e, notification?.id)
                                    }
                                    value={notification?.isActive}
                                  />
                                  
                                </Row> */}
                                <Row>
                                  <Title>Szükséges műveletek:</Title>
                                  <Typography
                                    style={{ color: "rgb(144,144,144)" }}
                                    className={classes.detail}
                                  >
                                    {notification?.requiredActions[0]
                                      ? notification?.requiredActions?.map(
                                          (action) => action.action
                                        )
                                      : "Nincs szükséges művelet"}
                                  </Typography>

                                  {/* <Detail>{notification?.btnText}</Detail> */}
                                </Row>
                                {!notification?.isActive && <OverLay />}
                              </div>
                            </Paper>
                          </Grid>
                        </Zoom>
                      );
                    })}
                  </Grid>
                </>
              )}
            </Container>
            {/* <ConfirmDialog
            open={open}
            onClose={() => setOpen(false)}
            onOk={() => {
              handleStatus(user);
              setOpen(false);
            }}
            title={
              user?.active
                ? "Biztosan deaktiválni akarod a fiókot?"
                : "Biztosan aktiválni akarod a fiókot?"
            }
            closeButtonText={"Nem"}
            functionButtonText={"Igen"}
            functionButtonColor={theme.palette.primary.success}
            multipleButtons={true}
          /> */}
          </main>
        </div>
      </div>
    </>
  );
};

export default Notifications;
