import { createSlice } from "@reduxjs/toolkit";

export const formulaSlice = createSlice({
  name: "formulas",
  initialState: {
    cFormula: "",
    cIFormula: "",
    mFormula: "",
  },
  reducers: {
    setFormulas: (state, action) => {
      state.cFormula = action.payload.cFormula;
      state.cIFormula = action.payload.cIFormula;
      state.mFormula = action.payload.mFormula;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFormulas } = formulaSlice.actions;

export default formulaSlice.reducer;
