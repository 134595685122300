import { createSlice } from '@reduxjs/toolkit';

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    alert: '',
  },
  reducers: {
    setAlert: (state, action) => {
      state.alert = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAlert } = alertSlice.actions;

export default alertSlice.reducer;
