import { getApi } from "api/axios";
import { useState } from "react";

export const useDocumentApi = () => {
  const [loading, setLoading] = useState();
  const [documents, setDocuments] = useState();

  const getDocuments = async () => {
    try {
      setLoading(true);
      const { data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/admin/users/current/contracts`);
      setDocuments(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const downloadDocument = async (contractId, linkRef, name) => {
    try {
      setLoading(true);
      const { data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/admin/users/current/contracts/${contractId}`, {
        headers: {
          "Content-Type": "application/pdf",
        },
        responseType: "blob",
      });
      setLoading(null);
      const href = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" })
      );
      setLoading(false);
      const a = linkRef.current;
      a.download = `${name}.pdf`;
      a.href = href;
      a.click();
      a.href = "";
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  return {
    getDocuments,
    downloadDocument,
    loading,
    documents,
  };
};
