import * as yup from "yup";

const phoneChangeValidationSchema = yup.object({
  phone: yup
    .string("Adja meg a telefonszámát")
    .matches(/^[0-9]{8}|[0-9]{9}$/, "Helyes formátum: 301234567 / 30123456")
    .max(9, "Nem lehet hosszabb 9 karakternél")
    .required("Szükséges mező"),
});

export default phoneChangeValidationSchema;
