import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  icon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export const ButtonOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 5px;
  z-index: 100;
  opacity: 0;
  transition: opacity 300ms;
  background: ${({ theme }) => theme.palette.primary.success};
  &: hover {
    opacity: 0.8;
  }
`;
