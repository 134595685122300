/** @format */

import * as yup from "yup";

const validationSchema = yup.object({
    name: yup.string("Adja meg az integráció nevét").required("Szükséges mező"),
    isActive: yup.boolean().required(),
    statusUrl: yup.string(),
    vendorType: yup
        .string("Adja meg az integráció típusát")
        .required("Szükséges mező"),
    vat: yup.string("Adja meg az Áfa kulcsot").required("Szükséges mező"),
    shopId: yup.string("").required("Szükséges mező"),
    // companyName: yup.string('Adja meg cége nevét').required('Szükséges mező'),
    vatNumber: yup
        .string("Adja meg adószámát")
        .matches(
            /^[0-9]{8}[-][0-9][-][0-9]{2}$/,
            "Helyes formátum: 12345678-3-21"
        )
        .required("Szükséges mező"),
    country: yup.string("Adja meg országát").required("Szükséges mező"),
    city: yup.string("Adja meg városát").required("Szükséges mező"),
    zipcode: yup.string("Adja meg irányítószámát").required("Szükséges mező"),
    street: yup.string("Adja meg utcáját").required("Szükséges mező"),
    streetNo: yup.string("Adja meg házszámát").required("Szükséges mező"),
    bankName: yup.string("Adja meg bankja nevét").required("Szükséges mező"),
    accountNumber: yup
        .string("Adja meg bankszámlaszámát")
        .matches(
            /^[0-9]{8}[-][0-9]{8}[-][0-9]{8}$|^[0-9]{8}[-][0-9]{8}$|^[0-9]{16}$|^[0-9]{24}$/,
            "Helyes formátum: 12345678-12345678 / 12345678-12345678-12345678"
        )
        .max(
            26,
            "Helyes formátum: 12345678-12345678 / 12345678-12345678-12345678"
        )
        .required("Szükséges mező"),
    iban: yup.string("Adja meg IBAN számát").required("Szükséges mező"),
    clientId: yup
        .string("Adja meg a kívánt számlatömb előtagot")
        .required("Szükséges mező"),
    clientSecret: yup
        .string("Adja meg az integrációhoz kapott API kulcsot")
        .required("Szükséges mező"),
});

export default validationSchema;
