import { useEffect, useRef } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useTheme } from "@material-ui/core/styles";
import { useNavigate, Outlet } from "react-router-dom";
import { Divider, Paper, Tab, Tabs, Tooltip } from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import { useStyles } from "./styles";
import HeaderData from "config/HeaderData";
import { useSelector } from "react-redux";

const AdminMenu = ({ match }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();
  const uploadLoading = useSelector(
    (state) => state.nonPersistedReducers.uploadSliceReducer.loading
  );

  const shopId = useSelector(
    (state) => state.nonPersistedReducers?.shopSliceReducer?.shopId
  );

  const user = useSelector(
    (state) => state.nonPersistedReducers?.userSliceReducer?.currentUser
  );
  const tabsActions = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (tabsActions?.current) {
        tabsActions.current.updateIndicator();
      }
    }, 1000);
  }, [tabsActions]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grow in={true} timeout={1000}>
        <Paper className={classes.settingsWrapper}>
          <Tabs
            TabIndicatorProps={{
              style: { background: theme.palette.primary.main },
            }}
            action={tabsActions}
            orientation="vertical"
            variant="fullWidth"
            value={
              HeaderData.options.admin.find((option) =>
                window.location.pathname.toString().includes(option.to)
              )?.to || `${match?.url}/orders`
            }
            className={classes.tabs}
          >
            {HeaderData.options.admin.map((option, idx) =>
              option.to === "templates" &&
              user?.ownedShops[0]?.id !== shopId &&
              !user?.maintainedShops
                ?.find((shop) => shop.shop.id === shopId)
                ?.scopes?.includes("MANAGE_PRICING") &&
              !user?.maintainedShops
                ?.find((shop) => shop.shop.id === shopId)
                ?.scopes?.includes("VIEW_PRICING") ? (
                  <Tab
                    selected={window.location.pathname
                      .toString()
                      .includes(option.to)}
                    value={option.to}
                    disabled={option.disabled || uploadLoading}
                    style={{
                      minWidth: "50px",
                      minHeight: "36px",
                      height: "36px",
                    }}
                    onClick={() => navigate(`${option.to}`)}
                    icon={<Divider orientation="vertical" />}
                    label={`${option.name}`}
                    key={`${idx}_option_tab`}
                  />
              ) : option.to === "galleries" &&
                user?.ownedShops[0]?.id !== shopId &&
                !user?.maintainedShops
                  ?.find((shop) => shop.shop.id === shopId)
                  ?.scopes?.includes("VIEW_PRODUCTS") ? (
                    <Tab
                      selected={window.location.pathname
                        .toString()
                        .includes(option.to)}
                      value={option.to}
                      disabled={option.disabled || uploadLoading}
                      style={{
                        minWidth: "50px",
                        minHeight: "36px",
                        height: "36px",
                      }}
                      onClick={() => navigate(`${option.to}`)}
                      icon={<Divider orientation="vertical" />}
                      label={`${option.name}`}
                      key={`${idx}_option_tab`}
                    />
              ) : (
                <Tab
                  selected={window.location.pathname
                    .toString()
                    .includes(option.to)}
                  value={option.to}
                  disabled={option.disabled || uploadLoading}
                  style={{
                    minWidth: "50px",
                    minHeight: "36px",
                    height: "36px",
                  }}
                  onClick={() => navigate(`${option.to}`)}
                  icon={<Divider orientation="vertical" />}
                  label={`${option.name}`}
                  key={`${idx}_option_tab`}
                />
              ))
            }
          </Tabs>
          <Outlet />
        </Paper>
      </Grow>
    </div>
  );
};

export default AdminMenu;
