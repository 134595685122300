import { getApi } from "api/axios";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";

import { setAlert } from "redux/slices/AlertSlice";

const { useState } = require("react");

export const useModifierSettingsAPI = () => {
  const shopId = useSelector(
    (state) => state.nonPersistedReducers.shopSliceReducer.shopId
  );
  const dispatch = useDispatch();

  const getUriId = (segment) => {
    return +segment.match("mod#id:[0-9]*")[0]?.split(":")[1];
  };

  const [modifiers, setModifiers] = useState(null);
  const [modifiersLoading, setModifiersLoading] = useState(false);
  const [modifiersError, setModifiersError] = useState(null);
  const [unsetModis, setUnsetModis] = useState([]);

  const getModifiers = useCallback(async () => {
    try {
      setModifiersLoading(true);

      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/admin/shops/${shopId}/modifiers`);
      setModifiers(res.data);
      setModifiersLoading(false);
    } catch (error) {
      setModifiersLoading(false);
      setModifiersError(error);
    }
  }, [shopId]);

  const [invoiceVendors, setinvoiceVendors] = useState(null);
  const [invoiceVendorsLoading, setInvoiceVendorsLoading] = useState(false);
  const [invoiceVendorsError, setInvoiceVendorsError] = useState(null);

  const getInvoiceVendors = useCallback(async () => {
    try {
      setInvoiceVendorsLoading(true);
      setinvoiceVendors(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/admin/shops/${shopId}/invoices/vendors`);
      if (res.status === 200) {
        setInvoiceVendorsLoading(false);
        setinvoiceVendors(res.data);
      }
    } catch (e) {
      setInvoiceVendorsLoading(false);
      setInvoiceVendorsError(e);
    }
  }, [shopId]);

  const [modInvoiceVendors, setModInvoiceVendors] = useState(null);
  const [modInvoiceVendorsIDS, setModInvoiceVendorsIDS] = useState(null);
  const [modInvoiceVendorsLoading, setModInvoiceVendorsLoading] =
    useState(false);
  const [modInvoiceVendorsError, setModInvoiceVendorsError] = useState(null);

  const getModInvoiceVendors = useCallback(async () => {
    try {
      setModInvoiceVendorsLoading(true);
      setModInvoiceVendors(null);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/admin/shops/${shopId}/modifier-invoice-vendors`);
      if (res.status === 200) {
        setModInvoiceVendorsLoading(false);
        setModInvoiceVendors(res.data);
        setModInvoiceVendorsIDS(res.data.map((item) => item.modifierId));
      }
    } catch (e) {
      setInvoiceVendorsLoading(false);
      setModInvoiceVendorsError(e);
    }
  }, [shopId]);

  const postModInvoiceVendor = useCallback(
    async (body) => {
      setModInvoiceVendorsLoading(true);
      try {
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
        ).post(`/admin/shops/${shopId}/modifier-invoice-vendors`, body);
        getModInvoiceVendors();
        setModInvoiceVendorsLoading(false);
      } catch (e) {
        setModInvoiceVendorsLoading(false);
        console.log(e);
      }
    },
    [shopId]
  );

  const deleteModInvoiceVendor = useCallback(
    async (modifierInvoiceVendorId) => {
      setModInvoiceVendorsLoading(true);
      try {
        setModInvoiceVendors(null);
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
        ).delete(
          `/admin/shops/${shopId}/modifier-invoice-vendors/${modifierInvoiceVendorId}`
        );
        getModInvoiceVendors();
        setModInvoiceVendorsLoading(false);
      } catch (e) {
        setModInvoiceVendorsLoading(false);
        setModInvoiceVendorsError(e);
      }
    },
    [shopId]
  );

  useEffect(() => {
    if (modifiers && modInvoiceVendorsIDS) {
      setUnsetModis(
        modifiers?.filter(
          (el) =>
            !modInvoiceVendorsIDS?.includes(getUriId(el.modifierUriSegment)) &&
            el.priority !== 1
        )
      );
    }
  }, [modInvoiceVendorsIDS, modifiers]);

  useEffect(() => {
    if (unsetModis && unsetModis.length > 0) {
      dispatch(setAlert("set_modifiers"));
    } else {
      dispatch(setAlert(""));
    }
  }, [unsetModis]);

  return {
    getModifiers,
    getInvoiceVendors,
    getModInvoiceVendors,
    postModInvoiceVendor,
    deleteModInvoiceVendor,
    getUriId,
    modifiers,
    modifiersLoading,
    modifiersError,
    invoiceVendors,
    invoiceVendorsLoading,
    invoiceVendorsError,
    modInvoiceVendors,
    modInvoiceVendorsIDS,
    modInvoiceVendorsLoading,
    modInvoiceVendorsError,
    unsetModis,
  };
};
