import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InvoiceBtn = styled.a`
  text-decoration: none;
  font-weight: bold;
  position: absolute;
  right: 0;
  bottom: 7%;
  width: 150px;
  text-align: center;
`;
export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    minheight: "1000px",
    flexDirection: "column",
  },
  cardHolder: {
    position: "relative",
  },

  breadcrumb: {
    margin: "1rem 0",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    outline: "none !important",
    color: theme.palette.primary.backgroundSecondary,
  },

  retryBtn: {
    margin: "0 1rem",
    height: "50%",
    marginRight: "0",
    fontSize: "11px",
    fontWeight: 600,
    [theme.breakpoints.up("sm")]: {
      height: "100%",
    },
  },
  newEmail: {
    margin: "1rem",
  },
  resendConfirm: {
    margin: "0.5rem 1rem 1rem 1rem",
  },

  orderListWrapper: {
    marginBottom: "2rem",
  },

  stepper: {
    background: "transparent",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  stepperItems: {
    marginTop: "0.5rem",
    [theme.breakpoints.up("md")]: {
      marginTop: "0",
    },
  },
  currentPage: {
    color: "white",
    [theme.breakpoints.up("md")]: {
      color: theme.palette.primary.main,
    },
  },
  link: {
    cursor: "pointer",
  },
  invoicesCard: {
    padding: "1rem",
    marginBottom: "2rem"
  },
  listItemTable:{
    padding: "0 !important",
    tableLayout: "fixed",
    width: "100%",
    transition: "background 300ms",
    borderRadius: "0 0 4px 4px",
  },
  tableCell:{
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
  },
  listItem: {
    borderRadius: "3px",
    padding: "0.1rem 1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1rem",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 9px 0px rgba(0,0,0,0.1)"
  },
  redDot: {
    height: "25px",
    width: "25px",
    backgroundColor: "red",
    borderRadius: "50%",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white"
  },
  hideCell: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "revert",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  hideXSCell: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  showXSCell: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "revert",
    },
  }
}));
