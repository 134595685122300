import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@material-ui/core";
import huLocale from "date-fns/locale/hu";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTheme } from "@material-ui/core";

export const DatePickerField = ({
  name,
  className,
  minDate,
  maxDate,
  openTo,
  setDate,
  views,
  value,
  fullWidth,
}) => {
  const theme = useTheme();

  return (
    <LocalizationProvider locale={huLocale} dateAdapter={AdapterDateFns}>
      <DatePicker
        name={name}
        className={className}
        minDate={minDate ? new Date(minDate) : null}
        maxDate={maxDate ? new Date(maxDate) : null}
        openTo={openTo}
        views={views}
        value={value}
        mask=""
        onChange={(val) => {
          setDate(val);
        }}
        inputProps={{
          style: {
            color: "black",
            fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
          },
          placeholder: "yyyy. mm. dd.",
        }}
        fullWidth={fullWidth}
      />
    </LocalizationProvider>
  );
};
