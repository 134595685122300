import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import { useStyles } from "./styles";
import SettingsCard from "components/PhotoAdminComponents/Settings/Card/SettingsCard";
import ActionButton from "components/common/ActionButton/ActionButton";
import { Form, Formik } from "formik";
import { useRef } from "react";
import emailChangeValidationSchema from "validation/UserSettings/EmailChangeSchema";
import { CircularProgress } from "@material-ui/core";
import billingAddressChangeSchema from "validation/UserSettings/BillingAddressChangeSchema";
import PostalCodeFields from "../PostalCodeFields/PostalCodeFields";

const ChangeBillingAddressCard = ({
  user,
  putUserBillingAddress,
  inProgress,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const formikRef = useRef(null);

  const [billingAddressData, setBillingAddressData] = useState({
    city: "",
    zipCode: "",
    streetAndNumber: "",
    country: "",
  });

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.city = billingAddressData?.city;
      formikRef.current.values.country = billingAddressData?.country;
      formikRef.current.values.zipCode = billingAddressData?.zipCode;
      formikRef.current.values.streetAndNumber =
        billingAddressData?.streetAndNumber;
    }
  }, [billingAddressData, formikRef]);

  useEffect(() => {
    if (isEditing) {
      setBillingAddressData({
        city: "",
        zipCode: "",
        streetAndNumber: "",
        country: "Magyarország",
      });
    } else {
      setBillingAddressData({
        city: user?.billingInfo?.billingAddress?.city,
        country: user?.billingInfo?.billingAddress?.country,
        zipCode: user?.billingInfo?.billingAddress?.zipCode,
        streetAndNumber: user?.billingInfo?.billingAddress?.streetAndNumber,
      });
    }
  }, [isEditing, user]);

  return (
    <SettingsCard
      title={"Számlázási cím"}
      actionButton={
        <ActionButton
          icon={<Edit color="primary" />}
          clickHandler={toggleIsEditing}
        />
      }
    >
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          city: "",
          country: "Magyarország",
          zipCode: "",
          streetAndNumber: "",
        }}
        validationSchema={billingAddressChangeSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await putUserBillingAddress({
            ...user,
            billingInfo: {
              ...user.billingInfo,
              billingAddress: {
                ...user.billingInfo.billingAddress,
                city: values.city,
                zipCode: values.zipCode,
                country: values.country,
                streetAndNumber: values.streetAndNumber,
              },
            },
          });
          setIsEditing(false);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <PostalCodeFields
                data={billingAddressData}
                setData={setBillingAddressData}
                disabled={!isEditing}
                zipHelper={formikRef?.current?.touched?.zipCode}
                zipError={formikRef?.current?.errors?.zipCode}
                cityHelper={formikRef?.current?.touched?.city}
                citypError={formikRef?.current?.errors?.city}
              />

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  variant="outlined"
                  fullWidth
                  required
                  label={"Utca, házszám"}
                  id="streetAndNumber"
                  name="streetAndNumber"
                  type="text"
                  value={
                    isEditing
                      ? values.streetAndNumber
                      : billingAddressData?.streetAndNumber
                  }
                  onChange={handleChange}
                  error={
                    touched.streetAndNumber && Boolean(errors.streetAndNumber)
                  }
                  helperText={touched.streetAndNumber && errors.streetAndNumber}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled
                  variant="outlined"
                  fullWidth
                  required
                  label={"Ország"}
                  id="country"
                  name="country"
                  type="text"
                  value={values.country}
                  onChange={handleChange}
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              style={{
                margin: "2rem 0 0 0",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Grid item xs={12}>
                <Button
                  disabled={!isEditing}
                  type="submit"
                  fullWidth
                  onClick={handleSubmit}
                  variant="outlined"
                  className={classes.button}
                >
                  {inProgress === "billingAddress" ? (
                    <>
                      Folyamatban
                      <CircularProgress
                        style={{ marginLeft: "0.5rem" }}
                        size={24}
                      />
                    </>
                  ) : (
                    "Cím frissítése"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangeBillingAddressCard;
