/** @format */

import { getApi } from "api/axios";
import { useState } from "react";

export const useModificationLogic = () => {
    const [minimals, setMinimals] = useState();
    const [printingPrices, setPrintingPrices] = useState();
    const getCurrentModifiers = (currentGroup, availableModifiers) => {
        let currentModifiers = [...availableModifiers];
        let currentModifierIds = [];
        let avaialableModifierIds = [];
        if (currentGroup) {
            currentGroup?.modifiers?.forEach((m) => {
                currentModifierIds.push(m.id);
            });
            availableModifiers?.forEach((m) =>
                avaialableModifierIds.push(m.id)
            );
        }
        // For now, modifiers are enabled by default which could result in a strange behaviour (objects with null unit price)
        currentModifiers = currentModifiers?.map((modifier) => {
            let newModifier = { ...modifier };
            newModifier.enabled = false;
            // Prio 1 modifiers have no price - customer request
            newModifier.unitPrice = {
                grossAmount: 0,
                vatAmount: 0,
                netAmount: 0,
                taxRate: 0,
                currency: "HUF",
            };
            newModifier.validUntil = new Date(
                new Date().setUTCHours(23, 59, 59, 999)
            );
            return newModifier;
        });
        if (currentGroup === null) {
            // This is a create.
        } else {
            // This is an update.

            currentModifiers?.forEach((cm) => {
                if (
                    currentGroup?.modifiers?.find(
                        (cgm) =>
                            getUriId(cgm.modifierUriSegment) ===
                            getUriId(cm.modifierUriSegment)
                    )
                ) {
                    let foundModi = currentGroup?.modifiers?.find(
                        (cgm) =>
                            getUriId(cgm.modifierUriSegment) ===
                            getUriId(cm.modifierUriSegment)
                    );
                    currentModifiers = currentModifiers.filter(
                        (m) => m.id !== cm.id
                    );
                    currentModifiers.push({
                        ...cm,
                        enabled: foundModi.enabled,
                        validUntil:
                            currentGroup?.productGroupModifierValidities?.find(
                                (v) => v.modifierUriSegment === cm.modifierUriSegment
                            )?.validUntil,
                        unitPrice: { ...foundModi.unitPrice },
                    });
                    // currentModifiers = currentModifiers.map(
                    //   (m) =>
                    //     m.id === cm.id && {
                    //       ...m,
                    //       enabled: foundModi.enabled,
                    //       validUntil: foundModi.validUntil,
                    //       unitPrice: { ...foundModi.unitPrice },
                    //     }
                    // );
                }
            });

            // if(currentModifierIds?.sort()?.join(',') === avaialableModifierIds?.sort()?.join(',')){
            //   currentGroup?.modifiers?.forEach((currentGroupModifier) => {
            //     currentModifiers = currentModifiers?.filter(
            //       (m) => m.id !== currentGroupModifier.id
            //     );
            //     currentModifiers.push(currentGroupModifier);
            //   });
            // }
        }
        return currentModifiers;
    };

    const modiGroupComparator = (a, b) => {
        return a.priority < b.priority ||
            (a.priority === b.priority && a.name < b.name)
            ? -1
            : a.priority > b.priority ||
              (a.priority === b.priority && a.name >= b.name)
            ? 1
            : 0;
    };

    const getUriId = (segment) => {
        return +segment.match("mod#id:[0-9]*")[0]?.split(":")[1];
    };

    const separatePrios = (modifications) => {
        let curPrio =
            modifications?.length > 0 ? modifications[0]?.priority : 0;
        let separatedPrios = [];
        let curIdx = 0;
        modifications?.forEach((curModi) => {
            if (curModi.priority === curPrio) {
                if (separatedPrios[curIdx] == null) {
                    separatedPrios[curIdx] = [];
                }
                separatedPrios[curIdx].push(curModi);
            } else {
                // we have reached a modification which has a higher prio than current
                curPrio = curModi.priority;
                curIdx = curIdx + 1;
                if (separatedPrios[curIdx] == null) {
                    separatedPrios[curIdx] = [];
                }
                separatedPrios[curIdx].push(curModi);
            }
        });
        return separatedPrios;
    };

    const getModificationList = (modifiers) => {
        if (modifiers?.length === 0) return [];
        let prioSorted = modifiers?.sort(modiGroupComparator); // one array, sorted by prio
        let separatedPrioModis = separatePrios(prioSorted);

        let modifierList = [];
        separatedPrioModis[0].forEach((format) => {
            modifierList.push(format);
            separatedPrioModis[1].forEach((modifier) => {
                if (
                    format.compatibleWith.includes(
                        getUriId(modifier.modifierUriSegment)
                    )
                ) {
                    modifierList.push(modifier);
                }
            });
        });
        return modifierList;
    };
    const [inputDisabled, setInputDisabled] = useState(false);
    const setModification = async ({
        currentModifiers,
        newModificationId,
        value,
        setCurrentModifiers,
        grossAmount,
        taxRate,
        tax,
    }) => {
        let foundModification = currentModifiers?.find(
            (modification) => modification.id === newModificationId
        );
        let modificationsWithoutFoundModification = currentModifiers?.filter(
            (modification) => modification.id !== newModificationId
        );

        setInputDisabled(true);
        if (foundModification) {
            let updatedModification = {
                ...foundModification,
                unitPrice: {
                    //TaxRateComment0.3 When taxRate is not 0.00, it will be necessary to change the backend to accept netAmount from here and not grossAmount
                    grossAmount: tax ? grossAmount : value,
                    currency: "HUF",
                    //TaxRateComment0.2 And here!!!!
                    taxRate: tax ? value : taxRate,
                    netAmount: 0,
                    vatAmount: 0,
                },
            };

            if (foundModification.priority === 1) {
                // in this case the value is a boolean
                updatedModification["enabled"] = value;
                updatedModification["unitPrice"] = {
                    grossAmount: 0,
                    vatAmount: 0,
                    currency: "HUF",
                    taxRate: 0,
                };
            }
            setCurrentModifiers([
                ...modificationsWithoutFoundModification,
                updatedModification,
            ]);
        }

        setInputDisabled(false);
    };

    const getModificationEnabled = (id, currentModifiers) => {
        let foundModification = currentModifiers?.find(
            (modification) => modification.id === id
        );
        return foundModification?.enabled || false;
    };

    const findAvailableCompatibles = (compatibles, modifiers) => {
        let found = false;
        compatibles.forEach((comp) =>
            modifiers.find(
                (modifier) =>
                    getUriId(modifier.modifierUriSegment) === comp &&
                    modifier.enabled === true
            )
                ? (found = true)
                : 0
        );
        return found;
    };

    const setModificationAvailable = async (
        newModificationId,
        modifierUriSegment,
        available,
        currentModifiers,
        setCurrentModifiers,
        priority
    ) => {
        const modifiedModifiers = await currentModifiers.map((modifier) => {
            if (priority === 1) {
                if (
                    modifier.id === newModificationId ||
                    modifier.compatibleWith.includes(
                        getUriId(modifierUriSegment)
                    )
                ) {
                    return { ...modifier, enabled: available };
                }
            } else {
                if (modifier.id === newModificationId) {
                    return { ...modifier, enabled: available };
                }
                if (
                    modifier.compatibleWith.includes(
                        getUriId(modifierUriSegment)
                    ) &&
                    available
                ) {
                    return { ...modifier, enabled: available };
                }
                return modifier;
            }

            return modifier;
        });

        const availableModifiers = modifiedModifiers.map((modifier) => {
            if (priority === 2) {
                if (
                    modifier.compatibleWith.includes(
                        getUriId(modifierUriSegment)
                    ) &&
                    !available
                ) {
                    //THIS IS NEEDED TO BE MODIFIED
                    if (
                        findAvailableCompatibles(
                            modifier.compatibleWith,
                            modifiedModifiers
                        )
                    ) {
                        return modifier;
                    } else {
                        return { ...modifier, enabled: false };
                    }
                }
                return modifier;
            }
            return modifier;
        });

        setCurrentModifiers(availableModifiers);
    };

    const setPriorityAvailable = (
        priority,
        available,
        currentModifiers,
        setCurrentModifiers
    ) => {
        let copy = currentModifiers?.map((m) => {
            let newM = { ...m };
            newM.enabled = available;
            if (available && !newM?.unitPrice?.netAmount) {
                newM.unitPrice = {
                    netAmount: 0,
                    vatAmount: 0,
                    grossAmount: 0,
                    currency: "HUF",
                    taxRate: 0,
                };
            }
            return newM;
        });

        setCurrentModifiers(copy);
    };

    const setFormatPrice = (
        compatibleWithList,
        value,
        currentModifiers,
        setCurrentModifiers
    ) => {
        const isEmpty = !!value === false;
        let copy = currentModifiers?.map((m) => {
            let mCopy = { ...m };
            if (
                compatibleWithList.includes(getUriId(mCopy.modifierUriSegment))
            ) {
                mCopy.unitPrice = {
                    grossAmount: isEmpty ? 0 : value,
                    currency: "HUF",
                    taxRate: 0,
                    netAmount: isEmpty ? 0 : value,
                    vatAmount: 0,
                };
            }
            return mCopy;
        });
        setCurrentModifiers(copy);
    };

    const getSetModificationValidUntil =
        (id, priority, compatibleWith, modifiers, setModifiers) => (value) => {
            let copy = modifiers.map((m) => {
                let mCopy = {
                    ...m,
                    visibleValidUntil: m.visibleValidUntil
                        ? m.visibleValidUntil
                        : null,
                };
                if (mCopy.id === id) {
                    let newDateValue = new Date(
                        value.setUTCHours(23, 59, 59, 999)
                    );
                    newDateValue.setUTCDate(newDateValue.getUTCDate() + 1);
                    mCopy.validUntil = newDateValue;
                    mCopy.visibleValidUntil = value;
                }
                if (priority === 1) {
                    if (
                        compatibleWith.includes(
                            getUriId(mCopy.modifierUriSegment)
                        )
                    ) {
                        let newDateValue = new Date(
                            value.setUTCHours(23, 59, 59, 999)
                        );
                        newDateValue.setUTCDate(newDateValue.getUTCDate() + 1);
                        mCopy.validUntil = newDateValue;
                        mCopy.visibleValidUntil = value;
                    }
                }
                return mCopy;
            });
            setModifiers(copy);
        };

    const getPrintingPrice = async () => {
        try {
            const res = await (
                await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
            ).get(`/admin/delivery-vendor-options/latest`);
            setPrintingPrices(res?.data);
        } catch (e) {
            console.log(e);
        }
    };

    const getMinimals = async (modifiers) => {
        try {
            const bodyArray = [];
            modifiers?.forEach((m) => {
                bodyArray.push({
                    modifierId: getUriId(m?.modifierUriSegment),
                    itemPrice: {
                        grossAmount: 0,
                        taxRate: 0,
                        currency: "HUF",
                    },
                    formulaParameters: {
                        minimalPrice: [
                            {
                                formulaKey: "printingPrice",
                                formulaValue: printingPrices?.find(
                                    (price) =>
                                        price.modId ===
                                            getUriId(m?.modifierUriSegment) &&
                                        price.deliveryType ===
                                            m?.deliveryTypeDTO
                                )?.price?.grossAmount,
                            },
                        ],
                    },
                });
            });
            const res = await (
                await getApi({
                    baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
                })
            ).post(`/admin/quotes/calculate/minimals`, bodyArray);

            setMinimals(res.data);
        } catch (e) {
            console.log(e);
        }
    };

    const handleSubmitClick = (submitButtonRef) => {
        submitButtonRef.current.click();
    };

    const getPrintedDate = (modifiers) => {
        const printedModifier = modifiers?.find(
            (modifier) => modifier.id === 1
        );
        if (printedModifier) {
            return new Date(printedModifier.validUntil);
        } else {
            return new Date();
        }
    };

    const getDigitalDate = (modifiers) => {
        const digitalModifier = modifiers?.find(
            (modifier) => modifier.id === 2
        );
        if (digitalModifier) {
            return new Date(digitalModifier.validUntil);
        } else {
            return new Date();
        }
    };

    const getPriorityAvailable = (modifiers, priority) => {
        return modifiers?.find((m) => m.priority === priority)?.enabled;
    };

    const clearModifiers = (modifiers) => {
        const newModis = modifiers.map((m) => {
            delete m.visibleValidUntil;
            delete m.validUntil;

            delete m.commission;
            delete m.total;
            if (!m.unitPrice) {
                m.unitPrice = {
                    netAmount: 0,
                    vatAmount: 0,
                    grossAmount: 0,
                    currency: "HUF",
                    taxRate: 0,
                };
            }
            if (m.unitPrice) return m;
        });
        return newModis;
    };

    return {
        getCurrentModifiers,
        modiGroupComparator,
        getModificationList,
        setModification,
        setModificationAvailable,
        setPriorityAvailable,
        setFormatPrice,
        getSetModificationValidUntil,
        handleSubmitClick,
        getPrintedDate,
        getDigitalDate,
        getModificationEnabled,
        getPriorityAvailable,
        getMinimals,
        minimals,
        clearModifiers,
        inputDisabled,
        getUriId,
        getPrintingPrice,
        printingPrices,
    };
};
