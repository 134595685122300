import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  CGName: {
    textOverflow: "ellipsis",
    maxWidth: "20ch",
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "white",
    [theme.breakpoints.up("md")]: {
      color: theme.palette.primary.main,
    },
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
  PGName: {
    cursor: "pointer",
    color: theme.palette.primary.backgroundSecondary,
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    textOverflow: "ellipsis",
    maxWidth: "20ch",
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

export const OuterWrapper = styled.div`
  width: 100%;
`;
