import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import { useStyles } from "./styles";
import SettingsCard from "components/PhotoAdminComponents/Settings/Card/SettingsCard";
import ActionButton from "components/common/ActionButton/ActionButton";
import { Form, Formik } from "formik";
import { useRef } from "react";
import companyNameChangeSchema from "validation/UserSettings/CompanyNameChangeSchema";
import { CircularProgress } from "@material-ui/core";

const ChangeBillingNameCard = ({ user, putUserBillingName, inProgress }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const formikRef = useRef(null);

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.companyName = user?.billingInfo?.companyName;
    }
  }, [user, formikRef]);

  return (
    <SettingsCard
      title={"Számlázási név"}
      actionButton={
        <ActionButton
          icon={<Edit color="primary" />}
          clickHandler={toggleIsEditing}
        />
      }
    >
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          companyName: "",
        }}
        validationSchema={companyNameChangeSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          //TODO Real billing first and last name will be needed instead of companyName
          await putUserBillingName({
            ...user,
            billingInfo: {
              ...user?.billingInfo,
              companyName: values.companyName,
            },
          });
          setIsEditing(false);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant="outlined"
                  fullWidth
                  required
                  label={"Számlázási név"}
                  id="companyName"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  error={touched.companyName && Boolean(errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                />
              </Grid>{" "}
            </Grid>
            <Grid
              item
              container
              style={{
                margin: "2rem 0 0 0",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Grid item xs={12}>
                <Button
                  disabled={!isEditing}
                  type="submit"
                  fullWidth
                  onClick={handleSubmit}
                  variant="outlined"
                  className={classes.button}
                >
                  {inProgress === "billingName" ? (
                    <>
                      Folyamatban
                      <CircularProgress
                        style={{ marginLeft: "0.5rem" }}
                        size={24}
                      />
                    </>
                  ) : (
                    "Név frissítése"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangeBillingNameCard;
