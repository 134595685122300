import AuthService from "services/AuthService";
import axios from "axios";

export const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};

export const coverUploadRequest =
  (group, refreshParent, setLoading) =>
  ({ uid, file, send, action, headers, onProgress, onSuccess, onError }) => {
    const form = new FormData();

    form.append("imageFile", file);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true);
    axios
      .post(action, form, {
        headers: {
          Authorization: AuthService.authHeader(),
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: ({ total, loaded }) => {
          onProgress(uid, Math.round((loaded / total) * 100));
        },
        cancelToken: source.token,
      })
      .then(({ data: response }) => {
        onSuccess(uid, response);
        refreshParent();
      })
      .catch((error) => {
        onError(uid, {
          action,
          status: error.request,
          response: error.response,
        });
      });

    return {
      abort() {
        source.cancel();
      },
    };
  };

export const imageUploadRequest =
  (group, refreshParent) =>
  ({ uid, file, send, action, headers, onProgress, onSuccess, onError }) => {
    const form = new FormData();

    const productDto = {
      name: file.name,
      groupId: group.id,
      isActive: false,
      modifiers: group.modifiers,
    };

    form.append("imageFile", file);
    form.append(
      "productDTO",
      new Blob([JSON.stringify(productDto)], {
        type: "application/json",
      })
    );

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios
      .post(action, form, {
        headers: {
          Authorization: AuthService.authHeader(),
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: ({ total, loaded }) => {
          onProgress(uid, Math.round((loaded / total) * 100));
        },
        cancelToken: source.token,
      })
      .then(({ data: response }) => {
        onSuccess(uid, response);
        refreshParent();
      })
      .catch((error) => {
        onError(uid, {
          action,
          status: error.request,
          response: error.response,
        });
      });

    return {
      abort() {
        source.cancel();
      },
    };
  };
