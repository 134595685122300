import * as yup from 'yup';

const emailChangeValidationSchema = yup.object({
  contactEmail: yup
    .string('Adja meg email címét')
    .email('Kérem adjon meg egy valós email címet')
    .required('Szükséges mező'),
  emailConfirm: yup
    .string('Erősítse meg email címét')
    .email('Kérem adjon meg egy valós email címet')
    .required('Szükséges mező')
    .when('contactEmail', (contactEmail) => {
      if (contactEmail) {
        return yup
          .string('Erősítse meg email címét')
          .email('Kérem adjon meg egy valós email címet')
          .required('Szükséges mező')
          .test('do-the-email-match', 'A két email cím nem egyezik!', function (emailConfirm) {
            return contactEmail === emailConfirm;
          });
      }
    })
});

export default emailChangeValidationSchema;
