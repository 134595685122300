import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0.1rem;
  padding: 0.25rem 0.5rem;

  border: 1px solid lightgrey;
  border-radius: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor};

  &:hover {
    cursor: pointer;
  }

  position: relative;
`;

export const EditorWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: max-content;
  background-color: white;
  z-index: 100;

  padding: 0.5rem;

  border: 1px solid lightgrey;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
`;
