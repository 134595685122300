import { useEffect, useRef } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useTheme } from "@material-ui/core/styles";
import {  Outlet, useNavigate } from "react-router-dom";
import { Divider, Paper, Tab, Tabs } from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import { useStyles } from "./styles";
import HeaderData from "config/HeaderData";

import { useSelector } from "react-redux";


const SuperAdminMenu = ({ match }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();
  const uploadLoading = useSelector(
    (state) => state.nonPersistedReducers.uploadSliceReducer.loading
  );

  const tabsActions = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (tabsActions?.current) {
        tabsActions.current.updateIndicator();
      }
    }, 1000);
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grow in={true} timeout={1000}>
        <Paper className={classes.settingsWrapper}>
          <Tabs
            TabIndicatorProps={{
              style: { background: theme.palette.primary.main },
            }}
            action={tabsActions}
            orientation="vertical"
            variant="fullWidth"
            value={
              HeaderData.options.superAdmin.find((option) =>
                window.location.pathname.toString().includes(option.to)
              )?.to || `${match?.url}/dashboard`
            }
            className={classes.tabs}
          >
            {HeaderData.options.superAdmin.map((option, idx) => (
              <Tab
                selected={window.location.pathname
                  .toString()
                  .includes(option.to)}
                value={option.to}
                disabled={option.disabled || uploadLoading}
                style={{ minWidth: "50px", minHeight: "36px", height: "36px" }}
                onClick={() => navigate(`${option.to}`)}
                icon={<Divider orientation="vertical" />}
                label={`${option.name}`}
                key={`${idx}_option_tab`}
              />
            ))}
          </Tabs>
          <Outlet />
        </Paper>
      </Grow>
    </div>
  );
};

export default SuperAdminMenu;
