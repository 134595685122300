import settlements from "constants/settlements.json";
import settlementNames from "constants/settlementNames.json";
import settlementsWithZip from "constants/settlementsWithZip.json";

export const usePostalCodeFields = () => {
  const calcAddress = (zip, city) => {
    if (parseInt(zip) > 1000 && parseInt(zip) < 1300) {
      return "Budapest";
    } else {
      if (city !== "") {
        return settlementsWithZip?.filter(
          (settlement) =>
            settlement.zip === zip &&
            settlement.city
              ?.toLocaleLowerCase()
              ?.includes(city?.toLocaleLowerCase())
        )[0]?.city;
      }
      return settlementsWithZip?.filter(
        (settlement) =>
          settlement.zip.toLocaleLowerCase() === zip.toLocaleLowerCase()
      )[0]?.city;
    }
  };

  const calcPostalCode = (city, zip) => {
    if (city === "Budapest") {
      return city;
    } else {
      return settlements[city.charAt(0).toUpperCase() + city.slice(1)];
    }
  };

  const calcAddressSuggestions = (letters) => {
    if (letters?.length < 3) return [];
    return settlementNames?.filter((name) => name?.includes(letters));
  };

  return {
    calcAddress,
    calcPostalCode,
    calcAddressSuggestions,
  };
};
