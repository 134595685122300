import { getApi } from "../axios";
import axios from "axios";

export default {
  async uploadRequest({ setLoading, file, onSuccess, onProgress, url }) {
    const form = new FormData();
    form.append("imageFile", file);
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
        auth: true,
      })
    )
      .post(url, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: ({ total, loaded }) => {
          onProgress(1, Math.round((loaded / total) * 100));
        },
        cancelToken: source.token,
      })
      .then(({ data: response }) => {
        onSuccess(1, response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  },
};
