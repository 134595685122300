import { getApi } from 'api/axios';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

export const usePaymentAPI = () => {
  const [payment, setPayment] = useState(null);
  const shopId = useSelector((state) => state.nonPersistedReducers.shopSliceReducer.shopId);

  const fetchPayment = useCallback(
    async ({ id }) => {
      try {
        const res = await (
          await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PAYMENT_URL })
        ).get(`/admin/shops/${shopId}/payments/${id}`);
        setPayment(res.data);
      } catch (e) {
        console.log(e);
      }
    },
    [shopId]
  );

  return { fetchPayment, payment };
};
