import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
export const useStyles = makeStyles((theme) => ({
  spinner: {
    margin: "3rem auto",
  },
}));

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: ${(props) => props.height}px;
`;
