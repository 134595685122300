import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import Zoom from '@material-ui/core/Zoom';

import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';

const AddGroupCard = ({ toggleCreateModal, isSchool }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Zoom in={true} timeout={1000}>
      <Card className={classes.cardStyle}>
        <CardActionArea
          className={classes.cardActionArea}
          onClick={() => {
            toggleCreateModal();
          }}>
          <AddCircleIcon
            style={{
              color: theme.palette.primary.backgroundSecondary,
              fontSize: '5rem'
            }}
          />
          <Typography className={classes.cardTitle}>
            {isSchool ? 'Iskola Hozzáadása' : ' Osztály Létrehozása'}
          </Typography>
        </CardActionArea>
      </Card>
    </Zoom>
  );
};

export default AddGroupCard;
