import { useEffect } from "react";
import AuthService from "services/AuthService";

const Shop = () => {
  useEffect(() => {
    if (!AuthService.isLoggedIn()) {
      AuthService.doLogin();
    }
  }, []);
 
  return (
    <></>
    
  );
};

export default Shop;
