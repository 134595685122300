import { useTheme } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useStyles } from "./styles";

const GroupNameEditor = ({ option, setOption, accountingData }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [selectedSchool, setSelectedSchool] = useState(null);
  const [schoolOptions, setSchoolOptions] = useState([]);

  useEffect(() => {
    const uniqueNames = Array.from(
      new Set(accountingData.map((entry) => entry.schoolName))
    );
    setSchoolOptions(uniqueNames);
    //setSchoolOptions(accountingData);
  }, [accountingData]);

  useEffect(() => {
    if (selectedSchool) {
      const newValue = `schoolName=${selectedSchool}`;
      if (decodeURI(option.value) !== decodeURI(newValue)) {
        setOption({ ...option, value: encodeURI(newValue) });
      }
    }
  }, [selectedSchool]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "0.5rem",
        fontFamily: theme.typography.main,
      }}
    >
      <Autocomplete
        disabled={!accountingData || accountingData?.length === 0}
        disablePortal
        disableClearable
        id="school-autocomplete"
        options={schoolOptions}
        getOptionLabel={(option) => option}
        sx={{ width: 300, fontFamily: theme.typography.main }}
        value={selectedSchool}
        className={classes.autocomplete}
        onChange={(event, selectedSchool) => {
          setSelectedSchool(selectedSchool);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontFamily: `${theme.typography.main}` },
            }}
            label="Iskola"
          />
        )}
      />
    </div>
  );
};

export default GroupNameEditor;
