export const useHeaderLogic = () => {
  const getCurrentHeader = (currentGroup, belongsTo, createModal) => {
    if (typeof currentGroup !== 'undefined' && currentGroup !== null) {
      let cgCopy = { ...currentGroup, belongsTo: belongsTo };
      delete cgCopy.modifiers;
      delete cgCopy.products;
      return cgCopy;
    } else {
      return {
        name: '',
        createdAt: null,
        isActive: false,
        inheritModifiers: createModal && belongsTo
        ? true : false,
        isSecured: false,
        accessKey: '',
        category: '',
        belongsTo: belongsTo,
        modifierTemplate: null
      };
    }
  };

  const getGroupType = (header) => {
    if (header.belongsTo) {
      if (header.name === 'Csoportképek') {
        return 'Csoportképek';
      } else {
        return 'Osztály';
      }
    } else {
      return 'Iskola';
    }
  };

  return {
    getCurrentHeader,
    getGroupType
  };
};
