import { Fragment, useState, useEffect } from "react";
import { useTheme } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import Container from "@material-ui/core/Container";
import { CircularProgress } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import background from "assets/images/test.jpg";
import GroupItem from "./GroupItem/GroupItem";
import {
  Transition,
  useStyles,
  NewGroupWrapper,
  GroupsWrapper,
  UpdateIndicator,
} from "./styles";
import { useGroupAPI } from "pages/PhotoAdmin/SelectShop/hooks/useGroupAPI";
import LoadIndicator from "components/common/LoadIndicator/LoadIndicator";
import GroupDetails from "components/PhotoAdminComponents/Group/GroupDetails/GroupDetails";
import AddGroupCard from "components/PhotoAdminComponents/Group/AddGroupCard/AddGroupCard";
import { useSelector } from "react-redux";
import QueryBuilder from "components/common/QueryBuilder/QueryBuilder";
import { useLocation, useNavigate } from "react-router-dom";

const Groups = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();
  const { search } = useLocation();
  const [showAll, setShowAll] = useState(false);

  const shopId = useSelector(
    (state) => state.nonPersistedReducers?.shopSliceReducer?.shopId
  );

  const { fetchGroupsNew, groupsLength, groups, groupLoading, loading, putGroup } =
    useGroupAPI();

  const [detailsOpen, setDetailsOpen] = useState(false);

  const toggleDetailsOpen = () => {
    setDetailsOpen(!detailsOpen);
  };

  useEffect(() => {
    if (shopId) {
      fetchGroupsNew({ queryString: '' });
    }
  }, [shopId]);

  const refresh = () => {
    fetchGroupsNew({ queryString: search });
  };
  useEffect(() => {
    refresh();
    let queryParams = new URLSearchParams(search);

    if (queryParams.get("size") !== groupsLength) {
      setShowAll(false);
    }
  }, [search]);
  const resetQueryString = () => {
    navigate(`/shops/${shopId}/administration/galleries`);
  };

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
        <Typography variant="body1">Iskolák</Typography>
      </Breadcrumbs>
      <CssBaseline />
      <Container maxWidth={"xl"} className={classes.container}>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <NewGroupWrapper>
            {/* <Button
              onClick={() => setDetailsOpen(true)}
              className={classes.newGroupBtn}
              variant="contained"
              component="button"
            >
              Iskola hozzáadása
            </Button> */}
            {loading && (
              <UpdateIndicator>
                Galériák frissítése folyamatban
                <CircularProgress
                  size={20}
                  style={{
                    color: theme.palette.primary.backgroundPrimary,
                    marginLeft: "0.5rem",
                  }}
                />
              </UpdateIndicator>
            )}
          </NewGroupWrapper>
          {/* <TablePagination
            hidden={groups?.length <= 10 || groups?.length === undefined}
            component="div"
            count={groups?.length}
            page={currentPage}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Iskolák egy oldalon:"}
          /> */}
          <QueryBuilder
                itemsLength={groupsLength}
                siteType={"group"}
                showAll={showAll}
                reset={resetQueryString}
                refresh={refresh}
              >
            <GroupsWrapper>
              {groupLoading ? (
                <LoadIndicator color={theme.palette.primary.light} height={300} />
              ) : (
                <>
                  <AddGroupCard
                    toggleCreateModal={() => setDetailsOpen(true)}
                    isSchool={true}
                  />
                  {groups?.map((group) => {
                    return (
                      <Fragment key={`group_${group.id}`}>
                        {group.belongsTo ? (
                          <Fragment />
                        ) : (
                          <GroupItem
                            shopId={shopId}
                            disabled={false}
                            isSchool={true}
                            group={group}
                            imgSrc={background}
                            putGroup={putGroup}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </>
              )}
            </GroupsWrapper>
          </QueryBuilder>
          {/* <TablePagination
            hidden={groups?.length === undefined}
            component="div"
            count={groups?.length}
            page={currentPage}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Iskolák egy oldalon:"}
          /> */}
          <Dialog
            open={detailsOpen}
            TransitionComponent={Transition}
            keepMounted
            maxWidth={"md"}
            onClose={toggleDetailsOpen}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <GroupDetails
              shopId={shopId}
              isSchool={true}
              currentGroup={null}
              belongsTo={null}
              toggle={toggleDetailsOpen}
              createModal={true}
              refresh={() => {
                fetchGroupsNew({ queryString: '' });
              }}
            />
          </Dialog>
        </main>
      </Container>
    </div>
  );
};

export default Groups;
