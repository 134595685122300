import { createSlice } from '@reduxjs/toolkit';

export const shopSlice = createSlice({
  name: 'shop',
  initialState: {
    shopId: -1,
  },
  reducers: {
    setActive: (state, action) => {
      state.shopId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setActive } = shopSlice.actions;

export default shopSlice.reducer;
