import { MenuItem, Select, useTheme } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { SelectWrapper } from "./styles";

const KeyValueEditor = ({ option, setOption }) => {
  const [currentKey, setCurrentKey] = useState(
    option.value === "" ? -1 : option.value.split("=")[1].split(",")[0]
  );
  const [currentValue, setCurrentValue] = useState(
    option.value === "" ? -1 : option.value.split("=")[1].split(",")[1]
  );
  const theme = useTheme();

  useEffect(() => {
    if (currentKey !== -1 && currentValue !== -1) {
      setOption({
        ...option,
        value: `${option.name}=${currentKey},${currentValue}`,
      });
    }
  }, [currentKey, currentValue]);

  return (
    <SelectWrapper>
      {option.keys.length > 0 ? (
        <Select
          required
          variant="outlined"
          onChange={(e) => {
            setCurrentKey(e.target.value);
          }}
          value={currentKey}
          fullWidth
          style={{
            fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
          }}
        >
          <MenuItem
            fullWidth
            key={`default_template_option_key`}
            value={-1}
            disabled
          >
            Kérem válasszon...
          </MenuItem>
          {option.keys?.map((key) => (
            <MenuItem
              fullWidth
              key={`${option.name}_option_key`}
              value={key.name}
              style={{
                fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
              }}
            >
              {key.displayName}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          variant="outlined"
          defaultValue={-1}
          fullWidth
          style={{
            fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
          }}
        >
          <MenuItem
            fullWidth
            key={`no_items`}
            value={-1}
            disabled
            style={{
              fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
            }}
          >
            Nincs megjeleníthető elem
          </MenuItem>
        </Select>
      )}
      {option.values.length > 0 ? (
        <Select
          required
          variant="outlined"
          onChange={(e) => {
            setCurrentValue(e.target.value);
          }}
          value={currentValue}
          fullWidth
          style={{
            fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
          }}
        >
          <MenuItem
            fullWidth
            key={`default_template_option`}
            value={-1}
            disabled
            style={{
              fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
            }}
          >
            Kérem válasszon...
          </MenuItem>
          {option.values?.map((value) => (
            <MenuItem
              fullWidth
              key={`${option.name}_option`}
              value={value.name}
              style={{
                fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
              }}
            >
              {value.displayName}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          variant="outlined"
          defaultValue={-1}
          fullWidth
          style={{
            fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
          }}
        >
          <MenuItem
            fullWidth
            key={`no_items`}
            value={-1}
            disabled
            style={{
              fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
            }}
          >
            Nincs megjeleníthető elem
          </MenuItem>
        </Select>
      )}
    </SelectWrapper>
  );
};

export default KeyValueEditor;
