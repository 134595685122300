import { useState } from "react";

export const useGetQueryString = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(
    localStorage.getItem("pageSize") ? localStorage.getItem("pageSize") : 10
  );

  const getDateString = (date) => {
    if (date && typeof date !== "undefined") {
      return new Date(date).toISOString();
    }
    return new Date().toISOString();
  };
  const getProductQueryString = ({ page, size, sort }) => {
    return `?page=${page}&size=${size}&sort=${sort},asc`;
  };

  const getQueryString = (searchParams) => {
    let queryString = "";
    let foundValidQueryParam = false;

    searchParams?.forEach((searchParam) => {
      if (searchParam.value !== "") {
        if (!foundValidQueryParam) {
          queryString += `?${searchParam.value}`;
        } else {
          queryString += `&${searchParam.value}`;
        }
        foundValidQueryParam = true;
      }
    });
    if (!foundValidQueryParam) return "";
    return queryString;
  };

  return {
    currentPage,
    setCurrentPage,
    pageSize,
    getDateString,
    setPageSize,
    getProductQueryString,
    getQueryString,
  };
};
