import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import { useStyles } from "./styles";
import SettingsCard from "components/PhotoAdminComponents/Settings/Card/SettingsCard";
import ActionButton from "components/common/ActionButton/ActionButton";
import { Form, Formik } from "formik";
import { useRef } from "react";
import { CircularProgress, InputAdornment } from "@material-ui/core";
import phoneChangeValidationSchema from "validation/UserSettings/PhoneChangeValidationSchema";

const ChangeContactPhoneCard = ({ user, putUserPhoneNumber, inProgress }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const formikRef = useRef(null);
  const [phoneData, setPhoneData] = useState({ phone: "" });

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.phone = phoneData?.phone;
    }
  }, [user, formikRef]);

  useEffect(() => {
    if (isEditing) {
      setPhoneData({ phone: "" });
    } else {
      setPhoneData({
        phone: user?.contact?.phone,
      });
    }
  }, [user, isEditing]);

  return (
    <SettingsCard
      title={"Telefonszám"}
      actionButton={
        <ActionButton
          icon={<Edit color="primary" />}
          clickHandler={toggleIsEditing}
        />
      }
    >
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          phone: "",
        }}
        validationSchema={phoneChangeValidationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          await putUserPhoneNumber({
            ...user,
            contact: {
              ...user?.contact,
              phone: values.phone,
            },
          });
          setIsEditing(false);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant="outlined"
                  fullWidth
                  required
                  label={"Telefonszám"}
                  id="phone"
                  name="phone"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+36</InputAdornment>
                    ),
                  }}
                  value={isEditing ? values.phone : phoneData?.phone}
                  onChange={handleChange}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>{" "}
            </Grid>
            <Grid
              item
              container
              style={{
                margin: "2rem 0 0 0",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Grid item xs={12}>
                <Button
                  disabled={!isEditing}
                  type="submit"
                  fullWidth
                  onClick={handleSubmit}
                  variant="outlined"
                  className={classes.button}
                >
                  {inProgress === "phone" ? (
                    <>
                      Folyamatban
                      <CircularProgress
                        style={{ marginLeft: "0.5rem" }}
                        size={24}
                      />
                    </>
                  ) : (
                    "Telefonszám frissítése"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangeContactPhoneCard;
