import Grid from "@material-ui/core/Grid";
import { useModifierSettingsAPI } from "./hooks/useModifierSettingsAPI";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useTheme } from "@material-ui/styles";
import FormControl from "@mui/material/FormControl";
import { useStyles } from "./styles";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography, Paper, CircularProgress } from "@material-ui/core";
import { Info } from "@material-ui/icons";

const ModifierInvoiceVendors = () => {
  const shopId = useSelector(
    (state) => state.nonPersistedReducers?.shopSliceReducer?.shopId
  );
  const theme = useTheme();
  const classes = useStyles(theme);

  const [modifierHereID, setModifierHereID] = useState("");
  const [vendorHere, setVendorHere] = useState("");

  const {
    getModifiers,
    getInvoiceVendors,
    getModInvoiceVendors,
    postModInvoiceVendor,
    deleteModInvoiceVendor,
    getUriId,
    modifiers,
    modifiersLoading,
    modifiersError,
    invoiceVendors,
    invoiceVendorsLoading,
    invoiceVendorsError,
    modInvoiceVendors,
    modInvoiceVendorsIDS,
    modInvoiceVendorsLoading,
    modInvoiceVendorsError,
    unsetModis,
  } = useModifierSettingsAPI();

  useEffect(() => {
    getModifiers();
    getInvoiceVendors();
    getModInvoiceVendors();
  }, [shopId]);

  const handleChangeModifier = (event) => {
    setModifierHereID(event.target.value);
  };
  const handleChangeVendor = (event) => {
    setVendorHere(event.target.value);
  };
  const handleAdd = () => {
    postModInvoiceVendor({
      id: null,
      shopId: shopId,
      modifierId: getUriId(
        modifiers.find((el) => el.id === modifierHereID).modifierUriSegment
      ),
      invoiceVendorId: vendorHere,
    });
    setModifierHereID("");
    setVendorHere("");
  };
  const handleDelete = (id) => {
    deleteModInvoiceVendor(id);
  };

  return (
    <div className={classes.mainContainer}>
      {unsetModis.length > 0 && (
        <Typography style={{ color: "red" }}>
          <Info /> Figyelem! Van olyan terméked, amely egyik számlázási
          kiszolgálódhoz sincs beállítva, így ezen termékekről számlát sem
          tudunk automatikusan kiállítani!
        </Typography>
      )}
      <Paper className={classes.paperContainer}>
        <div className={classes.leftContainer}>
          <div className={classes.leftInnerContainer}>
            <FormControl
              sx={{ m: 1, minWidth: 250 }}
              className={classes.flexItemMain}
            >
              <InputLabel id="products">Termék típusok</InputLabel>
              <Select
                labelId="products"
                className={classes.dropdown}
                value={modifierHereID}
                label="Termék típusok"
                onChange={handleChangeModifier}
              >
                {modifiers?.map((el) =>
                  !modInvoiceVendorsIDS?.includes(
                    getUriId(el.modifierUriSegment)
                  ) && el.priority !== 1 ? (
                    <MenuItem key={el.id} value={el.id}>
                      {el.name}
                    </MenuItem>
                  ) : (
                    <></>
                  )
                )}
              </Select>
            </FormControl>
            <div className={classes.flexGrow}></div>
            <FormControl
              sx={{ m: 1, minWidth: 250 }}
              className={classes.flexItemMain}
            >
              <InputLabel id="vendors">Számlázási vendor</InputLabel>
              <Select
                labelId="vendors"
                className={classes.dropdown}
                value={vendorHere}
                label="Számlázási vendor"
                onChange={handleChangeVendor}
              >
                {invoiceVendors?.map((el) => {
                  return (
                    <MenuItem key={el.id} value={el.id}>
                      {el.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div className={classes.flexGrow}></div>
          </div>
          <div className={classes.leftInnerContainer}>
            {modifierHereID === "" ? (
              <div className={classes.flexItemMain}></div>
            ) : (
              <div className={classes.flexItemMain}>
                <div className={classes.boxVendorL}>
                  {modifiers?.find((el) => el.id === modifierHereID)?.name}
                </div>
              </div>
            )}
            {modifierHereID !== "" || vendorHere !== "" ? (
              <div className={classes.flexGrow}>
                <AddIcon fontSize="large"></AddIcon>
              </div>
            ) : (
              <div></div>
            )}
            {vendorHere === "" ? (
              <div className={classes.flexItemMain}></div>
            ) : (
              <div className={classes.flexItemMain}>
                <div className={classes.boxVendorL}>
                  {invoiceVendors?.find((el) => el.id === vendorHere)?.name}
                </div>
              </div>
            )}
            {modifierHereID !== "" && vendorHere !== "" ? (
              <div className={classes.flexGrow}>
                <DoneIcon
                  fontSize="large"
                  onClick={handleAdd}
                  className={classes.buttonT}
                ></DoneIcon>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className={classes.rightContainer}>
          {modInvoiceVendorsLoading ? (
            <CircularProgress></CircularProgress>
          ) : modInvoiceVendors == null || modInvoiceVendors?.length < 1 ? (
            <div>Nincs elérhető adat</div>
          ) : (
            modInvoiceVendors?.map((vendors) => {
              return (
                <div className={classes.innerRight} key={vendors.id}>
                  <div className={classes.boxVendorR}>
                    <div>
                      {
                        modifiers?.find(
                          (el) =>
                            getUriId(el.modifierUriSegment) ===
                            vendors.modifierId
                        )?.name
                      }
                    </div>
                    <div>
                      {
                        invoiceVendors?.find(
                          (el) => el.id === vendors.invoiceVendorId
                        )?.name
                      }
                    </div>
                  </div>
                  <div>
                    <DeleteIcon
                      fontSize="large"
                      onClick={() => handleDelete(vendors.id)}
                      className={classes.buttonD}
                    ></DeleteIcon>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </Paper>
    </div>
  );
};

export default ModifierInvoiceVendors;
