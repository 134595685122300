import { getApi } from "api/axios";
import { errorToast, successToast } from "components/common/Toast/Toast";
import { useState } from "react";

export const useQuotesApi = () => {
  const [minimals, setMinimals] = useState();

  const [commissions, setCommissions] = useState();

  const getMinimals = async () => {
    try {
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/admin/quotes/minimals`);

      if (status === 200) {
        setMinimals(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const postMinimal = async (minimal) => {
    try {
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).post(`/admin/quotes/minimals`, minimal);
      console.log(data, status);
      successToast("Minimális ár számítási formula sikeresen frissítve!");
    } catch (e) {
      console.log(e);
      errorToast("Minimális ár számítási formula fissítése sikertelen!");
    }
  };

  const getCommissions = async () => {
    try {
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/admin/quotes/commissions`);
      if (status === 200) {
        setCommissions(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const postCommission = async (commission) => {
    try {
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).post(`/admin/quotes/commissions`, commission);
      console.log(data, status);
      successToast("Jutalék számítási formula sikeresen frissítve!");
    } catch (e) {
      errorToast("Jutalék számítási formula fissítése sikertelen!");
      console.log(e);
    }
  };

  return {
    getMinimals,
    postMinimal,
    minimals,
    getCommissions,
    postCommission,
    commissions,
  };
};
