import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const PortraitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: left;
`;

export const PortraitImage = styled.img`
  width: 250px;
  height: 250px;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.22), 0 6px 20px 0 rgba(0, 0, 0, 0.22);
  border: 6px solid white;
  margin: 2rem;

  @media (max-width: 600px) {
    width: 200px;
    height: 200px;
  }
`;

export const ProfileFields = styled.div`
  margin: 0 2rem 2rem 2rem;
  float: right;

  @media (min-width: 600px) {
    margin: 3rem 2rem 2rem 2rem;
    float: right;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const ProfileContainer = styled.div`
  @media (min-width: 600px) {
    flex-direction: row;
  }
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    rowGap: "2rem"
  },
  desktopTitle: {
    fontSize: "2rem",
    padding: "0 1rem",
    fontWeight: "bold",
    width: '100%',
    color: theme.palette.primary.main,
  },
  paperContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
    padding: "2rem",
    rowGap: "2rem",
    columnGap: "1rem",
    minHeight: "250px",
    [theme.breakpoints.up("md")]: {
      width: "90%",
      minHeight: "500px"
    },
    [theme.breakpoints.up("xl")]: {
      width: "80%",
    },
  },
  dropdown: {
    height: "60px",
  },
  leftContainer:{
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    rowGap: "2rem",
    width: "100%",
    minHeight: "175px",
    [theme.breakpoints.up("lg")]: {
      width: "calc(60% - 0.5rem)",
    },
  },
  leftInnerContainer:{
    display: "flex",
    flexWrap: "wrap",
    rowGap: "1rem",
    width: "100%",
    alignItems: "center",
    textAlign: "center",
  },
  flexGrow:{
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "10%",
    },
  },
  flexItemMain:{
    width: "100%",
    margin: "0 !important",
    minWidth: "0 !important",
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    rowGap: "2rem",
    width: "100%",
    maxHeight: "350px",
    overflowY: "scroll",
    padding: "0 1rem 1rem 1rem",
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      width: "calc(40% - 0.5rem)",
      maxHeight: "550px",
    },
  },
  innerRight:{
    display: "flex",
    alignItems: "center",
    columnGap: "1rem"
  },
  boxVendorR:{
    display: "flex",
    columnGap: "1rem",
    height: "60px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1rem",
    backgroundColor: theme.palette.primary.info,
    color: "#ffffff",
    borderRadius: "0.3rem",
    boxShadow: "0px 12px 12px rgb(0 0 0 / 24%)",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  boxVendorL:{
    padding: "1rem",
    backgroundColor: theme.palette.primary.info,
    color: "#ffffff",
    borderRadius: "0.3rem",
    boxShadow: "0px 12px 12px rgb(0 0 0 / 24%)",
    width: "100%",
    textAlign: "center"
  },
  buttonT:{
    "&:hover": {
      cursor: "pointer",
      color: "green"
    },
  },
  buttonD:{
    "&:hover": {
      cursor: "pointer",
      color: "red"
    },
  }
}));
